import { createStore } from "vuex";

const store = createStore({
  state: {
    showLocalTime: false,
    auth: null,
    user: null,
    dateFilter: null,
    monthFilter: null,
    assignmentData: null,
    flight: null,
    employee: null,
    assignmentsNotAcknowledged: [],
    aircraft: [],
    selectedAircraft: null,
    aircraftSortedBy: "registration",
    crewRequirementExemptions: [],
    airport: null,
    airports: [],
    assignmentWarnings: [],
    authorizedFlightRoles: [],
    assignmentBoardPageNumber: 0,
    flightBoardPageNumber: 0,
    bidlinesPageNumber: 0,
    securityGroup: null,
    securityGroups: [],
    notificationStatusFilter: {
      active: false,
      status: "",
    },
    jobTitlesFilter: {
      active: false,
      jobTitles: [],
    },
    companyFilter: {
      active: false,
      name: "",
    },
    assignmentsAirportFilter: {
      active: false,
      match: "",
      iataCode: "",
    },

    flightBoardAircraftFilter: {
      active: false,
      aircraft: [],
    },
    flightBoardAirportFilter: {
      active: false,
      match: "",
      iataCode: "",
    },
    aircraftDeckFilter: {
      active: false,
      deck: "",
    },
    airportsSpecialPeriodsFilter: {
      active: false,
      match: "",
      date: null,
    },
    airportsCountryFilter: {
      active: false,
      country: "",
    },
    crewRequirementsAirportFilter: {
      active: false,
      iataCode: "",
    },
    crewRequirementsAircraftTypeFilter: {
      active: false,
      type: "",
    },
  },
  //Note: newStationAssignment is the station assignment created on the flight board and shared with station assignment board.

  getters: {
    assignmentData(state) {
      return state.assignmentData;
    },

    airport(state) {
      return state.airport;
    },

    securityGroups(state) {
      return state.securityGroups;
    },

    crewRequirementExemptions(state) {
      return state.crewRequirementExemptions;
    },

    crewRequirementsAirportFilter(state) {
      return state.crewRequirementsAirportFilter;
    },

    crewRequirementsAircraftTypeFilter(state) {
      return state.crewRequirementsAircraftTypeFilter;
    },

    airportsSpecialPeriodsFilter(state) {
      return state.airportsSpecialPeriodsFilter;
    },

    airportsCountryFilter(state) {
      return state.airportsCountryFilter;
    },

    aircraftDeckFilter(state) {
      return state.aircraftDeckFilter;
    },

    notificationStatusFilter(state) {
      return state.notificationStatusFilter;
    },

    assignmentsAirportFilter(state) {
      return state.assignmentsAirportFilter;
    },

    flightBoardAirportFilter(state) {
      return state.flightBoardAirportFilter;
    },

    flightBoardAircraftFilter(state) {
      return state.flightBoardAircraftFilter;
    },

    jobTitlesFilter(state) {
      return state.jobTitlesFilter;
    },

    companyFilter(state) {
      return state.companyFilter;
    },

    assignmentsNotAcknowledged(state) {
      return state.assignmentsNotAcknowledged;
    },

    flightToAssign(state) {
      return state.flightToAssign;
    },

    tripToAssign(state) {
      return state.tripToAssign;
    },

    securityGroup(state) {
      return state.securityGroup;
    },

    newStationAssignment(state) {
      return state.newStationAssignment;
    },

    monthFilter(state) {
      return state.monthFilter;
    },

    authorizedFlightRoles(state) {
      return state.authorizedFlightRoles;
    },

    assignmentWarnings(state) {
      return state.assignmentWarnings;
    },

    // airports(state) {
    //   return state.airports;
    // },

    aircraft(state) {
      return state.aircraft;
    },

    aircraftSortedBy(state) {
      return state.aircraftSortedBy;
    },

    selectedAircraft(state) {
      return state.selectedAircraft;
    },

    flight(state) {
      return state.flight;
    },

    // employee(state) {
    //   return state.employee;
    // },

    assignmentBoardPageNumber(state) {
      return state.assignmentBoardPageNumber;
    },

    flightBoardPageNumber(state) {
      return state.flightBoardPageNumber;
    },

    bidlinesPageNumber(state) {
      return state.bidlinesPageNumber;
    },
  },

  mutations: {
    toggleShowLocalTime(state) {
      state.showLocalTime = !state.showLocalTime;
    },
    updateEmployee(state, employee) {
      state.employee = employee;
    },

    updateAssignmentData(state, assignmentData) {
      state.assignmentData = assignmentData;
    },

    updateSecurityGroups(state, securityGroups) {
      state.securityGroups = securityGroups;
    },

    updateAirport(state, airport) {
      state.airport = airport;
    },

    updateCrewRequirementExemptions(state, exemptions) {
      state.crewRequirementExemptions = exemptions;
    },

    updateCrewRequirementsAircraftTypeFilter(state, filter) {
      state.crewRequirementsAircraftTypeFilter = filter;
    },

    updateCrewRequirementsAirportFilter(state, filter) {
      state.crewRequirementsAirportFilter = filter;
    },

    updateAirportsSpecialPeriodsFilter(state, filter) {
      state.airportsSpecialPeriodsFilter = filter;
    },

    updateAirportsCountryFilter(state, filter) {
      state.airportsCountryFilter = filter;
    },

    updateAircraftDeckFilter(state, filter) {
      state.aircraftDeckFilter = filter;
    },

    updateNotificationStatusFilter(state, filter) {
      state.notificationStatusFilter = filter;
    },

    updateAssignmentsAirportFilter(state, filter) {
      state.assignmentsAirportFilter = filter;
    },

    updateFlightBoardAirportFilter(state, filter) {
      state.flightBoardAirportFilter = filter;
    },

    updateFlightBoardAircraftFilter(state, filter) {
      state.flightBoardAircraftFilter = filter;
    },

    updateCompanyFilter(state, filter) {
      state.companyFilter = filter;
    },

    updateJobTitlesFilter(state, filter) {
      state.jobTitlesFilter = filter;
    },

    updateAssignmentsNotAcknowledged(state, assignmentsNotAcknowledged) {
      state.assignmentsNotAcknowledged = assignmentsNotAcknowledged;
    },

    // updateFlightToAssign(state, flight) {
    //   state.flightToAssign = flight;
    // },

    // updateTripToAssign(state, trip) {
    //   state.tripToAssign = trip;
    // },

    updateSecurityGroup(state, securityGroup) {
      state.securityGroup = securityGroup;
    },

    updateAuthorizedFlightRoles(state, authorizedFlightRoles) {
      state.authorizedFlightRoles = authorizedFlightRoles;
    },

    updateAssignmentWarnings(state, assignmentWarnings) {
      state.assignmentWarnings = assignmentWarnings;
    },

    //Note this is used on company assignments
    // updateFlightAssignment_id(state, flightAssignment_id) {
    //   state.flightAssignment_id = flightAssignment_id;
    // },

    updateAirports(state, airports) {
      state.airports = airports;
    },

    updateUser(state, user) {
      state.user = user;
    },

    updateAircraft(state, aircraft) {
      state.aircraft = aircraft;
    },

    updateAircraftSortedBy(state, aircraftSortedBy) {
      state.aircraftSortedBy = aircraftSortedBy;
    },

    updateSelectedAircraft(state, selectedAircraft) {
      state.selectedAircraft = selectedAircraft;
    },

    updateFlight(state, flight) {
      state.flight = flight;
    },

    updateAuth(state, auth) {
      state.auth = auth;
    },

    updateDateFilter(state, { filterStart, numberOfDays }) {
      //Note filterStart comes in as miliseconds, numberOfDays as integer
      let days = [];
      let day;

      for (let i = 0; i < numberOfDays + 1; i++) {
        day = new Date(
          new Date(filterStart).getTime() + i * (24 * 60 * 60 * 1000)
        ).toISOString();
        days.push(day);
      }

      const start = days[0];
      const lastDay = days[days.length - 1];
      const end = new Date(new Date(lastDay).getTime() - 1).toISOString();

      // Remove the last day
      const filterDays = days.slice(0, numberOfDays);

      let labels = [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
      ];

      let hourLabels = [];
      let hourBoxes = [];
      let hourLines = [];

      for (let d = 0; d < filterDays.length; d++) {
        for (let i = 0; i < 24; i++) {
          hourLabels.push(`${labels[i]}`);
          hourBoxes.push({ key: `L${d}-${i}`, value: `${labels[i]}` });
          hourLines.push({ key: `B${d}-${i}`, value: `${labels[i]}` });
        }
      }

      const filter = {
        start,
        end,
        startDate: new Date(start).toISOString().substring(0, 10),
        endDate: new Date(end).toISOString().substring(0, 10),
        days: filterDays,
        hourLabels,
        hourBoxes,
        hourLines,
      };

      state.dateFilter = filter;
    },

    updateMonthFilter(state, { year, month, period }) {
      const filterStart = new Date(
        Date.UTC(year, month, 1, 0, 0, 0, 0)
      ).getTime();

      const numberOfDays = new Date(year, month + 1, 0).getDate();

      let days = [];
      let day;

      for (let i = 0; i < numberOfDays + 1; i++) {
        day = new Date(
          new Date(filterStart).getTime() + i * (24 * 60 * 60 * 1000)
        ).toISOString();
        days.push(day);
      }

      const start = days[0];
      const lastDay = days[days.length - 1];
      const end = new Date(new Date(lastDay).getTime() - 1).toISOString();

      // Remove the last day
      const filterDays = days.slice(0, numberOfDays);

      let labels = [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
      ];

      let hourLabels = [];
      let hourBoxes = [];
      let hourLines = [];

      for (let d = 0; d < filterDays.length; d++) {
        for (let i = 0; i < 24; i++) {
          hourLabels.push(`${labels[i]}`);
          hourBoxes.push({ key: `L${d}-${i}`, value: `${labels[i]}` });
          hourLines.push({ key: `B${d}-${i}`, value: `${labels[i]}` });
        }
      }

      const filter = {
        start,
        end,
        startDate: new Date(start).toISOString().substring(0, 10),
        endDate: new Date(end).toISOString().substring(0, 10),
        days: filterDays,
        hourLabels,
        hourBoxes,
        hourLines,
        year,
        month,
        period,
      };

      state.monthFilter = filter;
    },

    updateAssignmentBoardPageNumber(state, pageNumber) {
      state.assignmentBoardPageNumber = pageNumber;
    },

    updateFlightBoardPageNumber(state, pageNumber) {
      state.flightBoardPageNumber = pageNumber;
    },

    updateBidlinesPageNumber(state, pageNumber) {
      state.bidlinesPageNumber = pageNumber;
    },
  },
});

export default store;
