<template>
  <!-- #region NAVBAR -->
  <nav>
    <ul class="nav-wrapper">
      <li class="icon" @click="handleGoBack">
        <a href="#">
          <span class="tooltip">Go Back</span>
          <span><i class="fa-solid fa-arrow-left text-white ml-5"> </i></span>
        </a>
      </li>

      <li class="icon">
        <RouterLink to="/">
          <span class="tooltip">Home</span>
          <span><i class="fa-solid fa-house text-white ml-3"> </i></span
        ></RouterLink>
      </li>

      <li class="icon">
        <a href="#">
          <span class="tooltip">Search Active Directory</span>
          <i
            @click="handleSearchActiveDirectory"
            class="fa-solid fa-down-to-bracket ml-3 text-blue-500"
          ></i>
        </a>
      </li>

      <li class="icon">
        <a href="#">
          <span class="tooltip">Update Company ID</span>
          <i
            @click="handleShowCompanyIdDialog"
            class="fa-solid fa-id-card ml-3 text-blue-400"
          ></i>
        </a>
      </li>

      <li class="icon">
        <a href="#">
          <span class="tooltip">Add Passport</span>
          <i
            @click="handleShowPassportDialog"
            class="fa-solid fa-passport ml-3 text-blue-300"
          ></i>
        </a>
      </li>

      <li class="icon">
        <a href="#">
          <span class="tooltip">Add Visa</span>
          <i
            @click="handleShowVisaDialog"
            class="fa-solid fa-stamp ml-3 text-yellow-300"
          ></i>
        </a>
      </li>

      <li class="icon">
        <a href="#">
          <span class="tooltip">Add Vaccine</span>
          <i
            @click="handleShowVaccineDialog"
            class="fa-solid fa-syringe ml-3 text-red-300"
          ></i>
        </a>
      </li>

      <li class="icon">
        <a href="#">
          <span class="tooltip">Add SIDA Badge</span>
          <i
            @click="handleShowSidaBadgeDialog"
            class="fa-solid fa-id-badge ml-3 text-white"
          ></i>
        </a>
      </li>

      <li class="icon">
        <a href="#">
          <span class="tooltip">Add CAC Card</span>
          <i
            @click="handleShowCacCardDialog"
            class="fa-solid fa-id-badge ml-3 text-gray-300"
          ></i>
        </a>
      </li>

      <li class="icon">
        <a href="#">
          <span class="tooltip">Add KCM Badge</span>
          <i
            @click="handleShowKcmBadgeDialog"
            class="fa-solid fa-id-badge ml-3 text-gray-400"
          ></i>
        </a>
      </li>

      <li class="icon">
        <a href="#">
          <span class="tooltip">Add Driver's License</span>
          <i
            @click="handleShowDriversLicenseDialog"
            class="fa-solid fa-id-card ml-3 text-blue-400"
          ></i>
        </a>
      </li>

      <li class="icon">
        <a href="#">
          <span class="tooltip">Get Training Records</span>
          <i
            @click="handleGetTrainingRecords"
            class="fa-solid fa-file-certificate ml-3 text-green-400"
          ></i>
        </a>
      </li>

      <li>
        <a href="#"> </a>
      </li>
    </ul>
  </nav>
  <!-- #endregion -->

  <!-- SNACKBAR -->
  <r-snackbar
    v-if="snackbarVisible"
    :backgroundColor="snackbarColor"
    :message="snackbarText"
    :timeout="3000"
    @close="snackbarVisible = false"
  >
  </r-snackbar>

  <div class="p-5">
    <!-- WARNINGS -->
    <r-modal v-if="showWarningsModal">
      <template #header>
        <div class="flex justify-between mb-5">
          <div class="flex">
            <div class="text-base">Warnings</div>
            <i @click="handlePrintWarnings" class="fa-solid fa-print"></i>
          </div>

          <i @click="showWarningsModal = false" class="fa-solid fa-xmark"></i>
        </div>
      </template>
      <template #default>
        <div class="modal-body">
          <table class="items-table">
            <colgroup>
              <col style="width: auto" />
              <col style="width: auto" />
              <col style="width: 100px" />
              <col style="width: auto" />
            </colgroup>
            <tr>
              <th>Employee</th>
              <th>Type</th>
              <th>Level</th>
              <th>Message</th>
            </tr>
            <tr v-for="(warning, index) in warnings" :key="index">
              <td>
                {{ `${warning.surname},${warning.givenName}  ` }}
              </td>
              <td>{{ warning.type }}</td>
              <td>{{ warning.level }}</td>
              <td>{{ warning.message }}</td>
            </tr>
          </table>
        </div>
      </template>
      <template #actions>
        <div class="flex justify-end">
          <button
            @click="showWarningsModal = false"
            class="btn bg-blue-500 text-white"
          >
            Acknowledge
          </button>
        </div>
      </template>
    </r-modal>

    <!-- COMPANY ID  -->
    <r-modal v-if="showCompanyIdModal" @close="showCompanyIdModal = false">
      <div class="p-5">
        <div class="flex justify-between mb-5">
          <div class="text-2xl">Company ID</div>
          <i
            @click="showCompanyIdModal = false"
            class="fa-solid fa-xmark cursor-pointer"
          ></i>
        </div>

        <div class="flex mb-2">
          <div>
            <p class="text-sm text-gray-500">Employee ID</p>
            <input
              class="block w-32 rounded-md border-0 py-2 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              :class="{ missing: !newCompanyId.number }"
              type="text"
              maxlength="40"
              v-model="newCompanyId.number"
            />
          </div>

          <div class="ml-2">
            <p class="text-sm text-gray-500">Expiration Date</p>
            <input
              class="block w-48 py-2 rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              type="date"
              v-model="newCompanyId.expirationDate"
            />
          </div>
        </div>

        <div class="p-3 border border-gray-500 rounded-md mb-2">
          <div class="flex justify-between mb-2">
            <div class="text-xl">Profile Image</div>
            <i class="fa-solid fa-image-user ml-3 text-blue-500"></i>
          </div>

          <input
            @change="handleOnFileOneChange($event)"
            type="file"
            accept="image/png, image/jpeg"
          />

          <p class="mt-1 text-sm text-gray-500">
            Image should be of type png or jpeg and smaller than 1,000 KB
          </p>

          <div
            class="border border-gray-500 rounded-md p-2 my-2"
            v-if="fileOne"
          >
            <div>{{ `Size: ${fileOneSize} kb` }}</div>
            <div>{{ `Type: ${fileOneType}` }}</div>
          </div>
        </div>

        <div class="flex justify-between">
          <div class="text-base text-red-500" v-if="companyIdError">
            {{ companyIdError }}
          </div>
          <div v-else></div>

          <button
            @click="handleSaveCompanyId"
            class="btn bg-blue-500 text-white"
          >
            Save Company ID
          </button>
        </div>
      </div>
    </r-modal>

    <!-- PASSPORT -->
    <r-modal v-if="showPassportModal" @close="showPassportModal = false">
      <div class="p-5" v-if="passport">
        <div class="flex justify-between mb-5">
          <div class="text-2xl">Passport</div>
          <i
            @click="showPassportModal = false"
            class="fa-solid fa-xmark cursor-pointer"
          ></i>
        </div>

        <div class="flex">
          <div class="mb-2">
            <p class="text-sm text-gray-500">Number</p>
            <input
              class="block w-32 rounded-md border-0 py-2 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              :class="{
                missing: !passport.number || passport.number.length !== 4,
              }"
              type="text"
              maxlength="4"
              v-model="passport.number"
            />
          </div>

          <div class="ml-2">
            <p class="text-sm text-gray-500">Expiration Date</p>
            <input
              class="block w-48 py-2 rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              type="date"
              v-model="passport.expirationDate"
            />
          </div>
        </div>

        <div>
          <p class="text-sm text-gray-500">Country</p>
          <select
            class="block w-80 py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
            v-model="passport.country"
          >
            <option
              v-for="(country, index) in countries"
              :key="index"
              :value="country"
            >
              {{ country }}
            </option>
          </select>
        </div>

        <div class="flex justify-between mt-3">
          <div class="text-base text-red-500" v-if="passportError">
            {{ passportError }}
          </div>
          <div v-else></div>
          <button @click="handleAddPassport" class="btn bg-blue-500 text-white">
            Add Passport
          </button>
        </div>
      </div>
    </r-modal>

    <!-- VISA -->
    <r-modal v-if="showVisaModal" @close="showVisaModal = false">
      <div class="p-5" v-if="visa">
        <div class="flex justify-between mb-5">
          <div class="text-2xl">Visa</div>
          <i
            @click="showVisaModal = false"
            class="fa-solid fa-xmark cursor-pointer"
          ></i>
        </div>

        <div class="flex mb-2">
          <div>
            <p class="text-sm text-gray-500">Country</p>
            <select
              class="block w-full py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              v-model="visa.country"
            >
              <option
                v-for="(country, index) in countries"
                :key="index"
                :value="country"
              >
                {{ country }}
              </option>
            </select>
          </div>

          <div class="ml-1">
            <p class="text-sm text-gray-500">Number</p>
            <input
              class="block w-full rounded-md border-0 py-2 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              :class="{ missing: !visa.number || visa.number.length !== 4 }"
              type="text"
              maxlength="4"
              v-model="visa.number"
            />
          </div>
        </div>

        <div>
          <p class="text-sm text-gray-500">Expiration Date</p>
          <input
            class="block w-48 py-2 rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
            type="date"
            v-model="visa.expirationDate"
          />
        </div>

        <div class="flex justify-between mt-3">
          <div class="text-base text-red-500">
            {{ visaError }}
          </div>
          <div></div>

          <button @click="handleAddVisa" class="btn bg-blue-500 text-white">
            Add Visa
          </button>
        </div>
      </div>
    </r-modal>

    <!-- VACCINE -->
    <r-modal v-if="showVaccineModal" @close="showVaccineModal = false">
      <div class="p-5">
        <div class="flex justify-between mb-5">
          <div class="text-2xl">Vaccines</div>
          <i
            @click="showVaccineModal = false"
            class="fa-solid fa-xmark cursor-pointer"
          ></i>
        </div>

        <div class="flex mb-2">
          <div>
            <p class="text-sm text-gray-500">Proper Name</p>
            <select
              class="block w-full py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              v-model="vaccine.properName"
              @change="handleUpdateVaccineTypes"
            >
              <option
                v-for="(name, index) in vaccineProperNames"
                :key="index"
                :value="name"
              >
                {{ name }}
              </option>
            </select>
          </div>

          <div class="ml-2" v-if="vaccineTypes.length">
            <p class="text-sm text-gray-500">Type</p>
            <select
              class="block w-full py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              v-model="vaccine.type"
            >
              <option
                v-for="(type, index) in vaccineTypes"
                :key="index"
                :value="type"
              >
                {{ type }}
              </option>
            </select>
          </div>
        </div>

        <div>
          <p class="text-sm text-gray-500">Date</p>
          <input
            class="block w-48 py-2 rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
            type="date"
            v-model="vaccine.date"
          />
        </div>

        <div class="flex justify-between">
          <div v-if="vaccineError" class="text-base text-red-500">
            {{ vaccineError }}
          </div>
          <div v-else></div>
          <div class="flex justify-end mt-1">
            <button
              @click="handleAddVaccine"
              class="btn bg-blue-500 text-white"
            >
              Add Vaccine
            </button>
          </div>
        </div>
      </div>
    </r-modal>

    <!-- SIDA BADGE -->
    <r-modal v-if="showSidaBadgeModal" @close="showSidaBadgeModal = false">
      <div class="p-5">
        <div class="flex justify-between mb-5">
          <div class="text-2xl">SIDA Badge</div>
          <i
            @click="showSidaBadgeModal = false"
            class="fa-solid fa-xmark cursor-pointer"
          ></i>
        </div>

        <div class="flex">
          <div>
            <p class="text-sm text-gray-500">Airport</p>
            <input
              class="block w-24 rounded-md border-0 py-1.5 text-gray-900 text-center uppercase shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              :class="{
                missing:
                  !sidaBadge.airportOfIssue ||
                  sidaBadge.airportOfIssue.length !== 3,
              }"
              type="text"
              maxlength="3"
              v-model="sidaBadge.airportOfIssue"
            />
          </div>

          <div class="ml-2">
            <p class="text-sm text-gray-500">Number</p>
            <input
              class="block w-24 rounded-md border-0 py-1.5 text-gray-900 text-center shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              :class="{
                missing: !sidaBadge.number || sidaBadge.number.length !== 4,
              }"
              type="text"
              maxlength="4"
              v-model="sidaBadge.number"
            />
          </div>
        </div>

        <div>
          <p class="text-sm text-gray-500">Expiration Date</p>
          <input
            class="block w-48 py-2 rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
            type="date"
            v-model="sidaBadge.expirationDate"
          />
        </div>

        <div class="flex justify-between">
          <div v-if="sidaBadgeError" class="text-base text-red-500">
            {{ sidaBadgeError }}
          </div>
          <div v-else></div>
          <div class="flex justify-end">
            <button
              @click="handleAddSidaBadge"
              class="btn bg-blue-500 text-white"
            >
              Add SIDA Badge
            </button>
          </div>
        </div>
      </div>
    </r-modal>

    <!-- CAC CARD -->
    <r-modal v-if="showCacCardModal" @close="showCacCardModal = false">
      <div class="p-5">
        <div class="flex justify-between mb-5">
          <div class="text-base">CAC Card</div>
          <i @click="showCacCardModal = false" class="fa-solid fa-xmark"></i>
        </div>

        <div class="flex">
          <div>
            <p class="text-sm text-gray-500">Number</p>
            <input
              class="block w-32 rounded-md border-0 py-2 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              :class="{
                missing: !newCacCard.number || newCacCard.number.length !== 4,
              }"
              type="text"
              maxlength="4"
              v-model="newCacCard.number"
            />
          </div>

          <div class="ml-2">
            <p class="text-sm text-gray-500">Expiration Date</p>
            <input
              class="block w-48 py-2 rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              type="date"
              v-model="newCacCard.expirationDate"
            />
          </div>
        </div>

        <div class="flex justify-between mt-1">
          <div v-if="cacCardError" class="text-base text-red-500">
            {{ cacCardError }}
          </div>
          <div v-else></div>
          <div class="flex justify-end">
            <button
              @click="handleAddCacCard"
              class="btn bg-blue-500 text-white"
            >
              Add CAC Card
            </button>
          </div>
        </div>
      </div>
    </r-modal>

    <!-- KCM BADGE -->
    <r-modal v-if="showKcmBadgeModal" @close="showKcmBadgeModal = false">
      <div class="p-5">
        <div class="flex justify-between mb-5">
          <div class="text-base">KCM Card</div>
          <i
            @click="showKcmBadgeModal = false"
            class="fa-solid fa-xmark cursor-pointer"
          ></i>
        </div>

        <div>
          <p class="text-sm text-gray-500">Number</p>
          <input
            class="block w-24 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            :class="{
              missing: !newKcmBadge.number || newKcmBadge.number.length !== 4,
            }"
            type="text"
            maxlength="4"
            v-model="newKcmBadge.number"
          />
        </div>

        <div class="flex justify-end mt-1">
          <div class="flex justify-end">
            <button
              @click="handleAddKcmBadge"
              class="btn bg-blue-500 text-white"
            >
              Add KCM Badge
            </button>
          </div>
        </div>
      </div>
    </r-modal>

    <!-- DRIVER'S LICENSE -->
    <r-modal
      v-if="showDriversLicenseModal"
      @close="showDriversLicenseModal = false"
    >
      <div class="p-5">
        <div class="flex justify-between mb-5">
          <div class="text-base">Drivers License</div>
          <i
            @click="showDriversLicenseModal = false"
            class="fa-solid fa-xmark cursor-pointer"
          ></i>
        </div>

        <div class="flex mb-2">
          <div>
            <p class="text-sm text-gray-500">Number</p>
            <input
              class="block w-32 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              :class="{
                missing:
                  !driversLicense.number || driversLicense.number.length !== 4,
              }"
              type="text"
              maxlength="4"
              v-model="driversLicense.number"
            />
          </div>

          <div class="ml-2">
            <p class="text-sm text-gray-500">State</p>
            <select
              class="block w-full py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              v-model="driversLicense.state"
            >
              <option
                v-for="(state, index) in states"
                :key="index"
                :value="state"
              >
                {{ state }}
              </option>
            </select>
          </div>
        </div>

        <div>
          <p class="text-sm text-gray-500">Expiration Date</p>
          <input
            class="block w-48 py-2 rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
            type="date"
            v-model="driversLicense.expirationDate"
          />
        </div>

        <div class="flex justify-between">
          <div v-if="driversLicenseError" class="flex">
            <button
              @click="handleRemoveDriversLicense"
              class="btn-r is-orange is-dark"
            >
              remove
            </button>
          </div>
          <div v-else></div>
          <div class="flex justify-end">
            <button
              @click="handleAddDriversLicense"
              class="btn bg-blue-500 text-white"
            >
              Add Driver's License
            </button>
          </div>
        </div>
      </div>
    </r-modal>

    <r-spinner v-if="loading"> </r-spinner>

    <div v-if="!error">
      <div class="flex justify-between px-5">
        <div>
          <div class="text-2xl text-blue-700">
            {{ `${surname}, ${givenName}` }}
          </div>
          <div class="text-xl text-blue-700">
            {{ `${companyName} - Emp. No ${companyId.number}` }}
          </div>
        </div>
        <figure @click="handleShowCompanyIdDialog" class="profile-pic-figure">
          <img
            :src="profileImageUrl"
            alt="Profile Image"
            title="Profile Image"
            width="150"
            height="150"
          />
        </figure>
      </div>

      <form class="border-b border-gray-400 my-5" @submit.prevent="onSubmit">
        <div class="main-grid">
          <div
            class="employee-id cursor-pointer"
            @click="handleShowCompanyIdDialog"
          >
            <p class="text-sm text-gray-500">Employee ID</p>
            <div
              class="block w-full rounded-md border-0 py-2 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            >
              {{ companyId.number }}
            </div>
          </div>

          <div
            class="id-expiration-date cursor-pointer"
            @click="handleShowCompanyIdDialog"
          >
            <p class="text-sm text-gray-500">Expiration Date</p>

            <div
              class="block w-48 py-2 rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
            >
              {{ companyId.expirationDate }}
            </div>
          </div>

          <div class="security-group">
            <p class="text-sm text-gray-500">Security Group</p>
            <select
              class="block w-full py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 cursor-pointer"
              v-model="securityGroupId"
              :disabled="!allowedAppRoles.includes(user.appRole)"
            >
              <option
                v-for="securityGroup in securityGroups"
                :key="securityGroup._id"
                :value="securityGroup._id"
              >
                {{ securityGroup.name }}
              </option>
            </select>
          </div>

          <div class="status">
            <p class="text-sm text-gray-500">Status</p>
            <select
              class="block w-full py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 cursor-pointer"
              v-model="status"
            >
              <option
                v-for="(sts, index) in statusOptions"
                :key="index"
                :value="sts"
              >
                {{ sts }}
              </option>
            </select>
          </div>

          <div class="given-name">
            <p class="text-sm text-gray-500">First Name</p>
            <input
              class="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 cursor-pointer"
              disabled
              type="text"
              maxlength="40"
              v-model="givenName"
            />
          </div>

          <div class="surname">
            <p class="text-sm text-gray-500">Last Name</p>
            <input
              class="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              disabled
              type="text"
              maxlength="40"
              v-model="surname"
            />
          </div>

          <div class="job-title">
            <p class="text-sm text-gray-500">Job Title</p>
            <select
              class="block w-full py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 cursor-pointer"
              :class="{ missing: !jobTitle }"
              v-model="jobTitle"
            >
              <option
                v-for="(jobTitle, index) in user.securityGroup.jobTitles"
                :key="index"
                :value="jobTitle"
              >
                {{ jobTitle }}
              </option>
            </select>
          </div>

          <div class="company-name">
            <p class="text-sm text-gray-500">Company</p>
            <select
              class="block w-full py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              :class="{ missing: !companyName }"
              v-model="companyName"
            >
              <option
                v-for="(companyName, index) in user.securityGroup.companies"
                :key="index"
                :value="companyName"
              >
                {{ companyName }}
              </option>
            </select>
          </div>

          <div class="gateway">
            <p class="text-sm text-gray-500">Gateway</p>
            <input
              class="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              :class="{ missing: !gatewayCode }"
              type="text"
              maxlength="4"
              v-model="gatewayCode"
            />
          </div>

          <div class="sub-gateways">
            <p class="text-sm text-gray-500">Sub-Gateways</p>
            <input
              class="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              type="text"
              maxlength="100"
              v-model="subGatewayCodes"
            />
          </div>

          <div class="seniority-number">
            <p class="text-sm text-gray-500">Seniority No.</p>
            <input
              class="block w-24 py-2 rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              :class="{ missing: !seniorityNumber }"
              type="number"
              step="1"
              min="0"
              max="99999"
              v-model="seniorityNumber"
            />
          </div>

          <div class="date-of-hire">
            <p class="text-sm text-gray-500">Date of Hire</p>
            <input
              class="block w-48 py-2 rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              type="date"
              v-model="dateOfHire"
            />
          </div>

          <div class="mail">
            <p class="text-sm text-gray-500">Email</p>
            <input
              class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              disabled
              type="text"
              maxlength="100"
              v-model="mail"
            />
          </div>

          <div class="secondary-mail">
            <p class="text-sm text-gray-500">Secondary Email</p>
            <input
              class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              type="text"
              maxlength="100"
              v-model="secondaryMail"
            />
          </div>

          <div class="mobile-phone">
            <p class="text-sm text-gray-500">Mobile Phone</p>
            <input
              class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              type="text"
              maxlength="40"
              v-model="mobilePhone"
            />
          </div>

          <div class="emergency-contact-name">
            <p class="text-sm text-gray-500">Emergency Contact Name</p>
            <input
              class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              type="text"
              maxlength="40"
              v-model="emergencyContact.name"
            />
          </div>

          <div class="emergency-contact-number">
            <p class="text-sm text-gray-500">Emergency Contact number</p>
            <input
              class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              type="text"
              maxlength="40"
              v-model="emergencyContact.number"
            />
          </div>

          <div class="address-street">
            <p class="text-sm text-gray-500">Street Address</p>
            <input
              class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              type="text"
              maxlength="40"
              v-model="address.street"
            />
          </div>

          <div class="address-city">
            <p class="text-sm text-gray-500">City</p>
            <input
              class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              type="text"
              maxlength="40"
              v-model="address.city"
            />
          </div>

          <div class="address-state">
            <p class="text-sm text-gray-500">State</p>
            <input
              class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              type="text"
              maxlength="2"
              v-model="address.state"
            />
          </div>

          <div class="address-zip">
            <p class="text-sm text-gray-500">ZIP Code</p>
            <input
              class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              type="text"
              maxlength="40"
              v-model="address.postalCode"
            />
          </div>

          <div class="address-country mb-3">
            <p class="text-sm text-gray-500">Country</p>
            <select
              class="block w-full py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 cursor-pointer"
              v-model="address.country"
            >
              <option
                v-for="(country, index) in countries"
                :key="index"
                :value="country"
              >
                {{ country }}
              </option>
            </select>
          </div>
        </div>
      </form>

      <div class="p-3">
        <!-- PASSPORTS -->
        <div class="border border-gray-500 rounded-md py-2 px-3 my-1">
          <div>
            <div
              class="flex justify-between cursor-pointer"
              @click="showPassports = !showPassports"
            >
              <div class="font-base">Passports</div>
            </div>

            <div class="relative overflow-x-auto" v-if="showPassports">
              <table
                class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
                v-if="passports.length"
              >
                <thead
                  class="text-xs text-gray-200 uppercase bg-gray-500 dark:bg-gray-700 dark:text-gray-400"
                >
                  <tr>
                    <th scope="col" class="px-6 py-3">Country of Issue</th>
                    <th style="width: 10%" scope="col" class="px-6 py-3">
                      Number
                    </th>
                    <th style="width: 20%" scope="col" class="px-6 py-3">
                      Expiration Date
                    </th>
                    <th style="width: 10%" scope="col" class="px-6 py-3">
                      Remove
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(passport, index) in passports"
                    :key="index"
                    class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <td
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {{ passport.country }}
                    </td>
                    <td class="px-6 py-2">{{ passport.number }}</td>
                    <td class="px-6 py-2">
                      {{ passport.expirationDate.substring(0, 10) }}
                    </td>

                    <td class="px-6 py-2">
                      <i
                        class="fa-solid fa-trash-can cursor-pointer"
                        @click="handleRemovePassport(passport)"
                      ></i>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div v-else class="text-base p-3">
                This employee has no passports.
              </div>
            </div>
          </div>
        </div>
        <!-- VISAS -->
        <div class="border border-gray-500 rounded-md py-2 px-3 mb-1">
          <div>
            <div
              class="flex justify-between cursor-pointer"
              @click="showVisas = !showVisas"
            >
              <div class="font-base">Visas</div>
            </div>

            <div class="relative overflow-x-auto" v-if="showVisas">
              <table
                class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
                v-if="visas.length"
              >
                <thead
                  class="text-xs text-gray-200 uppercase bg-gray-500 dark:bg-gray-700 dark:text-gray-400"
                >
                  <tr>
                    <th scope="col" class="px-6 py-3">Country</th>
                    <th style="width: 10%" scope="col" class="px-6 py-3">
                      Number
                    </th>
                    <th style="width: 20%" scope="col" class="px-6 py-3">
                      Expiration Date
                    </th>
                    <th style="width: 10%" scope="col" class="px-6 py-3">
                      Remove
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(visa, index) in visas"
                    :key="index"
                    class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <td
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {{ visa.country }}
                    </td>
                    <td class="px-6 py-2">
                      {{ visa.number }}
                    </td>
                    <td class="px-6 py-2">
                      {{ visa.expirationDate.substring(0, 10) }}
                    </td>

                    <td class="px-6 py-2">
                      <i
                        class="fa-solid fa-trash-can cursor-pointer"
                        @click="handleRemoveVisa(visa)"
                      ></i>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div v-else class="text-base p-3">
                This employee has no visas.
              </div>
            </div>
          </div>
        </div>
        <!-- VACCINES -->
        <div class="border border-gray-500 rounded-md py-2 px-3 mb-1">
          <div>
            <div
              class="flex justify-between cursor-pointer"
              @click="showVaccines = !showVaccines"
            >
              <div class="font-base">Vaccines</div>
            </div>

            <div v-if="showVaccines" class="pa-2">
              <div class="relative overflow-x-auto" v-if="showVaccines">
                <table
                  class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
                  v-if="vaccines.length"
                >
                  <thead
                    class="text-xs text-gray-200 uppercase bg-gray-500 dark:bg-gray-700 dark:text-gray-400"
                  >
                    <tr>
                      <th scope="col" class="px-6 py-3">Proper Name</th>
                      <th style="width: 10%" scope="col" class="px-6 py-3">
                        Type
                      </th>
                      <th style="width: 20%" scope="col" class="px-6 py-3">
                        Date
                      </th>
                      <th style="width: 10%" scope="col" class="px-6 py-3">
                        Remove
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(vaccine, index) in vaccines"
                      :key="index"
                      class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                    >
                      <td
                        scope="row"
                        class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {{ vaccine.properName }}
                      </td>
                      <td class="px-6 py-2">{{ vaccine.type }}</td>
                      <td class="px-6 py-2">
                        {{ vaccine.date.substring(0, 10) }}
                      </td>

                      <td class="px-6 py-2">
                        <i
                          class="fa-solid fa-trash-can cursor-pointer"
                          @click="handleRemoveVaccine(vaccine)"
                        ></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div v-else class="text-base p-3">
                  This employee has no vaccines.
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- SIDA BADGES -->
        <div class="border border-gray-500 rounded-md py-2 px-3 mb-1">
          <div>
            <div
              class="flex justify-between cursor-pointer"
              @click="showSidaBadges = !showSidaBadges"
            >
              <div class="font-base">SIDA Badges</div>
            </div>

            <div class="relative overflow-x-auto" v-if="showSidaBadges">
              <table
                class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
                v-if="sidaBadges.length"
              >
                <thead
                  class="text-xs text-gray-200 uppercase bg-gray-500 dark:bg-gray-700 dark:text-gray-400"
                >
                  <tr>
                    <th scope="col" class="px-6 py-3">Airport</th>
                    <th style="width: 10%" scope="col" class="px-6 py-3">
                      Number
                    </th>
                    <th style="width: 20%" scope="col" class="px-6 py-3">
                      Expiration Date
                    </th>
                    <th style="width: 10%" scope="col" class="px-6 py-3">
                      Remove
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(sidaBadge, index) in sidaBadges"
                    :key="index"
                    class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <td
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {{ sidaBadge.airportOfIssue }}
                    </td>
                    <td class="px-6 py-2">{{ sidaBadge.number }}</td>
                    <td class="px-6 py-2">
                      {{ sidaBadge.expirationDate.substring(0, 10) }}
                    </td>

                    <td class="px-6 py-2">
                      <i
                        class="fa-solid fa-trash-can cursor-pointer"
                        @click="handleRemoveSidaBadge(sidaBadge)"
                      ></i>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div v-else class="text-base p-3">
                This employee has no SIDA Badges.
              </div>
            </div>
          </div>
        </div>
        <!-- CAC Card -->
        <div class="border border-gray-500 rounded-md py-2 px-3 mb-1">
          <div>
            <div
              class="flex justify-between cursor-pointer"
              @click="showCacCard = !showCacCard"
            >
              <div class="font-base">CAC Card</div>
            </div>

            <div class="relative overflow-x-auto" v-if="showCacCard">
              <table
                class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
                v-if="cacCard"
              >
                <thead
                  class="text-xs text-gray-200 uppercase bg-gray-500 dark:bg-gray-700 dark:text-gray-400"
                >
                  <tr>
                    <th scope="col" class="px-6 py-3">Number</th>
                    <th style="width: 20%" scope="col" class="px-6 py-3">
                      Expiration Date
                    </th>
                    <th style="width: 10%" scope="col" class="px-6 py-3">
                      Remove
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <td
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {{ cacCard.number }}
                    </td>
                    <td class="px-6 py-2">
                      {{ cacCard.expirationDate.substring(0, 10) }}
                    </td>

                    <td class="px-6 py-2">
                      <i
                        class="fa-solid fa-trash-can cursor-pointer"
                        @click="handleRemoveCacCard()"
                      ></i>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div v-else class="text-base p-3">
                This employee has no CAC Card.
              </div>
            </div>
          </div>
        </div>
        <!-- KCM BADGE -->
        <div class="border border-gray-500 rounded-md py-2 px-3 mb-1">
          <div>
            <div
              class="flex justify-between cursor-pointer"
              @click="showKcmBadge = !showKcmBadge"
            >
              <div class="font-base">KCM Badge</div>
            </div>

            <div class="relative overflow-x-auto" v-if="showKcmBadge">
              <table
                class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
                v-if="kcmBadge"
              >
                <thead
                  class="text-xs text-gray-200 uppercase bg-gray-500 dark:bg-gray-700 dark:text-gray-400"
                >
                  <tr>
                    <th scope="col" class="px-6 py-3">Number</th>
                    <th style="width: 10%" scope="col" class="px-6 py-3">
                      Remove
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <td
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {{ kcmBadge.number }}
                    </td>

                    <td class="px-6 py-2">
                      <i
                        class="fa-solid fa-trash-can cursor-pointer"
                        @click="handleRemoveKcmBadge()"
                      ></i>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div v-else class="text-base p-3">
                This employee has no KCM Badge.
              </div>
            </div>
          </div>
        </div>
        <!-- DRIVERS LICENSES -->
        <div class="border border-gray-500 rounded-md py-2 px-3 mb-1">
          <div>
            <div
              class="flex justify-between cursor-pointer"
              @click="showDriversLicenses = !showDriversLicenses"
            >
              <div class="font-base">Driver's Licenses</div>
            </div>

            <div class="relative overflow-x-auto" v-if="showDriversLicenses">
              <table
                class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
                v-if="driversLicenses.length"
              >
                <thead
                  class="text-xs text-gray-200 uppercase bg-gray-500 dark:bg-gray-700 dark:text-gray-400"
                >
                  <tr>
                    <th scope="col" class="px-6 py-2">State</th>
                    <th style="width: 10%" scope="col" class="px-6 py-2">
                      Number
                    </th>
                    <th style="width: 20%" scope="col" class="px-6 py-2">
                      Expiration Date
                    </th>
                    <th style="width: 10%" scope="col" class="px-6 py-2">
                      Remove
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(license, index) in driversLicenses"
                    :key="index"
                    class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <td
                      scope="row"
                      class="px-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {{ license.state }}
                    </td>
                    <td class="px-6 py-2">{{ license.number }}</td>
                    <td class="px-6 py-2">
                      {{ license.expirationDate.substring(0, 10) }}
                    </td>

                    <td class="px-6 py-2">
                      <i
                        class="fa-solid fa-trash-can cursor-pointer"
                        @click="handleRemoveDriversLicense(license)"
                      ></i>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div v-else class="text-base p-3">
                This employee has no driver's licenses.
              </div>
            </div>
          </div>
        </div>
        <!-- TRAINING RECORDS -->
        <div class="border border-gray-500 rounded-md py-2 px-3 mb-1">
          <div>
            <div
              class="flex justify-between cursor-pointer"
              @click="showTrainingRecords = !showTrainingRecords"
            >
              <div class="font-base">Training Records</div>
              <div v-if="trainingRecords.certificates.length">
                {{ `Certificates: ${trainingRecords.certificates.length}` }}
              </div>
              <div v-else class="text-red-500">No Certificates</div>
            </div>

            <div class="flex my-2">
              <div>
                <p class="text-sm text-gray-500">Private Login Name</p>
                <input
                  class="block w-64 rounded-md border-0 py-1.5 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  :class="{ missing: !pelesysLoginName }"
                  type="text"
                  maxlength="100"
                  v-model="pelesysLoginName"
                />
              </div>

              <div>
                <p class="text-sm text-gray-500">Public Login Name</p>
                <input
                  class="ml-1 block w-64 rounded-md border-0 py-1.5 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  :class="{ missing: !publicPelesysLoginName }"
                  type="text"
                  maxlength="100"
                  v-model="publicPelesysLoginName"
                />
              </div>
            </div>

            <div
              class="max-h-[200px] lg:max-h-[400px] relative overflow-x-auto mt-3"
              v-if="showTrainingRecords"
            >
              <div v-if="trainingRecords.certificates.length">
                <table
                  class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
                >
                  <thead
                    class="text-xs text-gray-200 uppercase bg-gray-500 dark:bg-gray-700 dark:text-gray-400"
                  >
                    <tr>
                      <th
                        scope="col"
                        class="px-6 py-2 cursor-pointer"
                        @click="sortCertificates('code')"
                      >
                        Code
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-2 cursor-pointer"
                        @click="sortCertificates('title')"
                      >
                        Title
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-2 cursor-pointer"
                        @click="sortCertificates('completedDate')"
                      >
                        Completed Date
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-2 cursor-pointer"
                        @click="sortCertificates('isExpired')"
                      >
                        Expired
                      </th>
                      <th scope="col" class="px-6 py-2">Expiration Date</th>
                      <th scope="col" class="px-6 py-2">
                        Expiration Date (Grace)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(
                        certificate, index
                      ) in trainingRecords.certificates"
                      :key="index"
                      class="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                      :class="{ 'is-expired': certificate.isExpired }"
                    >
                      <td scope="row" class="px-6 py-1">
                        {{ certificate.code }}
                      </td>
                      <td class="px-6 py-1">
                        {{ certificate.title }}
                      </td>
                      <td class="px-6 py-1">
                        {{ formatDate(certificate.completedDate) }}
                      </td>
                      <td class="px-6 py-1">
                        {{ certificate.isExpired ? "YES" : "NO" }}
                      </td>
                      <td class="px-6 py-1">
                        {{
                          certificate.isExpired
                            ? formatDate(certificate.expirationDate)
                            : "N/A"
                        }}
                      </td>

                      <td class="px-6 py-1">
                        {{
                          certificate.isExpired
                            ? formatDate(certificate.graceExpirationDate)
                            : "N/A"
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div class="flex justify-end px-2 my-2 text-sm text-gray-400">
                  {{ `Updated: ${formatDateTime(trainingRecords.updatedAt)}` }}
                </div>
              </div>

              <div v-else class="text-base p-3">
                This employee has no training records.
              </div>
            </div>
          </div>
        </div>

        <div class="flex justify-between text-sm text-gray-300 ma-2">
          <div>{{ `UPN: ${userPrincipalName}` }}</div>
          <div>{{ `ID: ${azureId}` }}</div>
        </div>
        <div class="flex justify-between mt-2">
          <div class="flex">
            <button
              v-if="confirmDelete"
              @click="handleDeleteEmployee"
              class="btn bg-red-500 text-white"
            >
              Confirm Delete
            </button>

            <button
              v-else
              @click="confirmDelete = true"
              class="btn bg-red-500 text-white"
            >
              Delete Employee
            </button>
          </div>

          <div class="flex justify-between">
            <button @click="handleGoBack()" class="btn bg-gray-500 text-white">
              Cancel
            </button>

            <button
              @click="handleUpdateEmployee"
              class="ml-1 btn bg-green-500 text-white"
            >
              Save Profile
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../services/api";
import msalInstance from "../services/msal";
import authentication from "../services/authentication";
import { mixin } from "../mixins/mixin";
import axios from "axios";
import RSnackbar from "../components/RSnackbar.vue";
import RSpinner from "../components/RSpinner.vue";
import RModal from "../components/RModal.vue";
import { previousRoute } from "../router";

export default {
  name: "employeeUpdate",
  components: { RSnackbar, RSpinner, RModal },
  mixins: [mixin],
  data() {
    return {
      inactivityTimer: null,

      allowedAppRoles: ["SystemAdmin", "admin"],
      previousRouteName: previousRoute.name,
      //-------------------------Sorting
      sortCertificatesCode: "",
      sortCertificatesTitle: "",
      sortCertificatesCompletedDate: "",
      sortCertificatesIsExpired: "",

      //-------------------------Employee
      pelesysLoginName: "login-name",
      azureId: "",
      userPrincipalName: "",
      displayName: "",
      givenName: "",
      surname: "",
      jobTitle: "",
      companyName: "",
      mail: "",
      secondaryMail: "",
      mobilePhone: "",
      emergencyContact: {
        name: "",
        number: "",
      },
      dateOfHire: new Date().toISOString().substring(0, 10),
      seniorityNumber: 0,
      imageUrl: "profile-default.png",
      gatewayCode: "",
      vaccines: [],
      address: {
        street: "",
        city: "",
        state: "",
        country: "United States",
        postalCode: "",
      },
      status: "",
      publicPelesysLoginName: "login-name",
      securityGroupId: null,
      subGatewayCodes: [],
      companyId: {
        number: "",
        expirationDate: new Date().toISOString().substring(0, 10),
        image: "employee-id-default.png",
      },
      passports: [],
      visas: [],
      sidaBadges: [],
      cacCard: null,
      kcmBadge: null,
      driversLicenses: [],
      trainingRecords: {
        updatedAt: {
          $date: "2025-05-01T20:01:12.282Z",
        },
        updatedBy: "Process",
        certificates: [
          // {
          //   type: "Private",
          //   code: "PAXSAINT",
          //   title: "Passenger Sable Initial",
          //   completedDate: "2014-09-29T00:00:00Z",
          //   expirationDate: null,
        ],
      },

      statusOptions: ["Active", "Inactive"],

      //-------------------------Snackbar
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,

      //--------------------------Data
      loading: false,
      error: null,
      confirmDelete: false,

      showWarningsModal: false,
      warnings: [],

      allowedFileTypes: ["image/png", "image/jpeg"],

      // Company ID
      newCompanyId: null,
      companyIdError: null,
      showCompanyIdModal: false,
      newImageUrl: null, //Profile Image
      fileOne: null, //Profile Image
      fileTwo: null, //Company ID Image

      // Passports
      passport: null,
      passportError: null,
      showPassportModal: false,
      showPassports: true,

      // Visas
      visa: null,
      visaError: null,
      showVisaModal: false,
      showVisas: true,

      // Vaccines
      vaccine: null,
      vaccineError: null,
      showVaccineModal: false,
      showVaccines: true,
      vaccineProperNames: [
        "Tetanus",
        "Diphtheria",
        "Pertussis",
        "Hepatitis A",
        "Hepatitis B",
        "Human Papillomavirus",
        "Measles",
        "Mumps",
        "Rubella",
        "Varicella",
        "Meningococcal ACWY",
        "Meningococcal B",
        "Influenza",
        "Pneumococcal conjugate",
        "Pneumococcal polysaccahride",
        "Zoster",
        "Hib",
        "Yellow Fever",
        "Covid-19",
      ],
      vaccineTypes: [],

      // SIDA Badges
      sidaBadge: null,
      sidaBadgeError: null,
      showSidaBadgeModal: false,
      showSidaBadges: true,

      // Cac Card
      newCacCard: null,
      cacCardError: null,
      showCacCardModal: false,
      showCacCard: true,

      // Kcm Badge
      newKcmBadge: null,
      showKcmBadgeModal: false,
      showKcmBadge: true,

      // DriversLicenses
      driversLicense: null,
      driversLicenseError: null,
      showDriversLicenseModal: false,
      showDriversLicenses: true,

      //Training Records
      showTrainingRecords: true,

      baseImageUrl:
        "https://dev-monarch-employee-profile-images.s3.amazonaws.com/",
    };
  },
  props: {
    employee_id: {
      type: String,
      required: true,
    },
  },

  watch: {
    // Watch for changes in the previous route
    "previousRoute.name"(newName) {
      this.previousRouteName = newName;
    },
  },
  created() {
    (async () => {
      this.getData();
    })();
  },

  mounted() {
    this.startInactivityTimer();
    document.addEventListener("click", this.resetInactivityTimer);
  },
  unmounted() {
    this.clearInactivityTimer();
    document.removeEventListener("click", this.resetInactivityTimer);
  },

  beforeDestroy() {
    clearTimeout(this.inactivityTimer);
  },

  computed: {
    auth() {
      return this.$store.state.auth;
    },

    securityGroups() {
      return this.$store.getters.securityGroups;
    },

    userCompanies() {
      let companies = [];

      this.user.securityGroup.companies.forEach((item) => {
        companies.push(item);
      });

      return companies;
    },

    user() {
      return this.$store.state.user;
    },

    fileOneName() {
      if (this.fileOne) {
        return this.fileOne.name;
      } else {
        return null;
      }
    },

    fileOneSize() {
      if (this.fileOne) {
        return Math.floor(this.fileOne.size / 1000);
      } else {
        return 0;
      }
    },

    fileOneExtension() {
      if (this.fileOneName) {
        return this.fileOneName.substr(this.fileOneName.lastIndexOf(".") + 1);
      } else {
        return null;
      }
    },

    fileOneType() {
      if (this.fileOne) {
        return this.fileOne.type;
      } else {
        return null;
      }
    },

    profileImageUrl() {
      if (this.newImageUrl) {
        return `${this.employeeProfileImagesBucketUrl}${this.newImageUrl}`;
      } else {
        return `${this.employeeProfileImagesBucketUrl}${this.imageUrl}`;
      }
    },

    isValidVaccine() {
      return this.vaccine.properName && this.vaccine.type;
    },

    certificates() {
      let certificates = [];

      if (this.trainingRecords) {
        if (this.showExpiredOnly) {
          certificates = this.trainingRecords.certificates.filter(
            (certificate) => {
              return certificate.expirationDate && certificate.isExpired;
            }
          );
        } else {
          certificates = [...this.trainingRecords.certificates];
        }
      }

      return certificates;
    },
  },

  methods: {
    startInactivityTimer() {
      this.clearInactivityTimer();
      this.inactivityTimer = setTimeout(() => {
        this.handleAutoLogout();
      }, this.timeoutDuration);
    },

    clearInactivityTimer() {
      if (this.inactivityTimer) {
        clearTimeout(this.inactivityTimer);
      }
    },

    resetInactivityTimer() {
      this.startInactivityTimer();
    },

    async handleAutoLogout() {
      try {
        if (!this.auth) {
          this.$store.commit("updateUser", null);
          clearTimeout(this.inactivityTimer);
          this.$router.push({ name: "home" });
          return;
        }

        //---------------------------------------Refresh Token
        this.loading = true;
        const accessToken = await authentication.getAccessToken();
        if (!accessToken) {
          this.loading = false;
          this.$router.push({ name: "home" });
          return;
        }

        const res = await api.get("/auth/logout", {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        });

        this.loading = false;

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }

        this.$store.commit("updateAuth", null);
        this.$store.commit("updateUser", null);
        clearTimeout(this.inactivityTimer);
        this.$router.push({ name: "home" });
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarVisible = true;
        this.snackbarText = `Error during logout: ${error}`;

        setTimeout(() => {
          this.$store.commit("updateAuth", null);
          this.$store.commit("updateUser", null);
          clearTimeout(this.inactivityTimer);
          this.$router.push({ name: "home" });
        }, 3000);
      }
    },

    sortCertificates(field) {
      switch (field) {
        case "code":
          if (this.sortCertificatesCode === "ASC") {
            this.trainingRecords.certificates.sort((a, b) => {
              if (a.code < b.code) {
                return -1;
              }
              if (b.code < a.code) {
                return 1;
              }
              return 0;
            });

            this.sortCertificatesCode = "DESC";
          } else {
            this.trainingRecords.certificates.sort((a, b) => {
              if (a.code > b.code) {
                return -1;
              }
              if (b.code > a.code) {
                return 1;
              }
              return 0;
            });

            this.sortCertificatesCode = "ASC";
          }

          break;
        case "title":
          if (this.sortCertificatesTitle === "ASC") {
            this.trainingRecords.certificates.sort((a, b) => {
              if (a.title < b.title) {
                return -1;
              }
              if (b.title < a.title) {
                return 1;
              }
              return 0;
            });

            this.sortCertificatesTitle = "DESC";
          } else {
            this.trainingRecords.certificates.sort((a, b) => {
              if (a.title > b.title) {
                return -1;
              }
              if (b.title > a.title) {
                return 1;
              }
              return 0;
            });

            this.sortCertificatesTitle = "ASC";
          }

          break;
        case "completedDate":
          if (this.sortCertificatesCompletedDate === "ASC") {
            this.trainingRecords.certificates.sort((a, b) => {
              if (a.completedDate < b.completedDate) {
                return -1;
              }
              if (b.completedDate < a.completedDate) {
                return 1;
              }
              return 0;
            });

            this.sortCertificatesCompletedDate = "DESC";
          } else {
            this.trainingRecords.certificates.sort((a, b) => {
              if (a.completedDate > b.completedDate) {
                return -1;
              }
              if (b.completedDate > a.completedDate) {
                return 1;
              }
              return 0;
            });

            this.sortCertificatesCompletedDate = "ASC";
          }

          break;

        case "isExpired":
          if (this.sortCertificatesIsExpired === "ASC") {
            this.trainingRecords.certificates.sort((a, b) => {
              if (a.isExpired < b.isExpired) {
                return -1;
              }
              if (b.isExpired < a.isExpired) {
                return 1;
              }
              return 0;
            });

            this.sortCertificatesIsExpired = "DESC";
          } else {
            this.trainingRecords.certificates.sort((a, b) => {
              if (a.isExpired > b.isExpired) {
                return -1;
              }
              if (b.isExpired > a.isExpired) {
                return 1;
              }
              return 0;
            });

            this.sortCertificatesIsExpired = "ASC";
          }

          break;

        default:
          break;
      }
    },

    handleGoBack() {
      this.$router.push({ name: this.previousRouteName });
    },

    handleOnFileOneChange(e) {
      this.fileOne = e.target.files[0];

      this.companyIdError = null;
      let errors = [];

      if (this.fileOneSize > 100000) {
        errors.push("Image should be smaller than 1,000 KB");
      }

      if (
        this.fileOneType !== "image/png" &&
        this.fileOneType !== "image/jpeg"
      ) {
        errors.push("Image should be of type png or jpeg");
      }

      if (errors.length) {
        this.companyIdError = errors.toString();
      }
    },

    isValidEmail(email) {
      const validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      return email.match(validRegex);
    },

    //----------------------------Company ID
    handleShowCompanyIdDialog() {
      this.newCompanyId = {
        ...this.companyId,
      };

      this.showCompanyIdModal = true;
    },

    async handleSaveCompanyId() {
      //-------------------------------Profile Image
      // Run only if fileOne !== null

      if (this.fileOne) {
        if (
          !this.allowedFileTypes.includes(this.fileOneType) ||
          this.fileOneSize > 1000000
        ) {
          this.snackbarColor = "red";
          this.snackbarText = "Please check image requirements";
          this.snackbarVisible = true;
          return;
        }

        //---------------------------------------Refresh Token
        this.loading = true;
        const accessToken = await authentication.getAccessToken();
        if (!accessToken) {
          this.loading = false;
          this.$router.push({ name: "home" });
          return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(this.fileOne);
        reader.onload = async () => {
          const encodedFile = reader.result.split(",")[1];
          const imageOneData = {
            fileOne: encodedFile,
            fileName: this.fileOneName,
            fileExtension: this.fileOneExtension,
            fileOneType: this.fileOneType,
          };

          try {
            const res = await api.post(
              `/employees/images/profile/${this.employee_id}`,
              {
                contentType: imageOneData.fileOneType,
              },
              {
                headers: {
                  Authorization: "Bearer " + accessToken,
                },
              }
            );

            if (res.status !== 200) {
              this.snackbarColor = "red";
              this.snackbarText = res.message;
              this.snackbarVisible = true;
              return;
            }

            const s3url = res.data.url;

            const s3Response = await axios.put(s3url, this.fileOne, {
              headers: {
                "Content-Type": imageOneData.fileOneType,
              },
            });

            this.loading = false;

            if (s3Response.status === 200) {
              this.newImageUrl = res.data.key;
              this.showEmployeeImageDialog = false;
            } else {
              this.snackbarColor = "red";
              this.snackbarText = s3Response.statusText;
              this.snackbarVisible = true;
            }
          } catch (error) {
            this.loading = false;
            this.snackbarColor = "red";
            this.snackbarText = error.message;
            this.snackbarVisible = true;
          }
        };
      }

      this.companyId = { ...this.newCompanyId };

      this.showCompanyIdModal = false;
    },

    //-----------------------------Passport
    handleShowPassportDialog() {
      this.passport = {
        number: "",
        country: "United States",
        expirationDate: new Date().toISOString().substring(0, 10),
      };

      this.showPassportModal = true;
    },

    handleAddPassport() {
      this.passportError = null;

      if (!this.passport.number || this.passport.number.length !== 4) {
        this.passportError = "Passport Number must be 4 characters long.";
        return;
      }

      const i = this.passports.findIndex((p) => {
        return (
          p.country === this.passport.country &&
          p.number === this.passport.number
        );
      });

      if (i >= 0) {
        this.passports[i] = { ...this.passport };
      } else {
        this.passports.unshift({ ...this.passport });
      }

      this.passport = null;
      this.showPassportModal = false;
      this.showPassports = true;
    },

    handleRemovePassport(passport) {
      const i = this.passports.findIndex((p) => {
        return p.country === passport.country && p.number === passport.number;
      });

      if (i >= 0) {
        this.passports.splice(i, 1);
      }
    },

    //-----------------------------Visa
    handleShowVisaDialog() {
      this.visa = {
        number: "",
        country: "China",
        expirationDate: new Date().toISOString().substring(0, 10),
      };

      this.showVisaModal = true;
    },

    handleAddVisa() {
      this.visaError = null;

      if (!this.visa.number || this.visa.number.length !== 4) {
        this.visaError = "Visa Number must be 4 characters long.";
        return;
      }

      const i = this.visas.findIndex((p) => {
        return p.country === this.visa.country && p.number === this.visa.number;
      });

      if (i >= 0) {
        this.visas[i] = { ...this.visa };
      } else {
        this.visas.unshift({ ...this.visa });
      }

      this.visa = null;
      this.showVisaModal = false;
      this.showVisas = true;
    },

    handleRemoveVisa(visa) {
      const i = this.visas.findIndex((p) => {
        return p.country === visa.country && p.number === visa.number;
      });

      if (i >= 0) {
        this.visas.splice(i, 1);
      }
    },

    //---------------------------Vaccine
    handleShowVaccineDialog() {
      this.vaccine = {
        properName: "Covid-19",
        date: new Date().toISOString().substring(0, 10),
      };

      this.handleUpdateVaccineTypes();

      this.showVaccineModal = true;
    },

    handleAddVaccine() {
      this.vaccineError = null;

      const i = this.vaccines.findIndex((p) => {
        return (
          p.properName === this.vaccine.properName &&
          p.type === this.vaccine.type &&
          Math.abs(new Date(p.date).getTime() - new Date(this.vaccine.date)) <
            24 * 60 * 60 * 1000
        );
      });

      if (i >= 0) {
        this.vaccines[i] = { ...this.vaccine };
      } else {
        this.vaccines.unshift({ ...this.vaccine });
      }

      this.vaccine = null;
      this.showVaccineModal = false;
      this.showVaccines = true;
    },

    handleRemoveVaccine(vaccine) {
      const i = this.vaccines.findIndex((p) => {
        return p.properName === vaccine.properName && p.type === vaccine.type;
      });

      if (i >= 0) {
        this.vaccines.splice(i, 1);
      }
    },

    //--------------------------SIDA Badge
    handleShowSidaBadgeDialog() {
      this.sidaBadge = {
        number: "",
        country: "MIA",
        expirationDate: new Date().toISOString().substring(0, 10),
      };

      this.showSidaBadgeModal = true;
    },

    handleAddSidaBadge() {
      this.sidaBadgeError = null;

      if (!this.sidaBadge.number || this.sidaBadge.number.length !== 4) {
        this.sidaBadgeError = "SIDA Badge Number must be 4 characters long.";
        return;
      }

      this.sidaBadge.airportOfIssue.toUpperCase();

      const i = this.sidaBadges.findIndex((p) => {
        return (
          p.airportOfIssue.toUpperCase() === this.sidaBadge.airportOfIssue &&
          p.number === this.sidaBadge.number
        );
      });

      if (i >= 0) {
        this.sidaBadges[i] = { ...this.sidaBadge };
      } else {
        this.sidaBadges.unshift({ ...this.sidaBadge });
      }

      this.sidaBadge = null;
      this.showSidaBadgeModal = false;
      this.showSidaBadges = true;
    },

    handleRemoveSidaBadge(sidaBadge) {
      const i = this.sidaBadges.findIndex((p) => {
        return (
          p.airportOfIssue === sidaBadge.airportOfIssue &&
          p.number === sidaBadge.number
        );
      });

      if (i >= 0) {
        this.sidaBadges.splice(i, 1);
      }
    },

    //--------------------------CAC Card
    handleShowCacCardDialog() {
      this.newCacCard = {
        number: "",
        expirationDate: new Date().toISOString().substring(0, 10),
      };

      this.showCacCardModal = true;
    },

    handleAddCacCard() {
      this.cacCardError = null;

      if (!this.newCacCard.number || this.newCacCard.number.length !== 4) {
        this.cacCardError = "CAC Card Number must be 4 characters long.";
        return;
      }

      this.cacCard = { ...this.newCacCard };
      this.newCacCard = null;
      this.showCacCardModal = false;
      this.showCacCard = true;
    },

    handleRemoveCacCard() {
      this.cacCard = null;
    },

    //--------------------------KCM Badge
    handleShowKcmBadgeDialog() {
      this.newKcmBadge = {
        number: "",
      };

      this.showKcmBadgeModal = true;
    },

    handleAddKcmBadge() {
      this.kcmBadge = { ...this.newKcmBadge };
      this.newKcmBadge = null;
      this.showKcmBadgeModal = false;
      this.showKcmBadge = true;
    },

    handleRemoveKcmBadge() {
      this.kcmBadge = null;
    },

    //--------------------------Driver's License
    handleShowDriversLicenseDialog() {
      this.driversLicense = {
        number: "",
        state: "Florida",
        expirationDate: new Date().toISOString().substring(0, 10),
      };

      this.showDriversLicenseModal = true;
    },

    handleAddDriversLicense() {
      this.driversLicenseError = null;

      if (
        !this.driversLicense.number ||
        this.driversLicense.number.length !== 4
      ) {
        this.driversLicenseError =
          "Driver's License Number must be 4 characters long.";
        return;
      }

      const i = this.driversLicenses.findIndex((l) => {
        return l.state === this.driversLicense.state;
      });

      if (i >= 0) {
        this.driversLicenses[i] = { ...this.driversLicense };
      } else {
        this.driversLicenses.unshift({ ...this.driversLicense });
      }

      this.driversLicense = null;
      this.showDriversLicenseModal = false;
      this.showDriversLicenses = true;
    },

    handleRemoveDriversLicense(driversLicense) {
      const i = this.driversLicenses.findIndex((p) => {
        return (
          p.state === driversLicense.state && p.number === driversLicense.number
        );
      });

      if (i >= 0) {
        this.driversLicenses.splice(i, 1);
      }
    },

    formatCertificateDate(d) {
      if (d) {
        return new Date(d).toUTCString().substring(5, 16);
      } else {
        return "N/A";
      }
    },

    formatIsExpired(certificate) {
      if (certificate.expirationDate) {
        if (certificate.isExpired) {
          return "Yes";
        } else {
          return "No";
        }
      } else {
        return "No";
      }
    },

    formatIsExpiredColor(certificate) {
      if (certificate.expirationDate) {
        if (certificate.isExpired) {
          return "red";
        } else {
          return "green";
        }
      } else {
        return "green";
      }
    },

    handleShowWarningsDialog() {
      if (this.warnings.length) {
        this.showWarningsDialog = true;
      }
    },

    async handleGetTrainingRecords() {
      if (!this.pelesysLoginName || !this.publicPelesysLoginName) {
        this.snackbarColor = "red";
        this.snackbarText = "Please enter Pelesys Login Names";
        this.snackbarVisible = true;
        return;
      }

      //---------------------------------------Refresh Token
      this.loading = true;
      const accessToken = await authentication.getAccessToken();
      if (!accessToken) {
        this.loading = false;
        this.$router.push({ name: "home" });
        return;
      }

      try {
        const res = await api.get(`/employees/training/records`, {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
          params: {
            pelesysLoginName: this.pelesysLoginName,
            publicPelesysLoginName: this.publicPelesysLoginName,
          },
        });

        this.loading = false;

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }

        this.trainingRecords = res.data.trainingRecords;
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    handleUpdateVaccineTypes() {
      if (this.vaccine) {
        switch (this.vaccine.properName) {
          case "Tetanus":
          case "Diphtheria":
          case "Pertussis":
            this.vaccineTypes = ["Tdap", "Td"];
            this.vaccine.type = "Tdap";
            break;

          case "Hepatitis A":
            this.vaccineTypes = ["HepA", "HepA-HepB"];
            this.vaccine.type = "HepA";
            break;

          case "Hepatitis B":
            this.vaccineTypes = [
              "Engerix-B",
              "Recombi-vax HB",
              "Heplisav-B",
              "HepB",
              "HepA-HepB",
            ];

            this.vaccine.type = "Engerix-B";

            break;

          case "Human Papillomavirus":
            this.vaccineTypes = ["HPV2", "HPV4", "HPV9"];
            this.vaccine.type = "HPV2";
            break;

          case "Measles":
            this.vaccineTypes = ["MMR"];
            this.vaccine.type = "MMR";

            break;

          case "Mumps":
            this.vaccineTypes = ["MMR"];
            this.vaccine.type = "MMR";
            break;

          case "Rubella":
            this.vaccineTypes = ["MMR"];
            this.vaccine.type = "MMR";
            break;

          case "Varicella":
            this.vaccineTypes = ["Chickenpox", "VAR"];
            this.vaccine.type = "Chickenpox";

            break;

          case "Meningococcal ACWY":
            this.vaccineTypes = ["MeanACWY", "MPSV4"];
            this.vaccine.type = "MeanACWY";
            break;

          case "Meningococcal B":
            this.vaccineTypes = ["MenB"];
            this.vaccine.type = "MenB";

            break;

          case "Influenza":
            this.vaccineTypes = [
              "IIV3",
              "IIV4",
              "ccIIV4",
              "RIV3",
              "RIV4",
              "LAIV4",
            ];
            this.vaccine.type = "IIV3";

            break;

          case "Pneumococcal conjugate":
            this.vaccineTypes = ["PCV13"];
            this.vaccine.type = "PCV13";
            break;

          case "Pneumococcal polysaccahride":
            this.vaccineTypes = ["PPSV23"];
            this.vaccine.type = "PPSV23";
            break;

          case "Zoster":
            this.vaccineTypes = ["Shingles"];
            this.vaccine.type = "Shingles";
            break;

          case "Hib":
            this.vaccineTypes = [];
            this.vaccine.type = "N/A";
            break;

          case "Yellow Fever":
            this.vaccineTypes = ["YF-Vax"];
            this.vaccine.type = "YF-Vax";

            break;

          case "Covid-19":
            this.vaccineTypes = ["Pfizer", "Moderna", "Johnson & Johnson"];
            this.vaccine.type = "Pfizer";
            break;

          default:
            this.vaccineTypes = [];
            this.vaccine.type = "N/A";
            break;
        }
      } else {
        this.vaccine.type = [];
        this.vaccine.type = "N/A";
      }
    },

    openEmployeeIdDialog() {
      this.showEmployeeIdImageDialog = true;
    },

    async getData() {
      this.error = {};

      //---------------------------------------Refresh Token
      this.loading = true;
      const accessToken = await authentication.getAccessToken();
      if (!accessToken) {
        this.loading = false;
        this.$router.push({ name: "home" });
        return;
      }

      try {
        const res = await api.get(`/employees/${this.employee_id}`, {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        });

        this.loading = false;

        if (res.status !== 200) {
          this.error = {
            message: "Error loading data",
          };

          this.snackbarColor = "red";
          this.snackbarText = this.error.message;
          return;
        }

        const employee = res.data.employee;

        this.pelesysLoginName = employee.pelesysLoginName
          ? employee.pelesysLoginName
          : "login-name";
        this.publicPelesysLoginName = employee.publicPelesysLoginName
          ? employee.publicPelesysLoginName
          : "login-name";
        this.azureId = employee.azureId;
        this.userPrincipalName = employee.userPrincipalName;
        this.displayName = employee.displayName;
        this.givenName = employee.givenName;
        this.surname = employee.surname;
        this.jobTitle = employee.jobTitle;
        this.companyName = employee.companyName;
        this.mail = employee.mail;
        this.mobilePhone = employee.mobilePhone;
        this.visas = [...employee.visas];
        this.dateOfHire = new Date(employee.dateOfHire)
          .toISOString()
          .substring(0, 10);
        this.seniorityNumber = employee.seniorityNumber;
        this.imageUrl = employee.imageUrl;
        this.gatewayCode = employee.gatewayCode;
        this.vaccines = [...employee.vaccines];
        this.status = employee.status;

        this.securityGroupId = employee.securityGroupId;
        this.companyId = {
          ...employee.companyId,
          expirationDate: new Date(employee.companyId.expirationDate)
            .toISOString()
            .substring(0, 10),
        };

        //---------------------Conditional Values

        if (employee.secondaryMail) {
          this.secondaryMail = employee.secondaryMail;
        } else {
          this.secondaryMail = "";
        }

        if (employee.emergencyContact) {
          this.emergencyContact = {
            ...employee.emergencyContact,
          };
        } else {
          this.emergencyContact = {
            name: "",
            number: "",
          };
        }

        if (employee.address) {
          this.address = {
            ...employee.address,
          };
        } else {
          this.address = {
            street: "",
            city: "",
            state: "",
            country: "United States",
            postalCode: "",
          };
        }

        if (employee.subGatewayCodes?.length) {
          this.subGatewayCodes = employee.subGatewayCodes.toString();
        } else {
          this.subGatewayCodes = "";
        }

        if (employee.trainingRecords) {
          this.trainingRecords = {
            ...employee.trainingRecords,
          };
        } else {
          this.trainingRecords = {
            updatedAt: new Date(),
            updatedBy: "Process",
            certificates: [],
          };
        }

        this.passports = employee.documents.filter((document) => {
          return document.type === "Passport";
        });

        this.visas = [...employee.visas];

        this.sidaBadges = employee.documents.filter((document) => {
          return document.type === "SIDA Badge";
        });

        this.cacCard = employee.documents.find((document) => {
          return document.type === "CAC Card";
        });

        this.kcmBadge = employee.documents.find((document) => {
          return document.type === "KCM Badge";
        });

        this.driversLicenses = employee.documents.filter((document) => {
          return document.type === "Driver's License";
        });

        this.error = null;
      } catch (error) {
        this.error = {
          message: error,
        };

        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = this.error.message;
        this.snackbarVisible = true;

        setTimeout(() => {
          this.$router.go(-1);
        }, 2000);
      }
    },

    async handleSearchActiveDirectory() {
      this.loading = true;

      try {
        await msalInstance.initialize();
        const request = {
          scopes: ["User.Read.All"],
        };

        // Acquire token
        const account = msalInstance.getAllAccounts()[0];

        if (!account) {
          this.loading = false;
          this.snackbarColor = "var(--red)";
          this.snackbarText =
            "No active account! Please sign in and try again.";
          this.snackbarVisible = true;
          return;
        }

        const response = await msalInstance.acquireTokenSilent({
          ...request,
          account,
        });
        const accessToken = response.accessToken;

        const res = await axios.get(
          `https://graph.microsoft.com/v1.0/users/${encodeURIComponent(
            this.userPrincipalName
          )}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        this.loading = false;

        this.activeDirectoryEmployee = {
          id: res.data.id,
          userPrincipalName: res.data.userPrincipalName,
        };

        this.azureId = res.data.id;
        this.userPrincipalName = res.data.userPrincipalName;
        this.displayName = res.data.displayName;
        this.givenName = res.data.givenName;
        this.surname = res.data.surname;
        this.jobTitle = this.user.securityGroup.jobTitles.includes(
          res.data.jobTitle
        )
          ? res.data.jobTitle
          : null;
        this.mail = res.data.mail;
        this.mobilePhone = res.data.mobilePhone;
      } catch (error) {
        let msg;

        if (error.response) {
          msg = error.response.data.error.message;
        } else {
          msg = error.message;
        }

        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = msg;
        this.snackbarVisible = true;
      }
    },

    async handleUpdateEmployee() {
      //--------------------------Documents
      let documents = [];

      if (this.secondaryMail && !this.isValidEmail(this.secondaryMail)) {
        this.snackbarColor = "red";
        this.snackbarText = "Please enter a valid secondary email";
        this.snackbarVisible = true;
        return;
      }

      if (this.passports.length) {
        this.passports.forEach((item) => {
          documents.push({
            type: "Passport",
            number: item.number,
            expirationDate: `${item.expirationDate}`,
            country: item.country,
          });
        });
      }

      if (this.driversLicenses.length) {
        this.driversLicenses.forEach((item) => {
          documents.push({
            type: "Driver's License",
            number: item.number,
            expirationDate: `${item.expirationDate}`,
            state: item.state.toUpperCase(),
          });
        });
      }

      if (this.sidaBadges.length) {
        this.sidaBadges.forEach((item) => {
          documents.push({
            type: "SIDA Badge",
            number: item.number,
            expirationDate: `${item.expirationDate}`,
            airportOfIssue: item.airportOfIssue.toUpperCase(),
          });
        });
      }

      if (this.cacCard) {
        documents.push({
          type: "CAC Card",
          number: this.cacCard.number,
          expirationDate: `${this.cacCard.expirationDate}`,
        });
      }

      if (this.kcmBadge) {
        documents.push({
          type: "KCM Badge",
          number: this.kcmBadge.number,
        });
      }

      let employee = {
        azureId: this.azureId,
        userPrincipalName: this.userPrincipalName,
        displayName: this.displayName,
        givenName: this.givenName,
        surname: this.surname,
        jobTitle: this.jobTitle,
        companyName: this.companyName,
        mail: this.mail,
        mobilePhone: this.mobilePhone,
        visas: [...this.visas],
        emergencyContact: {
          ...this.emergencyContact,
        },
        pelesysLoginName: this.pelesysLoginName,
        dateOfHire: `${this.dateOfHire}T23:59:59.999+00:00`,
        seniorityNumber: this.seniorityNumber,
        gatewayCode: this.gatewayCode,
        address: {
          ...this.address,
        },
        vaccines: [...this.vaccines],
        status: this.status,
        publicPelesysLoginName: this.publicPelesysLoginName,
        subGatewayCodes: [],
        securityGroupId: this.securityGroupId,
        secondaryMail: this.secondaryMail,
        trainingRecords: { ...this.trainingRecords },
        companyId: { ...this.companyId },
        documents,
      };

      //--------------------------------Profile Image
      if (this.newImageUrl) {
        employee.imageUrl = this.newImageUrl;
      } else {
        employee.imageUrl = this.imageUrl;
      }

      //--------------------------------Sub-Gateway Codes
      if (this.subGatewayCodes.length) {
        //Sub-Gateway Codes
        const codes = this.subGatewayCodes.split(",");
        let cleanCode;

        codes.forEach((item) => {
          cleanCode = item.trim().toUpperCase();
          if (
            cleanCode !== this.gatewayCode.toUpperCase() &&
            cleanCode.length === 3
          ) {
            //---------Limit the number of Sub-Gateways to 10
            if (employee.subGatewayCodes.length < 10) {
              employee.subGatewayCodes.push(cleanCode);
            }
          }
        });
      }

      //---------------------------------------Refresh Token
      this.loading = true;
      const accessToken = await authentication.getAccessToken();
      if (!accessToken) {
        this.loading = false;
        this.$router.push({ name: "home" });
        return;
      }

      try {
        const res = await api.put(`/employees/${this.employee_id}`, employee, {
          headers: { Authorization: "Bearer " + accessToken },
        });

        this.loading = false;

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }

        this.handleGoBack();
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    async handleDeleteEmployee() {
      this.confirmDelete = false;

      //---------------------------------------Refresh Token
      this.loading = true;
      const accessToken = await authentication.getAccessToken();
      if (!accessToken) {
        this.loading = false;
        this.$router.push({ name: "home" });
        return;
      }
      try {
        const res = await api.delete(`/employees/${this.employee_id}`, {
          headers: { Authorization: "Bearer " + accessToken },
        });

        this.loading = false;

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }

        this.$router.go(-1);
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    handleAcknowledgeWarnings() {
      this.showWarningsDialog = false;
    },

    navigateToFlightBoard() {
      this.$router.push({ name: "flightBoard" });
    },

    navigateToAdministration() {
      this.$router.push({ name: "administration" });
    },
  },
};
</script>

<style scoped>
.is-expired {
  background-color: tomato;
  color: white;
}

.profile-pic-figure {
  border: 1px solid gray;
  width: 150px;
  cursor: pointer;
}

.profile-pic-figure img {
  width: 100%;
  height: auto;
  min-width: 100px;
  max-width: 150px;
  border: 1px solid var(--gray);
}

.main-grid {
  display: grid;
  padding: 5px;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 5px;
  row-gap: 15px;
  align-items: center;
  margin: 0;
}

.container p {
  margin: 0;
  padding: 0;
  font-size: 0.8rem;
  color: var(--gray);
}

.employee-id,
.id-expiration-date,
.security-group,
.status,
.given-name,
.surname,
.job-title,
.company-name,
.gateway,
.sub-gateways,
.seniority-number,
.date-of-hire,
.mail,
.secondary-mail,
.mobile-phone,
.emergency-contact-name,
.emergency-contact-number,
.address-street,
.address-city,
.address-zip,
.address-state,
.address-country {
  color: #fff;
  font-size: 1.3rem;
  padding: 0 5px;
  grid-column: 1/13;
}

@media (min-width: 768px) {
  .employee-id {
    grid-column: 1/4;
    grid-row: 1;
  }

  .id-expiration-date {
    grid-column: 4/8;
    grid-row: 1;
  }

  .security-group {
    grid-column: 1/8;
    grid-row: 2;
  }

  .status {
    grid-column: 8/13;
    grid-row: 2;
  }

  .given-name {
    grid-column: 1/4;
    grid-row: 3;
  }

  .surname {
    grid-column: 4/8;
    grid-row: 3;
  }

  .job-title {
    grid-column: 8/13;
    grid-row: 3;
  }

  .company-name {
    grid-column: 1/4;
    grid-row: 4;
  }

  .gateway {
    grid-column: 4/5;
    grid-row: 4;
  }

  .sub-gateways {
    grid-column: 5/8;
    grid-row: 4;
  }

  .seniority-number {
    grid-column: 8/9;
    grid-row: 4;
  }

  .date-of-hire {
    grid-column: 9/12;
    grid-row: 4;
  }

  .mail {
    grid-column: 1/5;
    grid-row: 5;
  }

  .secondary-mail {
    grid-column: 5/9;
    grid-row: 5;
  }

  .mobile-phone {
    grid-column: 9/13;
    grid-row: 5;
  }

  .emergency-contact-name {
    grid-column: 1/9;
    grid-row: 6;
  }

  .emergency-contact-number {
    grid-column: 9/13;
    grid-row: 6;
  }

  .address-street {
    grid-column: 1/7;
    grid-row: 7;
  }

  .address-city {
    grid-column: 7/11;
    grid-row: 7;
  }

  .address-state {
    grid-column: 11/12;
    grid-row: 7;
  }

  .address-zip {
    grid-column: 12/13;
    grid-row: 7;
  }

  .address-country {
    grid-column: 1/13;
    grid-row: 8;
  }
}
</style>
