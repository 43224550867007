import api from "./api";
import store from "../store";

const getAccessToken = async () => {
  try {
    if (!store.state.auth) {
      return;
    }

    const accessToken = store.state.auth.accessToken;

    const base64Url = accessToken.split(".")[1]; // Get payload
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const decodedToken = JSON.parse(atob(base64));

    // If token will expire in less than 5 minutes, refresh it, otherwise return the current token
    if (decodedToken.exp * 1000 < Date.now() + 5 * 60 * 1000) {
      // Token expires in less than 5 minutes

      const res = await api.get(`/auth/refresh-token`, {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      });

      if (res.status === 200) {
        store.commit("updateAuth", {
          accessToken: res.data.accessToken,
          iat: Date.now(),
        });
        return res.data.accessToken;
      }

      store.commit("updateAuth", null);
      return null;
    }
    return accessToken;
  } catch (error) {
    store.commit("updateAuth", null);
    return null;
  }
};

export default { getAccessToken };
