<template>
  <div>
    <div class="backdrop"></div>
    <dialog open>
      <header>
        <div class="text-2xl text-red-500">Assignment Warnings</div>
        <button @click="handleClose('cancel')" class="btn-icon">
          <i class="fa-solid fa-xmark"></i>
        </button>
      </header>

      <div class="px-5">
        <div class="text-2xl text-blue-500 font-bold">
          {{ `${employee.surname}, ${employee.givenName}` }}
        </div>
        <div>
          {{
            `Warnings: ${hardWarnings.length} hard, ${
              warnings.length - hardWarnings.length
            } soft.`
          }}
        </div>
      </div>
      <section>
        <div
          class="warning-tile"
          :style="getWarningStyle(warning.level)"
          v-for="(warning, index) in warnings"
          :key="index"
        >
          <div class="flex justify-between font-2xl font-bold">
            <div>
              {{ warning.type }}
            </div>
            <i
              v-if="warning.level === 'Hard'"
              class="fa-solid fa-octagon-exclamation text-red-500"
            ></i>
            <i v-else class="fa-solid fa-warning text-orange-500"></i>
          </div>

          <div class="text-sm">{{ warning.message }}</div>
        </div>
        <div class="text-sm color-gray my-2">
          {{
            `Training Records updated at: ${formatDateTime(
              this.user.trainingRecords.updatedAt
            )} UTC`
          }}
        </div>
      </section>

      <div v-if="hardWarnings.length">
        <div v-if="canOverrideWarnings" class="flex justify-between px-4 py-2">
          <div class="text-sm text-red-500">
            By clicking acknowledge, you confirm that you have read and
            understood these warnings.
          </div>
          <button
            @click="handleClose('assign')"
            class="btn bg-blue-500 text-white"
          >
            Acknowledge
          </button>
        </div>
        <div v-else class="flex justify-between px-4 py-2">
          <div class="text-sm text-red-500">
            You do not have the required permission to continue.
          </div>
          <button
            @click="handleClose('cancel')"
            class="btn bg-gray-500 text-white"
          >
            Cancel
          </button>
        </div>
      </div>
      <div v-else class="flex justify-between px-4 py-2">
        <div class="text-sm text-red-500">
          By clicking acknowledge, you confirm that you have read and understood
          these warnings.
        </div>

        <button
          @click="handleClose('assign')"
          class="btn bg-blue-500 text-white"
        >
          Acknowledge
        </button>
      </div>
    </dialog>
  </div>
</template>

<script>
import { mixin } from "../mixins/mixin";
export default {
  mixins: [mixin],
  methods: {
    handleClose(action) {
      this.$emit("close", { action });
    },
    getWarningStyle(level) {
      let style = {
        "background-color": "orange",
      };

      if (level === "Hard") {
        style = {
          "background-color": "pink",
        };
      }

      return style;
    },
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    warnings: {
      type: Array,
      required: true,
    },
    employee: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hardWarnings() {
      return this.warnings.filter((item) => {
        return item.level === "Hard";
      });
    },

    canOverrideWarnings() {
      if (this.type === "Flight") {
        return this.canOverrideFlightAssignmentWarnings;
      } else {
        return this.canOverrideGroundAssignmentWarnings;
      }
    },
  },
};
</script>

<style scoped>
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 700;
}

dialog {
  position: fixed;
  top: 20vh;
  left: 10%;
  width: 80%;
  border-radius: 5px;
  border-width: 3px 3px 3px 10px;
  border-style: solid;
  border-color: red;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0;
  margin: 0;
  overflow: hidden;
  z-index: 800;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.3rem 1rem 0 1rem;
}

section {
  padding: 0 1rem;
  min-height: 10rem;
  max-height: 26rem;
  overflow-y: auto;
}

.warning-tile {
  border: 1px solid gray;
  border-radius: 5px;
  padding: 5px 10px;
  color: black;
  margin-bottom: 3px;
}

@media (min-width: 768px) {
  dialog {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}
</style>
