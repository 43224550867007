import * as msal from "@azure/msal-browser";
//Deploy

// //PROD;
// const authority =
//   "https://login.microsoftonline.com/2eec8d43-0dd7-4584-b232-bd179dbd1610";
// const clientId = "45286a0a-86dc-42e7-ac6b-58fc4cffafad";
// const redirectUri = "https://monarch.atlasair.com";

// //UAT
// const authority =
//   "https://login.microsoftonline.com/2eec8d43-0dd7-4584-b232-bd179dbd1610";
// const clientId = "63e64c5e-852b-4933-9a28-15629492299c";
// const redirectUri = "https://monarchuat.atlasair.com";

//DEV
const authority =
  "https://login.microsoftonline.com/d3554ce0-7205-4e69-8e9c-e3fe37c22c54";
const clientId = "9e3edebc-d4f3-4796-a092-16d2802f50fc";
const redirectUri = "https://dev-raiden.com"; //DEV
//const redirectUri = "http://localhost:5173"; //DEV LOCAL

const msalConfig = {
  auth: {
    clientId,
    authority,
    redirectUri,
  },
};

const msalInstance = new msal.PublicClientApplication(msalConfig);

export default msalInstance;
