<template>
  <div>
    <r-snackbar
      v-if="snackbarVisible"
      :backgroundColor="snackbarColor"
      :message="snackbarText"
      :timeout="3000"
      @close="snackbarVisible = false"
    >
    </r-snackbar>

    <nav>
      <ul class="nav-wrapper">
        <li class="icon">
          <RouterLink to="/">
            <span class="tooltip">Home</span>
            <span><i class="fa-solid fa-house text-white ml-3"> </i></span
          ></RouterLink>
        </li>

        <li>
          <a href="#">
            <div class="mx-3 text-base lg:text-2xl text-white">Bidlines</div>
          </a>
        </li>

        <li>
          <a href="#">
            <div v-if="pages.length" class="flex justify-between fnt-sm">
              <button class="btn-icon" @click="handlePageUp">
                <i class="fa-solid fa-chevrons-up text-white"></i>
              </button>
              <div class="text-white">
                {{ `${pageNumber + 1} of ${pages.length}` }}
              </div>
              <button class="btn-icon" @click="handlePageDown">
                <i class="fa-solid fa-chevrons-down text-white"></i>
              </button>
            </div>
          </a>
        </li>

        <li>
          <a href="#">
            <input
              @click="[(search = ''), paginate()]"
              class="mx-3 text-align-center w-24 h-8 rounded-md border-gray-500"
              v-model="search"
              @input="paginate()"
              type="text"
              maxlength="6"
              placeholder="Name"
            />
          </a>
        </li>

        <li @click="handleOpenFilters" class="icon">
          <a href="#">
            <span class="tooltip">Employee Filters</span>
            <span><i class="fa-solid fa-bars-filter text-yellow-500"></i></span>
          </a>
        </li>

        <li @click="zoomIn" class="icon ml-5">
          <a href="#">
            <span class="tooltip">Zoom In</span>
            <span><i class="fa-solid fa-magnifying-glass-plus"></i></span>
          </a>
        </li>
        <li @click="zoomOut" class="icon">
          <a href="#">
            <span class="tooltip">Zoom Out</span>
            <span><i class="fa-solid fa-magnifying-glass-minus"></i></span>
          </a>
        </li>
        <li @click="getData" class="nav-secondar icon">
          <a href="#">
            <span class="tooltip">Refresh</span>
            <span><i class="fa-solid fa-rotate"></i></span>
          </a>
        </li>
      </ul>
    </nav>

    <!-- #region LOADING -->
    <!-- LOADING -->

    <r-spinner v-if="loading"> </r-spinner>
    <!-- #endregion -->

    <!-- #region DIALOG -->

    <!--  FILTERS DIALOG -->
    <r-modal v-if="showFiltersDialog" @close="showFiltersDialog = false">
      <div
        v-if="showFiltersDialog"
        class="p-5 flex flex-col justify-between text-sm px-3"
      >
        <!-- Header -->
        <div class="flex justify-between mb-10">
          <div class="text-2xl font-bold ml-5">Employee Filters</div>
          <button @click="showFiltersDialog = false" class="btn-icon">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <!-- Section -->
        <div class="px-5">
          <!-- Filter by Company Name -->
          <div class="border-b border-gray-400 mb-5">
            <div class="flex h-6 items-center mb-2">
              <input
                v-model="filterByCompany"
                id="filterByCompany"
                aria-describedby="filter-description"
                name="filterByCompany"
                type="checkbox"
                class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
              />

              <div class="ml-3 text-base leading-6">
                <label for="filterByCompany" class="font-medium text-gray-900"
                  >Filter by Company</label
                >
                {{ " " }}
              </div>
            </div>

            <div class="flex my-1 px-5 pb-2" v-if="filterByCompany">
              <Listbox as="div" v-model="selectedCompany" class="mb-20">
                <ListboxLabel
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >Show Employees from this Company</ListboxLabel
                >
                <div class="relative mt-2 min-w-[250px]">
                  <ListboxButton
                    class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                  >
                    <span class="block truncate">{{ selectedCompany }}</span>
                    <span
                      class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                    >
                      <ChevronUpDownIcon
                        class="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </ListboxButton>

                  <transition
                    leave-active-class="transition ease-in duration-100"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                  >
                    <ListboxOptions
                      class="absolute z-10 mt-1 max-h-64 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                    >
                      <ListboxOption
                        as="template"
                        v-for="(option, id) in companyOptions"
                        :key="id"
                        :value="option"
                        v-slot="{ active, selectedCompany }"
                      >
                        <li
                          :class="[
                            active ? 'bg-blue-600 text-white' : 'text-gray-900',
                            'relative cursor-default select-none py-2 pl-3 pr-9',
                          ]"
                        >
                          <span
                            :class="[
                              selectedCompany ? 'font-semibold' : 'font-normal',
                              'block truncate',
                            ]"
                            >{{ option }}</span
                          >

                          <span
                            v-if="selectedCompany"
                            :class="[
                              active ? 'text-white' : 'text-blue-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                            ]"
                          >
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                          </span>
                        </li>
                      </ListboxOption>
                    </ListboxOptions>
                  </transition>
                </div>
              </Listbox>
            </div>
          </div>

          <!-- Filter by Job Title -->
          <div class="border-b border-gray-400 mb-5">
            <div class="flex h-6 items-center mb-2">
              <input
                v-model="filterByJobTitle"
                id="filterByJobTitle"
                aria-describedby="filter-description"
                name="filterJobTitle"
                type="checkbox"
                class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
              />

              <div class="ml-3 text-base leading-6">
                <label for="filterByJobTitle" class="font-medium text-gray-900"
                  >Filter by Job Title</label
                >
                {{ " " }}
              </div>
            </div>

            <div class="max-h-[300px] overflow-x-auto" v-if="filterByJobTitle">
              <div class="relative overflow-x-auto shadow-md">
                <table
                  class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
                >
                  <tbody>
                    <tr
                      v-for="jobTitle in jobTitles"
                      :key="jobTitle.name"
                      class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                    >
                      <td class="w-4 p-4">
                        <div class="flex items-center">
                          <input
                            v-model="selectedJobTitles"
                            :id="jobTitle.name"
                            :value="jobTitle"
                            type="checkbox"
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                        </div>
                      </td>
                      <td
                        scope="row"
                        class="px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {{ jobTitle }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="flex mt-2">
                  <div
                    v-if="selectedJobTitles.length !== jobTitles.length"
                    @click="selectedJobTitles = [...jobTitles]"
                    class="cursor-pointer text-base underline text-blue-500"
                  >
                    Selected All
                  </div>
                  <div
                    v-if="selectedJobTitles.length"
                    @click="selectedJobTitles = []"
                    class="ml-2 cursor-pointer text-base underline text-red-500"
                  >
                    Clear Selected
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Footer -->
        <div class="flex justify-end mt-1">
          <button @click="handleSaveFilters" class="btn bg-blue-500 text-white">
            Apply Filters
          </button>
        </div>
      </div>
    </r-modal>
    <!-- MONTH FILTER DIALOG-->
    <r-modal
      v-if="showMonthFilterDialog"
      @close="showMonthFilterDialog = false"
    >
      <div class="p-5 background-color-yellow-l4 -l2">
        <div class="flex justify-between mx-3 mb-2 text-2xl">
          <div>Select Month</div>
          <button class="btn-icon">
            <i
              class="fa-solid fa-xmark"
              @click="showMonthFilterDialog = false"
            ></i>
          </button>
        </div>

        <div class="flex">
          <input
            class="block w-40 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            v-model="monthPicker"
            type="month"
          />
        </div>
        <div class="flex justify-end my-2">
          <button class="btn bg-blue-500 text-white" @click="saveMonthFilter">
            Apply Filter
          </button>
        </div>
      </div>
    </r-modal>

    <!-- JOB TITLES FILTER DIALOG -->
    <r-modal
      v-if="showJobTitlesFilterDialog"
      @close="showJobTitlesFilterDialog = false"
    >
      <div v-if="showJobTitlesFilterDialog" class="p-5 -l2 text-sm">
        <div class="flex justify-between mb-2">
          <div class="text-2xl font-bold">Job Titles Filter</div>
          <button @click="showJobTitlesFilterDialog = false" class="btn-icon">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>
        <div class="max-h-[300px] lg:max-h-[500px] relative overflow-x-auto">
          <table v-if="jobTitles.length" class="items-table">
            <tr v-for="(jobTitle, index) in jobTitles" :key="index">
              <td
                :class="{
                  'not-selected': !selectedJobTitles.includes(jobTitle),
                }"
              >
                {{ jobTitle }}
              </td>
            </tr>
          </table>
        </div>

        <div class="flex my-3">
          <button
            @click="handleSelectAllJobTitles"
            class="btn-text mx-1 text-green-500"
          >
            Select All
          </button>

          <button
            v-if="selectedJobTitles.length"
            @click="handleClearJobTitlesSelection"
            class="btn-text mx-1 text-red-500"
          >
            Clear Selection
          </button>
        </div>

        <div class="flex justify-end mt-1">
          <select
            class="block w-24 py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
            v-model="selectedAircraftDeck"
          >
            <option
              v-for="option in aircraftDeckOptions"
              :key="option"
              :value="option"
            >
              {{ option }}
            </option>
          </select>

          <button
            :disabled="!selectedJobTitles.length"
            @click="handleSaveJobTitlesFilter"
            class="btn bg-green-500 text-white"
          >
            Save
          </button>
        </div>
      </div>
    </r-modal>

    <!-- BIDLINE DIALOG-->
    <r-modal
      v-if="showBidlineDialog"
      @close="[(showBidlineDialog = false), (confirmDelete = false)]"
    >
      <div v-if="bidDays.length" class="p-3 text-sm">
        <div class="flex justify-between mb-3">
          <div class="text-2xl font-bold">
            {{ bidlineTitle }}
          </div>
          <button
            @click="[(showBidlineDialog = false), (confirmDelete = false)]"
            class="btn-icon"
          >
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <div class="flex justify-center items-center py-2 mb-5">
          <div class="flex items-center">
            <!-- Start Date -->
            <input
              class="block w-32 rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              v-model="selectedStartDate"
              type="date"
            />

            <div class="mx-2">-</div>

            <!-- End Date -->

            <input
              class="block w-32 rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              v-model="selectedEndDate"
              type="date"
            />

            <div class="ml-2">
              <Listbox as="div" v-model="selectedStatus">
                <!-- <ListboxLabel
                class="block text-sm font-medium leading-6 text-gray-900"
                >Set Period status</ListboxLabel
              > -->
                <div class="relative min-w-[150px]">
                  <ListboxButton
                    class="relative w-full cursor-default rounded-md bg-white py-2 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                  >
                    <span class="block truncate">{{ selectedStatus }}</span>
                    <span
                      class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                    >
                      <ChevronUpDownIcon
                        class="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </ListboxButton>

                  <transition
                    leave-active-class="transition ease-in duration-100"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                  >
                    <ListboxOptions
                      class="absolute z-10 mt-1 max-h-64 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                    >
                      <ListboxOption
                        as="template"
                        v-for="(option, id) in statusOptions"
                        :key="id"
                        :value="option"
                        v-slot="{ active, selectedStatus }"
                      >
                        <li
                          :class="[
                            active ? 'bg-blue-600 text-white' : 'text-gray-900',
                            'relative cursor-default select-none py-2 pl-3 pr-9',
                          ]"
                        >
                          <span
                            :class="[
                              selectedStatus ? 'font-semibold' : 'font-normal',
                              'block truncate',
                            ]"
                            >{{ option }}</span
                          >

                          <span
                            v-if="selectedStatus"
                            :class="[
                              active ? 'text-white' : 'text-blue-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                            ]"
                          >
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                          </span>
                        </li>
                      </ListboxOption>
                    </ListboxOptions>
                  </transition>
                </div>
              </Listbox>
            </div>
          </div>

          <div class="ml-5 justify-center">
            <button @click="setStatus" class="btn bg-blue-500 text-white">
              Set Status
            </button>
          </div>
        </div>

        <div class="text-center text-2xl font-bold mb-3">
          {{ `${formatFullMonth(selectedMonth)}` }}
          {{ `${selectedYear}` }}
        </div>

        <div class="flex justify-center">
          <div class="flex flex-wrap w-[430px]">
            <div v-for="bidDay in bidDays" :key="bidDay.day">
              <div
                class="bid-day"
                :style="{ backgroundColor: getStatusColor(bidDay.code) }"
              >
                {{ bidDay.day }}
              </div>
            </div>
          </div>
        </div>

        <div class="flex justify-between mt-3">
          <div v-if="employee.biddays.length && canDeleteBidline">
            <div class="flex justify-between" v-if="confirmDelete">
              <button
                @click="handleDeleteBidline"
                class="btn bg-red-500 text-white ml-2"
              >
                Confirm Delete
              </button>

              <button
                @click="confirmDelete = false"
                class="btn bg-gray-500 text-white ml-2"
              >
                Cancel
              </button>
            </div>
            <div v-else>
              <button
                @click="confirmDelete = true"
                class="btn bg-red-500 text-white ml-2"
              >
                Delete Bidline
              </button>
            </div>
          </div>
          <div v-else></div>

          <div class="flex justify-end">
            <button
              @click="[(showBidlineDialog = false), (confirmDelete = false)]"
              class="btn bg-gray-500 text-white ml-2"
            >
              Cancel
            </button>
            <button
              @click="saveBidline"
              class="ml-1 btn bg-green-500 text-white"
            >
              Save Bidline
            </button>
          </div>
        </div>
      </div>
    </r-modal>
    <!-- #endregion -->

    <!-- BOARD -->
    <div class="board" ref="board">
      <!-- LEFT PANEL -->
      <div v-if="page" class="left-panel">
        <div class="left-panel-header bg-gray-500-l2">
          <div class="text-base flex justify-center items-center w-[90px]">
            Employees
          </div>
        </div>
        <div
          class="left-panel-row bg-gray-500-l4 cursor-pointer"
          @click="handleGetEmployeeBidline(employee)"
          v-for="employee in page.employees"
          :key="employee._id"
          :style="{ height: `${rowHeight}px` }"
        >
          <div class="text-base text-align text-bold text-truncate">
            {{ `${employee.surname}, ${employee.givenName.substring(0, 1)}` }}
          </div>
          <div class="text-[10px] text-truncate">
            {{ `${employee.companyName} - ${employee.jobTitle}` }}
          </div>
          <div class="text-xs text-truncate">
            {{ `${employee.gatewayCode} - ${employee.companyId.number}` }}
          </div>
        </div>
      </div>
      <!-- RIGHT PANEL -->
      <div v-if="page" class="right-panel-container">
        <div class="right-panel">
          <!-- Header -->
          <div
            class="right-panel-header bg-gray-500-l4 cursor-pointer"
            @click="showMonthFilterDialog = true"
            v-for="(item, index) in monthFilter.days"
            :key="index"
            :style="{
              top: 0,
              left: `${(1440 / scale) * index}px`,
              width: `${dayWidth}px`,
            }"
          >
            {{ formatHeaderDate(item) }}
          </div>

          <!-- Day Lines -->
          <div
            class="day-line"
            v-for="(day, index) in monthFilter.days"
            :key="day"
            :style="{
              left: `${(1440 / scale) * index}px`,
              height: `${availableBoardHeight + 50}px`,
            }"
          ></div>

          <!-- Hour Boxes -->
          <div
            class="hour-box text-[10px]"
            :class="{ hidden: marker.value === '00' || scale > 4 }"
            v-for="(marker, index) in monthFilter.hourBoxes"
            :key="marker.key"
            :style="{ left: `${(60 / scale) * index}px` }"
          >
            {{ marker.value }}
          </div>
          <!-- Hour Lines -->
          <div
            class="hour-line"
            :class="{ hidden: marker.value === '00' || scale > 6 }"
            v-for="(marker, index) in monthFilter.hourLines"
            :key="marker.key"
            :style="{
              left: `${(60 / scale) * index}px`,
              height: `${availableBoardHeight + 7}px`,
            }"
          ></div>

          <!-- Now Box -->
          <div
            class="now-box"
            v-if="showCurrentTime"
            :style="{
              left: `${nowLineLeft}px`,
            }"
          >
            {{ formatTime(new Date()) }}
          </div>

          <!-- Now Line -->
          <div
            class="now-line"
            v-if="showCurrentTime"
            :style="{
              left: `${nowLineLeft}px`,
              height: `${availableBoardHeight + 7}px`,
            }"
          ></div>

          <!-- Employees -->
          <div
            @click="handleGetEmployeeBidline(employee)"
            class="right-panel-row"
            v-for="(employee, index) in page.employees"
            :key="employee._id"
            :style="{
              top: `${index * rowHeight + 50}px`,
              width: `${boardWidth}px`,
              height: `${rowHeight}px`,
            }"
          >
            <!-- Bid Days -->
            <div
              v-for="bidday in employee.biddays"
              :key="`${employee._id}-${bidday.year}-${bidday.month}-${bidday.day}`"
              class="bidday-panel"
              :style="getBidDayStyle(bidday)"
            >
              {{ bidday.description }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../services/api";
import authentication from "../services/authentication";
import { mixin } from "../mixins/mixin";
import RModal from "../components/RModal.vue";
import RSnackbar from "../components/RSnackbar.vue";
import RSpinner from "../components/RSpinner.vue";
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid";

export default {
  name: "bidlines",
  mixins: [mixin],
  components: {
    RModal,
    RSnackbar,
    RSpinner,
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    ChevronUpDownIcon,
  },
  data() {
    return {
      loading: false,

      inactivityTimer: null,

      //-----------------------------------Paginate
      availableBoardHeight: 0,
      employees: null,
      employee: null,
      page: null,
      itemsPerPage: 0,
      printGroundCrewmembers: true,
      pages: [],
      rowHeight: 60,
      disableNextPageButton: true,
      disablePreviousPageButton: true,

      //----------------------------------------Board
      boardStyle: "Board",
      scale: 4,

      search: "",
      confirmDelete: false,
      //-------------------------Snackbar
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,

      employee: null,

      //-------------------------------------Filters
      showFiltersDialog: false,
      filterByCompany: false,
      filterByJobTitle: false,

      showMonthFilterDialog: false,
      showBidlineDialog: false,
      monthPicker: new Date().toISOString().substring(0, 7),
      selectedMonth: new Date().getUTCMonth(),
      selectedYear: new Date().getUTCFullYear(),
      selectedBidline: null,

      statusOptions: ["Day Off", "Reserve", "Vacation"],
      selectedStatus: "Day Off",
      selectedStartDate: "",
      selectedEndDate: "",
      showStartCalendar: false,
      showEndCalendar: false,
      bidDays: [],
      bidlineTitle: "",
      selectedCompany: null,
      selectedJobTitles: [],

      showJobTitlesFilterDialog: false,
      selectedAircraftDeck: "Cargo and Pax.",
      aircraftDeckOptions: [
        "Cargo and Pax.",
        "Cargo Aircraft",
        "Passenger Aircraft",
      ],
    };
  },

  created() {
    (async () => {
      this.getData();
    })();
  },

  mounted() {
    this.$nextTick(() => {
      const board = this.$refs.board;

      this.availableBoardHeight = board.clientHeight - 120;

      this.itemsPerPage = Math.floor(
        this.availableBoardHeight / this.rowHeight
      );
    });

    window.addEventListener("resize", this.onResize, { passive: true });

    this.startInactivityTimer();
    document.addEventListener("click", this.resetInactivityTimer);
  },

  unmounted() {
    this.clearInactivityTimer();
    document.removeEventListener("click", this.resetInactivityTimer);
  },

  beforeDestroy() {
    if (typeof window === "undefined") return;
    window.removeEventListener("resize", this.onResize, { passive: true });
  },

  computed: {
    auth() {
      return this.$store.state.auth;
    },

    showCurrentTime() {
      const now = new Date().getTime();

      return (
        now > new Date(this.monthFilter.start).getTime() &&
        now < new Date(this.monthFilter.end).getTime()
      );
    },

    companyFilter() {
      return this.$store.getters.companyFilter;
    },

    jobTitlesFilter() {
      return this.$store.getters.jobTitlesFilter;
    },

    jobTitles() {
      if (this.auth) {
        return [...this.user.securityGroup.jobTitles];
      } else {
        return [];
      }
    },

    pageNumber() {
      return this.$store.getters.bidlinesPageNumber;
    },

    nowLineLeft() {
      const x = this.getLocationLeft(
        new Date().toISOString(),
        this.monthFilter.start,
        this.scale
      );

      if (x > 0 || x < this.boardWidth) {
        return x;
      } else {
        return 0;
      }
    },

    boardWidth() {
      return this.monthFilter.days.length * (1440 / this.scale);
    },

    dayWidth() {
      return 1440 / this.scale;
    },

    userCompanies() {
      return [...this.user.securityGroup.companies];
    },
    user() {
      return this.$store.state.user;
    },

    companyOptions() {
      if (this.auth) {
        return [...this.user.securityGroup.companies];
      } else {
        return [];
      }
    },

    jobTitles() {
      if (this.auth) {
        return [...this.user.securityGroup.jobTitles];
      } else {
        return [];
      }
    },

    monthFilter() {
      return this.$store.getters.monthFilter;
    },
  },
  methods: {
    startInactivityTimer() {
      this.clearInactivityTimer();
      this.inactivityTimer = setTimeout(() => {
        this.handleAutoLogout();
      }, this.timeoutDuration);
    },

    clearInactivityTimer() {
      if (this.inactivityTimer) {
        clearTimeout(this.inactivityTimer);
      }
    },

    resetInactivityTimer() {
      this.startInactivityTimer();
    },

    async handleAutoLogout() {
      try {
        if (!this.auth) {
          this.$store.commit("updateUser", null);
          clearTimeout(this.inactivityTimer);
          this.$router.push({ name: "home" });
          return;
        }

        //---------------------------------------Refresh Token
        this.loading = true;
        const accessToken = await authentication.getAccessToken();
        if (!accessToken) {
          this.loading = false;
          this.$router.push({ name: "home" });
          return;
        }

        const res = await api.get("/auth/logout", {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        });

        this.loading = false;

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }

        this.$store.commit("updateAuth", null);
        this.$store.commit("updateUser", null);
        clearTimeout(this.inactivityTimer);
        this.$router.push({ name: "home" });
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarVisible = true;
        this.snackbarText = `Error during logout: ${error}`;

        setTimeout(() => {
          this.$store.commit("updateAuth", null);
          this.$store.commit("updateUser", null);
          clearTimeout(this.inactivityTimer);
          this.$router.push({ name: "home" });
        }, 3000);
      }
    },

    handleOpenFilters() {
      if (!this.selectedCompany) {
        this.selectedCompany = this.user.companyName.slice();
      }

      if (this.companyFilter.active) {
        this.filterByCompany = true;
        this.selectedCompany = this.companyFilter.name.slice();
      }

      if (this.jobTitlesFilter.active) {
        this.filterByJobTitle = true;
        this.selectedJobTitles = [...this.jobTitlesFilter.jobTitles];
      } else {
        this.filterByJobTitle = false;
        this.selectedJobTitles = [];
      }

      this.showFiltersDialog = true;
    },

    handleSaveFilters() {
      this.showFiltersDialog = false;

      //------------------------------------Airport Filter
      let assignmentsAirportFilter = {
        active: false,
        match: "",
        iataCode: "",
      };

      if (this.filterByAirport) {
        if (this.selectedIataCode?.length === 3) {
          const i = this.airports.findIndex((item) => {
            return item.iata === this.selectedIataCode.toUpperCase();
          });

          if (i >= 0) {
            assignmentsAirportFilter = {
              active: true,
              match: this.selectedIataMatch.slice(),
              iataCode: this.selectedIataCode.slice().toUpperCase(),
            };
          } else {
            this.filterByAirport = false;
            this.selectedIataCode = null;
          }
        } else {
          this.filterByAirport = false;
        }
      }

      this.$store.commit(
        "updateAssignmentsAirportFilter",
        assignmentsAirportFilter
      );

      //-----------------------------Company Filter
      let companyFilter = {
        active: false,
        name: "",
      };

      if (this.filterByCompany && this.selectedCompany) {
        companyFilter = {
          active: true,
          name: this.selectedCompany.slice(),
        };
      }

      this.$store.commit("updateCompanyFilter", companyFilter);

      //------------------------------Job Titles Filter
      let jobTitlesFilter = {
        active: false,
        jobTitles: [],
      };

      if (this.filterByJobTitle && this.selectedJobTitles.length) {
        jobTitlesFilter = {
          active: true,
          jobTitles: [...this.selectedJobTitles],
        };
      }

      this.$store.commit("updateJobTitlesFilter", jobTitlesFilter);

      this.getData();
    },

    onResize() {
      const board = this.$refs.board;

      if (!board) return;

      this.availableBoardHeight = board.clientHeight - 120;

      this.itemsPerPage = Math.floor(
        this.availableBoardHeight / this.rowHeight
      );

      this.paginate();
    },

    paginate() {
      if (!this.employees) {
        return;
      }

      this.pages = [];
      let _pages = [];
      this.page = null;

      let data = [...this.employees];

      if (this.search.length) {
        data = this.employees.filter((employee) => {
          return (
            employee.surname
              .toUpperCase()
              .startsWith(this.search.toUpperCase()) ||
            employee.givenName
              .toUpperCase()
              .startsWith(this.search.toUpperCase())
          );
        });
      }

      if (data.length) {
        let chunk = Math.min(
          Math.floor(this.availableBoardHeight / this.rowHeight),
          data.length
        );

        //Show warning if not enough space to display
        if (chunk < 1) {
          this.snackbarColor = "red";
          this.snackbarText = "Not enough screen space to display data";
          this.snackbarVisible = true;
          return;
        }

        //Split data into pages
        for (let i = 0; i < data.length; i += chunk) {
          let tempArray;
          tempArray = data.slice(i, i + chunk);
          _pages.push({
            index: _pages.length,
            employees: tempArray,
          });
        }

        this.pages = [..._pages];

        const i = this.pages.findIndex((page) => {
          return page.index === this.pageNumber;
        });

        if (i < 0) {
          this.$store.commit("updateBidlinesPageNumber", 0);
        }

        this.page = this.pages[this.pageNumber];
      }

      if (!this.page) {
        this.snackbarColor = "red";
        this.snackbarText = "No employees found using the selected filters";
        this.snackbarVisible = true;
      }
    },

    handlePageUp() {
      if (this.pageNumber > 0) {
        const val = this.pageNumber - 1;
        this.$store.commit("updateBidlinesPageNumber", val);
        this.page = this.pages[this.pageNumber];
      }
    },

    handlePageDown() {
      if (this.pageNumber < this.pages.length - 1) {
        const val = this.pageNumber + 1;
        this.$store.commit("updateBidlinesPageNumber", val);
        this.page = this.pages[this.pageNumber];
      }
    },

    handleSelectAllJobTitles() {
      this.selectedJobTitles = [...this.user.securityGroup.jobTitles];
    },

    handleClearJobTitlesSelection() {
      this.selectedJobTitles = [];
    },

    handleOpenJobTitlesFilter() {
      if (this.jobTitlesFilter.active) {
        this.selectedJobTitles = [...this.jobTitlesFilter.jobTitles];
      } else {
        this.selectedJobTitles = [...this.user.securityGroup.jobTitles];
      }

      this.showJobTitlesFilterDialog = true;
    },

    handleSaveJobTitlesFilter() {
      if (!this.selectedJobTitles.length) {
        this.showNewFlightAssignmentDialog = false;
        this.snackbarColor = "red";
        this.snackbarText = "Please select at least one job title.";
        this.snackbarVisible = true;
        return;
      }

      this.showJobTitlesFilterDialog = false;

      this.getData();
    },

    setStatus() {
      const d1 = new Date(this.selectedStartDate);
      const d2 = new Date(this.selectedEndDate);

      const numberOfDays = (d2.getTime() - d1.getTime()) / 1000 / 60 / 1440 + 1;
      let firstDay = d1.getUTCDate();

      let code;
      let description;

      switch (this.selectedStatus) {
        case "Reserve":
          code = "RES";
          description = "Reserve";
          break;

        case "Day Off":
          (code = "DO"), (description = "Day Off");
          break;

        case "Vacation":
          (code = "VAC"), (description = "Vacation");
          break;

        default:
          break;
      }

      if (numberOfDays < 1) {
        this.snackbarColor = "red";
        this.snackbarText = "Please select at least 1 day";
        return (this.snackbarVisible = true);
      }

      for (let i = 0; i < numberOfDays; i++) {
        this.bidDays[firstDay - 1 + i].code = code;
        this.bidDays[firstDay - 1 + i].description = description;
      }
    },

    async getData() {
      this.employees = null;

      //--------------------------Company Filter

      let companyFilter = {
        active: false,
        name: "",
      };

      if (this.companyFilter.active) {
        companyFilter = {
          active: true,
          name: this.companyFilter.name.slice(),
        };
      }

      //--------------------------Job Title Filter
      let jobTitlesFilter = {
        active: false,
        jobTitles: [],
      };

      if (this.jobTitlesFilter.active) {
        jobTitlesFilter = {
          active: true,
          jobTitles: [...this.jobTitlesFilter.jobTitles],
        };
      }

      //---------------------------------------Refresh Token
      this.loading = true;
      const accessToken = await authentication.getAccessToken();
      if (!accessToken) {
        this.loading = false;
        this.$router.push({ name: "home" });
        return;
      }

      try {
        const res = await api.post(
          "/bidlines",
          {
            year: this.monthFilter.year,
            month: this.monthFilter.month,
            companyFilter,
            jobTitlesFilter,
            aircraftDeck: this.selectedAircraftDeck,
          },
          {
            headers: {
              Authorization: "Bearer " + accessToken,
            },
          }
        );

        this.loading = false;

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }

        this.employees = res.data.employees;

        this.paginate();
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    handleGetEmployeeBidline(employee) {
      this.bidDays = [];

      if (employee.biddays.length) {
        employee.biddays.forEach((bidDay) => {
          this.bidDays.push({ ...bidDay });
        });
      } else {
        let bidDay = {};

        for (let i = 0; i < this.monthFilter.days.length; i++) {
          bidDay = {
            employee_id: employee._id,
            year: this.selectedYear,
            month: this.selectedMonth,
            day: i + 1,
            code: "RES",
            description: "Reserve",
          };

          this.bidDays.push(bidDay);
        }
      }

      this.employee = { ...employee };
      this.selectedStartDate = this.monthFilter.days[0].substring(0, 10);
      this.selectedEndDate = this.monthFilter.days[6].substring(0, 10);
      this.bidlineTitle = `${employee.surname}, ${employee.givenName}`;
      this.showBidlineDialog = true;
    },

    async saveBidline() {
      this.showBidlineDialog = false;

      //---------------------------------------Refresh Token
      this.loading = true;
      const accessToken = await authentication.getAccessToken();
      if (!accessToken) {
        this.loading = false;
        this.$router.push({ name: "home" });
        return;
      }

      try {
        const res = await api.put(
          `/bidlines`,
          { bidDays: this.bidDays },
          {
            headers: { Authorization: "Bearer " + accessToken },
          }
        );
        this.loading = false;

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }

        this.getData();
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    async handleDeleteBidline() {
      this.confirmDelete = false;
      this.showBidlineDialog = false;

      //---------------------------------------Refresh Token
      this.loading = true;
      const accessToken = await authentication.getAccessToken();
      if (!accessToken) {
        this.loading = false;
        this.$router.push({ name: "home" });
        return;
      }

      try {
        const res = await api.delete(`/bidlines/${this.employee._id}`, {
          data: {
            year: this.selectedYear,
            month: this.selectedMonth,
          },
          headers: { Authorization: "Bearer " + accessToken },
        });
        this.loading = false;

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }

        this.getData();
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    getBidDayStyle(bidDay) {
      //Default color "DO" - Day Off
      let backgroundColor = "#E74C3C";

      switch (bidDay.code) {
        case "RES":
          backgroundColor = "#2ECC71";
          break;
        case "VAC":
          backgroundColor = "#A569BD";
          break;
        default:
          break;
      }

      const x =
        (new Date(Date.UTC(bidDay.year, bidDay.month, bidDay.day)).getTime() -
          new Date(this.monthFilter.days[0]).getTime()) /
        1000 /
        60 /
        this.scale;

      const style = {
        top: 0,
        left: `${x}px`,
        height: `${this.rowHeight}px`,
        width: `${this.dayWidth}px`,
        backgroundColor,
      };

      return style;
    },

    getStatusColor(code) {
      switch (code) {
        case "DO":
          return "#E74C3C";

        case "RES":
          return "#2ECC71";

        case "VAC":
          return "#A569BD";

        default:
          return "white";
      }
    },

    getStatusText(bidDay) {
      if (this.scale <= 16) {
        return bidDay.description;
      } else {
        return bidDay.code;
      }
    },

    saveMonthFilter() {
      this.selectedMonth = new Date(this.monthPicker).getUTCMonth();
      this.selectedYear = new Date(this.monthPicker).getUTCFullYear();

      this.$store.commit("updateMonthFilter", {
        year: this.selectedYear,
        month: this.selectedMonth,
        period: "Second",
      });

      this.showMonthFilterDialog = false;

      this.getData();
    },

    zoomIn() {
      if (this.scale > 1) {
        this.scale -= 1;
      }
    },

    zoomOut() {
      if (this.scale < 8) {
        this.scale += 1;
      }
    },

    formatHeaderDate(string) {
      const date = new Date(string);

      let options = {
        timeZone: "UTC",
        year: "numeric",
        month: "short",
        day: "numeric",
        weekday: "long",
      };

      return new Intl.DateTimeFormat("en-US", options).format(date);
    },

    formatMonth(m) {
      switch (m) {
        case 0:
          return "JAN";
        case 1:
          return "FEB";
        case 2:
          return "MAR";
        case 3:
          return "APR";
        case 4:
          return "MAY";
        case 5:
          return "JUN";
        case 6:
          return "JUL";
        case 7:
          return "AUG";
        case 8:
          return "SEP";

        case 9:
          return "OCT";

        case 10:
          return "NOV";

        case 11:
          return "DEC";

        default:
          return "---";
      }
    },

    formatFullMonth(m) {
      switch (m) {
        case 0:
          return "January";
        case 1:
          return "February";
        case 2:
          return "March";
        case 3:
          return "April";
        case 4:
          return "May";
        case 5:
          return "June";
        case 6:
          return "July";
        case 7:
          return "August";
        case 8:
          return "September";

        case 9:
          return "October";

        case 10:
          return "November";

        case 11:
          return "December";

        default:
          return "---";
      }
    },
  },
};
</script>

<style scoped>
.right-panel-row {
  position: absolute;
  top: 50px;
  left: 0;
  height: 0;
  width: 0;
  border-bottom: 1px solid gray;
  background-color: white;
  overflow: hidden;
}

.right-panel-row:nth-child(odd) {
  background-color: rgb(236, 225, 225);
}

.bid-day {
  border: 1px solid #bebebe;
  padding: 3px;
  width: 60px;
  height: 60px;
}

.fa-chevron-left {
  font-size: 1.3rem;
  color: white;
}
</style>
