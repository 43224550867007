<template>
  <div class="p-5 bg-white">
    <r-snackbar
      v-if="snackbarVisible"
      :backgroundColor="snackbarColor"
      :message="snackbarText"
      :timeout="3000"
      @close="snackbarVisible = false"
    >
    </r-snackbar>
    <!-- LOADING -->

    <r-spinner v-if="loading"> </r-spinner>
    <div v-else>
      <div class="flex justify-between">
        <div class="flex">
          <div class="header-title mr-3">{{ groundAssignment.type }}</div>
          <button
            v-if="!isEditMode"
            @click="handleBeginEditing"
            class="btn-icon"
          >
            <i class="fa-solid fa-pen text-green-500"></i>
          </button>
        </div>

        <button @click="handleCancel" class="btn-icon">
          <i class="fa-solid fa-xmark"></i>
        </button>
      </div>

      <div class="flex my-1">
        <div class="font-bold">
          {{
            `${groundAssignment.employee.surname}, ${groundAssignment.employee.givenName}`
          }}
        </div>
        <div class="font-bold text-blue-600 ml-3">
          {{
            `(${groundAssignment.employee.companyName} - ${groundAssignment.employee.companyId.number})`
          }}
        </div>
        <button @click="handleSendNotification" class="btn-icon">
          <i class="fa-solid fa-envelope text-blue-500"></i>
        </button>
      </div>

      <div class="flex mb-2">
        <div
          class="text-lg font-bold lg:text-3xl mr-2"
          :style="confirmationStatusStyle"
        >
          {{ `${formatGroundAssignmentSnapshot(groundAssignment)}` }}
        </div>
        <div v-if="isEditMode && showReservationSwitch" class="flex">
          <div
            class="flex items-center ml-2"
            v-if="
              groundAssignment.type === 'Travel' ||
              groundAssignment.type === 'Layover'
            "
          >
            <Switch
              v-model="groundAssignmentConfirmed"
              class="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
            >
              <span class="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                class="pointer-events-none absolute h-full w-full rounded-md bg-white"
              />
              <span
                aria-hidden="true"
                :class="[
                  groundAssignmentConfirmed ? 'bg-green-600' : 'bg-gray-200',
                  'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out',
                ]"
              />
              <span
                aria-hidden="true"
                :class="[
                  groundAssignmentConfirmed ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out',
                ]"
              />
            </Switch>

            <div class="ml-3">
              {{ groundAssignmentConfirmed ? "Confirmed" : "Not Confirmed" }}
            </div>
          </div>
        </div>
      </div>

      <div class="horizontal-line-gray my-1"></div>

      <div v-if="!isEditMode">
        <span>Period:</span
        ><span class="text-base font-bold ml-2">
          {{
            `${formatDateTime(groundAssignment.startTime)} -  ${formatDateTime(
              groundAssignment.endTime
            )}`
          }}</span
        >
      </div>
      <div v-else>
        <div class="flex">
          <div v-if="showOriginInput">
            <p class="text-sm text-gray-500">From</p>
            <input
              class="block w-24 rounded-md border-0 py-1.5 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              type="text"
              maxlength="3"
              v-model="groundAssignmentOriginIata"
            />
          </div>

          <div v-if="showDestinationInput" class="mx-1">
            <p class="text-sm text-gray-500">To</p>
            <input
              class="block w-24 rounded-md border-0 py-1.5 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              type="text"
              maxlength="3"
              v-model="groundAssignmentDestinationIata"
            />
          </div>
        </div>

        <div class="flex my-2">
          <div>
            <p class="ml-2 text-sm text-gray-500">Start</p>

            <div class="flex">
              <input
                class="block w-32 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                v-model="startDate"
                type="date"
              />
              <div v-if="showStartTime">
                <input
                  class="ml-2 block w-24 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  v-model="startTime"
                  type="time"
                />
              </div>
            </div>
          </div>

          <div class="mx-5"></div>

          <div>
            <p class="ml-2 text-sm text-gray-500">End</p>
            <div class="flex">
              <input
                class="block w-32 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                v-model="endDate"
                type="date"
              />

              <div v-if="showEndTime">
                <input
                  class="ml-2 block w-24 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  v-model="endTime"
                  type="time"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <span>Phone:</span
        ><span class="text-base font-bold ml-2">{{
          groundAssignment.employee.mobilePhone
        }}</span>
      </div>

      <div>
        <span>Email:</span
        ><span class="text-base font-bold ml-2">{{
          groundAssignment.employee.mail
        }}</span>
      </div>

      <div>
        <span>Assigned by:</span
        ><span class="text-base font-bold ml-2">{{
          groundAssignment.assignedBy
        }}</span>
      </div>
      <div v-if="groundAssignment.warnings.length">
        <div
          class="border border-gray-500 rounded-md p-1 my-1"
          v-if="showAssignmentWarnings"
        >
          <div class="flex justify-between p-1 font-bold">
            <div>
              The following warnings were active when the assignment was made:
            </div>
            <i
              @click="showAssignmentWarnings = false"
              class="fa-solid fa-xmark cursor-pointer"
            ></i>
          </div>

          <div
            class="text-sm border-b p-1"
            :class="{ isHardWarning: warning.level === 'Hard' }"
            v-for="(warning, index) in groundAssignment.warnings"
            :key="index"
          >
            {{ warning.message }}
          </div>
        </div>
        <div
          v-else
          @click="showAssignmentWarnings = true"
          class="cursor-pointer"
        >
          <i class="fa-solid fa-triangle-exclamation text-orange-500 mr-2"></i>
          <span class="text-red-500 font-bold">Assigned with Warnings</span>
        </div>
      </div>

      <div class="border-b border-gray-500 mb-1">
        <span>Last updated:</span
        ><span class="text-base font-bold ml-2">{{
          formatDateTime(groundAssignment.updatedAt)
        }}</span>
      </div>

      <div class="text-small text-gray-500">Remarks</div>

      <div v-if="!isEditMode">
        <p
          class="user-select-text text-base text-blue-600 border border-gray-500 rounded-md min-h-20 px-2"
        >
          {{ groundAssignment.remarks }}
        </p>
      </div>
      <div v-else>
        <div class="w-full">
          <div class="mb-2">
            <textarea
              v-model="remarks"
              rows="4"
              name="remarks"
              id="remarks"
              class="block w-full rounded-md border-0 py-1.5 text-base text-blue-700 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:leading-6"
            />
          </div>
        </div>
      </div>

      <!-- Remove employee OR delete assignment -->
      <div class="flex my-2" v-if="!isEditMode">
        <select
          class="block w-80 py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 cursor-pointer"
          v-model="selectedAction"
          :items="actionOptions"
        >
          <option v-for="option in actionOptions" :key="option">
            {{ option }}
          </option>
        </select>

        <button
          class="ml-1 btn bg-red-500 text-white"
          v-if="selectedAction !== 'Select'"
          @click="handleExecuteAction"
        >
          Confirm
        </button>
      </div>
      <div v-else class="flex justify-end">
        <button class="btn bg-gray-500 text-white" @click="isEditMode = false">
          Cancel
        </button>
        <button
          class="ml-1 btn bg-green-500 text-white"
          @click="handleSaveAssignment"
        >
          Save Assignment
        </button>
      </div>

      <div class="flex">
        <span class="text-xs color-gray selectable-text">{{
          groundAssignment._id
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../services/api";
import authentication from "../services/authentication";
import { mixin } from "../mixins/mixin";
import RSnackbar from "../components/RSnackbar.vue";
import RSpinner from "../components/RSpinner.vue";
import { Switch } from "@headlessui/vue";
export default {
  name: "groundAssignmentInformation",
  mixins: [mixin],
  props: ["groundAssignment"],
  components: { RSnackbar, RSpinner, Switch },
  data() {
    return {
      loading: false,
      warnings: null,
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,
      showAssignmentWarnings: false,
      isValid: true,
      showGroundAssignmentStartCalendar: false,
      showGroundAssignmentEndCalendar: false,
      remarks: null,

      //----------------Editing
      isEditMode: false,
      startDate: null,
      startTime: null,
      minimumDuration: 30,

      groundAssignmentOriginIata: "",
      groundAssignmentDestinationIata: "",
      groundAssignmentConfirmed: false,

      actionOptions: [
        "Select",
        "Delete Assignment",
        "Delete Assignment and Notify Employee",
      ],
      selectedAction: "Select",
    };
  },
  computed: {
    airports() {
      return this.$store.state.airports;
    },

    hardWarnings() {
      return this.warnings.filter((warning) => {
        return warning.level === "Hard";
      });
    },

    showStartTime() {
      switch (this.groundAssignment.type) {
        case "Special Assignment":
        case "Layover":
        case "Training - Ground School":
        case "Training - SIM":
        case "Do Not Use":
        case "Sick":
        case "Quarantine":
        case "Quarantine - Day OFF":
        case "Travel":
          return true;

        default:
          // case "Vacation":
          // case "Holiday":
          // case "LOA - Jury Duty":
          // case "LOA - Long Term Disability":
          // case "LOA - Short Term Disability":
          // case "LOA - Workers' Compensation":
          // case "Floating Holiday":
          // case "Leave - Bereavement":
          // case "Leave - FMLA":
          // case "Leave - Medical":
          // case "Leave - Military":
          // case "Leave - Non-FMLA":
          // case "Leave - Parental":
          // case "Leave - Personal":
          // case "Stand By":

          return false;
      }
    },

    showEndTime() {
      switch (this.groundAssignment.type) {
        case "Special Assignment":
        case "Layover":
        case "Training - Ground School":
        case "Training - SIM":
        case "Do Not Use":
        case "Sick":
        case "Quarantine":
        case "Quarantine - Day OFF":
        case "Travel":
          return true;

        default:
          // case "Vacation":
          // case "Holiday":
          // case "LOA - Jury Duty":
          // case "LOA - Long Term Disability":
          // case "LOA - Short Term Disability":
          // case "LOA - Workers' Compensation":
          // case "Floating Holiday":
          // case "Leave - Bereavement":
          // case "Leave - FMLA":
          // case "Leave - Medical":
          // case "Leave - Military":
          // case "Leave - Non-FMLA":
          // case "Leave - Parental":
          // case "Leave - Personal":
          // case "Stand By":

          return false;
      }
    },

    showOriginInput() {
      switch (this.groundAssignment.type) {
        case "Special Assignment":
        case "Layover":
        case "Training - Ground School":
        case "Training - SIM":
        case "Do Not Use":
        case "Sick":
        case "Quarantine":
        case "Quarantine - Day OFF":
        case "Travel":
          return true;

        default:
          // case "Vacation":
          // case "Holiday":
          // case "LOA - Jury Duty":
          // case "LOA - Long Term Disability":
          // case "LOA - Short Term Disability":
          // case "LOA - Workers' Compensation":
          // case "Floating Holiday":
          // case "Leave - Bereavement":
          // case "Leave - FMLA":
          // case "Leave - Medical":
          // case "Leave - Military":
          // case "Leave - Non-FMLA":
          // case "Leave - Parental":
          // case "Leave - Personal":
          // case "Stand By":

          return false;
      }
    },

    showDestinationInput() {
      return this.groundAssignment.type === "Travel";
    },

    confirmationStatusStyle() {
      switch (this.groundAssignment.type) {
        case "Travel":
        case "Layover":
          if (this.isEditMode) {
            if (this.groundAssignmentConfirmed) {
              return {
                color: "#66BB6A",
              };
            } else {
              return {
                color: "red",
              };
            }
          } else {
            if (this.groundAssignment.confirmed) {
              return {
                color: "#66BB6A",
              };
            } else {
              return {
                color: "red",
              };
            }
          }

        default:
          return {
            color: "#000",
          };
      }
    },

    showReservationSwitch() {
      return (
        this.groundAssignment.type === "Travel" ||
        this.groundAssignment.type === "Layover"
      );
    },

    assignmentWarnings() {
      return this.$store.getters.assignmentWarnings;
    },

    formatReservationConfirmed() {
      if (this.isEditMode) {
        return this.groundAssignmentConfirmed ? "Confirmed" : "Not Confirmed";
      } else {
        return this.groundAssignment.confirmed ? "Confirmed" : "Not Confirmed";
      }
    },
  },
  created() {
    this.warnings = null;
  },
  methods: {
    async handleExecuteAction() {
      //---------------------------------------Refresh Token
      this.loading = true;
      const accessToken = await authentication.getAccessToken();
      if (!accessToken) {
        this.loading = false;
        this.$router.push({ name: "home" });
        return;
      }

      try {
        const res = await api.delete(
          `/assignments/${this.groundAssignment._id}`,
          {
            headers: { Authorization: "Bearer " + accessToken },
          }
        );

        if (res.status !== 200) {
          this.loading = false;
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }

        if (this.selectedAction === "Delete Assignment and Notify Employee") {
          const res = await api.put(
            "/notifications/single",
            {
              assignment: { type: "Ground", ...this.groundAssignment },
              emailSubject: "Assignment cancelled",
            },
            {
              headers: {
                Authorization: "Bearer " + accessToken,
              },
            }
          );

          this.loading = false;

          if (res.status !== 200) {
            this.snackbarColor = "red";
            this.snackbarText = res.message;
            this.snackbarVisible = true;
            return;
          }

          this.snackbarColor = "#66BB6A";
          this.snackbarText = "Notification sent.";
          this.snackbarVisible = true;
          this.selectedAction = "Select";

          setTimeout(() => {
            this.$emit("close", true);
          }, 3000);
        } else {
          this.selectedAction = "Select";
          this.$emit("close", true);
        }
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = error.message;
        this.snackbarVisible = true;
        return;
      }
    },

    handleBeginEditing() {
      this.startDate = new Date(this.groundAssignment.startTime)
        .toISOString()
        .substring(0, 10);
      this.startTime = new Date(this.groundAssignment.startTime)
        .toISOString()
        .substring(11, 16);

      this.endDate = new Date(this.groundAssignment.endTime)
        .toISOString()
        .substring(0, 10);
      this.endTime = new Date(this.groundAssignment.endTime)
        .toISOString()
        .substring(11, 16);

      this.remarks = this.groundAssignment.remarks
        ? this.groundAssignment.remarks.slice()
        : null;

      this.groundAssignmentOriginIata =
        this.groundAssignment.originIata.slice();
      this.groundAssignmentDestinationIata =
        this.groundAssignment.destinationIata.slice();
      this.groundAssignmentConfirmed = this.groundAssignment.confirmed
        ? true
        : false;

      this.isEditMode = true;
    },

    handleSaveAssignment() {
      this.isEditMode = false;

      let d1;
      let d2;

      let startDateTime;
      let endDateTime;
      let duration = 0;

      let groundAssignmentOrigin = null;
      let groundAssignmentDestination = null;

      switch (this.groundAssignment.type) {
        case "Special Assignment":
        case "Layover":
        case "Training - Ground School":
        case "Training - SIM":
        case "Do Not Use":
          //Check Origin IATA
          groundAssignmentOrigin = this.airports.find((airport) => {
            return (
              airport.iata === this.groundAssignmentOriginIata.toUpperCase()
            );
          });

          if (!groundAssignmentOrigin) {
            this.snackbarColor = "red";
            this.snackbarText = `We couln't find an airport with IATA code ${this.groundAssignmentOriginIata.toUpperCase()}`;
            this.snackbarVisible = true;
            this.loading = false;
            this.readOnly = true;
            return;
          }

          //Make Destination equal to origin
          groundAssignmentDestination = { ...groundAssignmentOrigin };

          d1 = `${this.startDate}T${this.startTime}:00.000+00:00`;
          d2 = `${this.endDate}T${this.endTime}:59.999+00:00`;

          break;
        case "Stand By":
          //Check Origin IATA
          groundAssignmentOrigin = this.airports.find((airport) => {
            return (
              airport.iata === this.groundAssignmentOriginIata.toUpperCase()
            );
          });

          if (!groundAssignmentOrigin) {
            this.snackbarColor = "red";
            this.snackbarText = `We couln't find an airport with IATA code ${this.groundAssignmentOriginIata.toUpperCase()}`;
            this.snackbarVisible = true;
            this.loading = false;
            this.readOnly = true;
            return;
          }

          //Make Destination equal to origin
          groundAssignmentDestination = { ...groundAssignmentOrigin };

          d1 = `${this.startDate}T00:00:00.000+00:00`;
          d2 = `${this.endDate}T23:59:59.999+00:00`;

          break;
        case "Quarantine":
        case "Quarantine - Day OFF":
          //Check Origin IATA
          groundAssignmentOrigin = this.airports.find((airport) => {
            return (
              airport.iata === this.groundAssignmentOriginIata.toUpperCase()
            );
          });

          if (!groundAssignmentOrigin) {
            this.snackbarColor = "red";
            this.snackbarText = `We couln't find an airport with IATA code ${this.groundAssignmentOriginIata.toUpperCase()}`;
            this.snackbarVisible = true;
            this.loading = false;
            this.readOnly = true;
            return;
          }

          //Make Destination equal to origin
          groundAssignmentDestination = { ...groundAssignmentOrigin };

          d1 = `${this.startDate}T00:00:00.000+00:00`;
          d2 = `${this.endDate}T23:59:59.999+00:00`;
          break;
        case "Travel":
          //Check Origin IATA
          groundAssignmentOrigin = this.airports.find((airport) => {
            return (
              airport.iata === this.groundAssignmentOriginIata.toUpperCase()
            );
          });

          if (!groundAssignmentOrigin) {
            this.snackbarColor = "red";
            this.snackbarText = `We couln't find an airport with IATA code ${this.groundAssignmentOriginIata.toUpperCase()}`;
            this.snackbarVisible = true;
            this.loading = false;
            this.readOnly = true;
            return;
          }

          //Check Destination IATA
          groundAssignmentDestination = this.airports.find((airport) => {
            return (
              airport.iata ===
              this.groundAssignmentDestinationIata.toUpperCase()
            );
          });

          if (!groundAssignmentDestination) {
            this.snackbarColor = "red";
            this.snackbarText = `We couln't find an airport with IATA code ${this.groundAssignmentDestinationIata.toUpperCase()}`;
            this.snackbarVisible = true;
            this.loading = false;
            this.readOnly = true;
            return;
          }

          d1 = `${this.startDate}T${this.startTime}:00.000+00:00`;
          d2 = `${this.endDate}T${this.endTime}:59.999+00:00`;
          break;
        case "Sick":
          //Check Origin IATA
          groundAssignmentOrigin = this.airports.find((airport) => {
            return (
              airport.iata === this.groundAssignmentOriginIata.toUpperCase()
            );
          });

          if (!groundAssignmentOrigin) {
            this.snackbarColor = "red";
            this.snackbarText = `We couln't find an airport with IATA code ${this.groundAssignmentOriginIata.toUpperCase()}`;
            this.snackbarVisible = true;
            this.loading = false;
            this.readOnly = true;
            return;
          }

          //Make Destination equal to origin
          groundAssignmentDestination = { ...groundAssignmentOrigin };

          d1 = `${this.startDate}T${this.startTime}:00.000+00:00`;
          d2 = `${this.endDate}T${this.endTime}:59.999+00:00`;
          break;
        default:
          // case "Vacation":
          // case "Holiday":
          // case "LOA - Jury Duty":
          // case "LOA - Long Term Disability":
          // case "LOA - Short Term Disability":
          // case "LOA - Workers' Compensation":
          // case "Floating Holiday":
          // case "Leave - Bereavement":
          // case "Leave - FMLA":
          // case "Leave - Medical":
          // case "Leave - Military":
          // case "Leave - Non-FMLA":
          // case "Leave - Parental":
          // case "Leave - Personal":

          //Check Origin IATA
          groundAssignmentOrigin = this.airports.find((airport) => {
            return (
              airport.iata === this.groundAssignmentOriginIata.toUpperCase()
            );
          });

          if (!groundAssignmentOrigin) {
            this.snackbarColor = "red";
            this.snackbarText = `We couln't find an airport with IATA code ${this.groundAssignmentOriginIata.toUpperCase()}`;
            this.snackbarVisible = true;
            this.loading = false;
            this.readOnly = true;
            return;
          }

          //Make Destination equal to origin
          groundAssignmentDestination = { ...groundAssignmentOrigin };

          d1 = `${this.startDate}T00:00:00.000+00:00`;
          d2 = `${this.endDate}T23:59:59.999+00:00`;

          break;
      }

      startDateTime = new Date(d1);
      endDateTime = new Date(d2);

      duration = Math.ceil(
        (endDateTime.getTime() - startDateTime.getTime()) / 60 / 1000
      );

      if (duration <= this.groundAssignmentMinimumDuration) {
        this.snackbarColor = "red";
        this.snackbarText = `The minimum duration for a ground assignment is ${this.groundAssignmentMinimumDuration} minutes`;
        this.snackbarVisible = true;
        this.loading = false;
        this.readOnly = true;
        return;
      }

      if (this.groundAssignment.type === "Travel") {
        if (duration > 1 * 24 * 60) {
          this.snackbarColor = "red";
          this.snackbarText = `The maximum duration for a travel assignment is 1 day`;
          this.snackbarVisible = true;
          this.loading = false;
          this.readOnly = true;
          return;
        }
      } else {
        if (duration > 31 * 24 * 60) {
          this.snackbarColor = "red";
          this.snackbarText = `The maximum duration for a ground assignment is 31 days`;
          this.snackbarVisible = true;
          this.loading = false;
          this.readOnly = true;
          return;
        }
      }

      const assignment = {
        ...this.groundAssignment,
        originIata: groundAssignmentOrigin.iata,
        destinationIata: groundAssignmentDestination.iata,
        startTime: startDateTime,
        endTime: endDateTime,
        duration: duration,
        originCountry: groundAssignmentOrigin.country,
        destinationCountry: groundAssignmentDestination.country,
        confirmed: this.groundAssignmentConfirmed,
        employee_id: this.groundAssignment.employee._id,
        remarks: this.remarks,
      };

      this.$emit("update", { assignment });
    },

    handleCancel() {
      this.$emit("close", false);
    },

    async handleSendNotification() {
      //---------------------------------------Refresh Token
      this.loading = true;
      const accessToken = await authentication.getAccessToken();
      if (!accessToken) {
        this.loading = false;
        this.$router.push({ name: "home" });
        return;
      }

      try {
        const res = await api.put(
          "/notifications/single",
          {
            assignment: this.groundAssignment,
            emailSubject: "Assignment",
          },
          {
            headers: {
              Authorization: "Bearer " + accessToken,
            },
          }
        );

        this.loading = false;

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }

        this.snackbarColor = "#66BB6A";
        this.snackbarText = "Notification sent.";
        this.snackbarVisible = true;
        this.selectedAction = "Select";

        setTimeout(() => {
          this.snackbarVisible = false;
        }, 3000);
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = error.message;
        this.snackbarVisible = true;
        return;
      }
    },
  },
};
</script>

<style scoped>
.header-title {
  font-family: "Abel", Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
  color: blue;
}

.disclaimer-text {
  border: 1px solid red;
  border-radius: 3px;
  padding: 0 5px;
  margin: 5px 0;
  font-size: 1.5rem;
  text-align: center;
  color: red;
}

.fa-trash-can:hover {
  color: red;
}

.isHardWarning {
  background-color: white;
  color: red;
}
</style>
