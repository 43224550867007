<template>
  <div>
    <r-snackbar
      v-if="snackbarVisible"
      :backgroundColor="snackbarColor"
      :message="snackbarText"
      :timeout="3000"
      @close="snackbarVisible = false"
    >
    </r-snackbar>

    <!-- LOADING -->

    <r-spinner v-if="loading"> </r-spinner>
    <div v-else>
      <div class="p-5 bg-white text-base">
        <div class="flex justify-between">
          <div>
            <div class="text-2xl font-bold text-blue-500 mr-2">
              FLIGHT ASSIGNMENT
            </div>
          </div>

          <button @click="close" class="btn-icon ml-3">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <div class="horizontal-line-gray mb-2"></div>

        <div>
          {{ formatFullFlightSnapshot(this.flightAssignment) }}
        </div>

        <div class="flex my-1" v-if="flightAssignment.employee">
          <div class="font-bold">
            {{
              `${flightAssignment.employee.surname}, ${flightAssignment.employee.givenName}`
            }}
          </div>
          <div class="font-bold text-blue-600 ml-3">
            {{
              `(${flightAssignment.employee.companyName} - ${flightAssignment.employee.companyId.number})`
            }}
          </div>
          <button @click="handleSendNotification" class="btn-icon ml-5">
            <i class="fa-solid fa-envelope text-blue-500"></i>
          </button>
        </div>
        <div v-else>
          <div class="flex">
            <div class="font-bold">
              <span>Assigned to:</span>
              <span class="ml-1 text-red-500">{{
                ` ${flightAssignment.assignedToCompany}`
              }}</span>
            </div>
          </div>

          <div v-if="flightAssignment.unableToCover" class="font-bold">
            <span>Unable to cover by:</span>
            <span class="ml-1 text-red-500">{{
              ` ${flightAssignment.unableToCover.companyName}`
            }}</span>
          </div>
        </div>
        <div v-if="flightAssignment.employee">
          <span>Phone: </span>
          <span class="text-sm-bold ml-1">
            {{ flightAssignment.employee.mobilePhone }}
          </span>
        </div>

        <div>
          <span>Flight Role:</span
          ><span class="text-base font-bold ml-2">{{
            flightAssignment.role
          }}</span>
        </div>

        <div>
          <span>Assigned by:</span
          ><span class="ml-1 font-bold">{{ flightAssignment.assignedBy }}</span>
        </div>
        <div v-if="flightAssignment.warnings.length">
          <div
            class="border border-gray-500 rounded-md p-1 my-1"
            v-if="showAssignmentWarnings"
          >
            <div class="flex justify-between p-1 font-bold">
              <div>
                The following warnings were active when the assignment was made:
              </div>
              <i
                @click="showAssignmentWarnings = false"
                class="fa-solid fa-xmark cursor-pointer"
              ></i>
            </div>

            <div
              class="text-sm border-b p-1"
              :class="{ isHardWarning: warning.level === 'Hard' }"
              v-for="(warning, index) in flightAssignment.warnings"
              :key="index"
            >
              {{ warning.message }}
            </div>
          </div>
          <div
            v-else
            class="cursor-pointer"
            @click="showAssignmentWarnings = true"
          >
            <i
              class="fa-solid fa-triangle-exclamation mr-2 text-orange-500"
            ></i>
            <span class="text-red-500 font-bold">Assigned with Warnings</span>
          </div>
        </div>
        <div class="border-b border-gray-500 mb-1">
          <span>Last Updated:</span
          ><span class="ml-1 font-bold">{{
            formatDateTime(flightAssignment.updatedAt)
          }}</span>
        </div>

        <div class="text-small text-gray-500">Remarks</div>

        <div>
          <div v-if="!isEditMode" @click="handleBeginEditing">
            <p
              class="user-select-text text-base text-blue-600 border border-gray-500 rounded-md min-h-20 px-2"
            >
              {{ flightAssignment.remarks }}
            </p>
          </div>
          <div v-else>
            <div>
              <div class="my-2">
                <textarea
                  v-model="remarks"
                  rows="4"
                  name="remarks"
                  id="remarks"
                  class="block w-full rounded-md border-0 py-1.5 text-base text-blue-700 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:leading-6"
                />
              </div>
            </div>

            <div class="flex justify-end">
              <button
                @click="isEditMode = false"
                class="btn bg-gray-500 text-white"
              >
                Cancel
              </button>

              <button
                @click="handleSaveRemarks"
                class="ml-1 btn bg-green-500 text-white"
              >
                Save Remarks
              </button>
            </div>
          </div>

          <!-- Remove employee OR delete assignment -->
          <div class="flex my-2" v-if="!isEditMode">
            <select
              class="block w-80 py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 cursor-pointer"
              v-model="selectedAction"
              :items="actionOptions"
            >
              <option v-for="option in actionOptions" :key="option">
                {{ option }}
              </option>
            </select>

            <button
              class="ml-1 btn bg-red-500 text-white"
              v-if="selectedAction !== 'Select'"
              @click="handleExecuteAction"
            >
              Confirm
            </button>
          </div>

          <div class="flex">
            <span class="text-xs color-gray selectable-text">{{
              flightAssignment._id
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../services/api";
import authentication from "../services/authentication";
import { mixin } from "../mixins/mixin";
import RSnackbar from "../components/RSnackbar.vue";
import RSpinner from "../components/RSpinner.vue";
export default {
  name: "flightAssignmentInformation",
  mixins: [mixin],
  props: ["flightAssignment"],
  components: { RSnackbar, RSpinner },

  data() {
    return {
      loading: false,
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,
      dialog: true,
      showAssignmentWarnings: false,
      selectedAction: "Select",
      isEditMode: false,
      remarks: "",
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },

    aircraft() {
      return this.$store.getters.aircraft;
    },

    actionOptions() {
      let options = ["Select", "Delete Assignment"];

      if (this.flightAssignment.employee) {
        options.push("Cancel Assignment");
        options.push("Cancel Assignment and Notify Employee");
        options.push("Delete Assignment and Notify Employee");
      }

      return options;
    },
  },

  methods: {
    handleBeginEditing() {
      if (!this.canUpdateFlightRemarks) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText =
          "Not authorized. Please contact your administrator.";
        this.snackbarVisible = true;
        return;
      }

      this.remarks = this.flightAssignment.remarks
        ? this.flightAssignment.remarks.slice()
        : null;

      this.isEditMode = true;
    },

    async handleSaveRemarks() {
      if (!this.canUpdateFlightRemarks) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText =
          "Not authorized. Please contact your administrator.";
        this.snackbarVisible = true;
        return;
      }

      this.isEditMode = false;

      //---------------------------------------Refresh Token
      this.loading = true;
      const accessToken = await authentication.getAccessToken();
      if (!accessToken) {
        this.loading = false;
        this.$router.push({ name: "home" });
        return;
      }

      try {
        const { flight_id, _id } = this.flightAssignment;

        const res = await api.put(
          `/flights/${flight_id}/crewmembers/update-remarks`,
          {
            flight_id,
            assignment_id: _id,
            remarks: this.remarks,
          },
          {
            headers: { Authorization: "Bearer " + accessToken },
          }
        );

        this.loading = false;
        this.isEditMode = false;

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;

          setTimeout(() => {
            this.$store.commit("updateAuth", null);
            this.$router.push({ name: "home" });
          }, 3000);

          return;
        }

        this.$store.commit("updateFlight", res.data.flight);

        this.$emit("close", { action: "refresh" });
      } catch (error) {
        this.loading = false;
        this.isEditMode = false;
        this.snackbarColor = "red";
        this.snackbarText = error.message;
        this.snackbarVisible = true;

        setTimeout(() => {
          this.$store.commit("updateAuth", null);
          this.$router.push({ name: "home" });
        }, 3000);
      }
    },

    async handleSendNotification() {
      //---------------------------------------Refresh Token
      this.loading = true;
      const accessToken = await authentication.getAccessToken();
      if (!accessToken) {
        this.loading = false;
        this.$router.push({ name: "home" });
        return;
      }

      try {
        const res = await api.put(
          "/notifications/single",
          {
            assignment: { type: "Flight", ...this.flightAssignment },
            emailSubject: `${this.flightAssignment.role} - Flight Assignment`,
          },
          {
            headers: {
              Authorization: "Bearer " + accessToken,
            },
          }
        );

        this.loading = false;

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }

        this.snackbarColor = "#66BB6A";
        this.snackbarText = "Notification sent.";
        this.snackbarVisible = true;
        this.selectedAction = "Select";

        setTimeout(() => {
          this.snackbarVisible = false;
        }, 3000);
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = error.message;
        this.snackbarVisible = true;

        setTimeout(() => {
          this.$store.commit("updateAuth", null);
          this.$router.push({ name: "home" });
        }, 3000);
      }
    },

    async handleExecuteAction() {
      //---------------------------------------Refresh Token
      this.loading = true;
      const accessToken = await authentication.getAccessToken();
      if (!accessToken) {
        this.loading = false;
        this.$router.push({ name: "home" });
        return;
      }

      let res;
      let notify = false;
      let flight = null;

      const { flight_id, _id } = this.flightAssignment;

      switch (this.selectedAction) {
        case "Cancel Assignment":
          res = await api.put(
            `/flights/${flight_id}/crewmembers/remove-employee`,
            {
              assignment_id: _id,
            },
            {
              headers: { Authorization: "Bearer " + accessToken },
            }
          );
          break;

        case "Cancel Assignment and Notify Employee":
          res = await api.put(
            `/flights/${flight_id}/crewmembers/remove-employee`,
            {
              assignment_id: _id,
            },
            {
              headers: { Authorization: "Bearer " + accessToken },
            }
          );

          notify = true;
          break;

        case "Delete Assignment":
          res = await api.put(
            `/flights/${flight_id}/crewmembers/remove`,
            {
              flight_id,
              assignment_id: _id,
            },
            {
              headers: { Authorization: "Bearer " + accessToken },
            }
          );

          break;

        case "Delete Assignment and Notify Employee":
          res = await api.put(
            `/flights/${flight_id}/crewmembers/remove`,
            {
              flight_id,
              assignment_id: _id,
            },
            {
              headers: { Authorization: "Bearer " + accessToken },
            }
          );
          notify = true;
          break;
        default:
          this.loading = false;
          return;
      }

      this.loading = false;

      if (res.status !== 200) {
        this.snackbarColor = "red";
        this.snackbarText = res.message;
        this.snackbarVisible = true;

        setTimeout(() => {
          this.$store.commit("updateAuth", null);
          this.$router.push({ name: "home" });
        }, 3000);

        return;
      }

      flight = res.data.flight;

      if (this.flightAssignment.employee && notify) {
        res = await api.put(
          `/notifications/single`,
          {
            assignment: { type: "Flight", ...this.flightAssignment },
            emailSubject: "Flight Assignment Cancelled",
          },
          {
            headers: { Authorization: "Bearer " + accessToken },
          }
        );

        this.loading = false;

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;

          setTimeout(() => {
            this.$store.commit("updateAuth", null);
            this.$router.push({ name: "home" });
          }, 3000);

          return;
        }
      }

      this.selectedAction = "Select";

      this.$store.commit("updateFlight", flight);

      this.$emit("close", { action: "refresh" });
    },

    close() {
      this.$emit("close", { action: "none" });
    },

    formatFullFlightSnapshot(assignment) {
      const timeOut = this.formatDateTime(assignment.startTime);
      const timeIn = this.formatTime(assignment.endTime);

      const snapshot = `${assignment.flightNumber} . ${assignment.aircraftRegistration} . ${assignment.originIata} - ${assignment.destinationIata} . ${timeOut} Z - ${timeIn} Z`;

      return snapshot;
    },
  },
};
</script>

<style scoped>
.remarks {
  background-color: white;
  border: 1px solid #bebebe;
  border-radius: 5px;
  padding: 0 5px;
  cursor: pointer;
}

p {
  width: 100%;
  min-height: 100px;
}

.isHardWarning {
  background-color: white;
  color: red;
}
</style>
