<template>
  <div>
    <r-snackbar
      v-if="snackbarVisible"
      :backgroundColor="snackbarColor"
      :message="snackbarText"
      :timeout="3000"
      @close="snackbarVisible = false"
    >
    </r-snackbar>

    <!-- LOADING -->

    <r-spinner v-if="loading"> </r-spinner>

    <nav>
      <ul class="nav-wrapper">
        <li class="icon">
          <RouterLink to="/">
            <span class="tooltip">Home</span>
            <span><i class="fa-solid fa-house text-white ml-3"> </i></span
          ></RouterLink>
        </li>

        <li>
          <a href="#">
            <div class="ml-3 text-2xl text-white">Manuals</div>
          </a>
        </li>

        <li>
          <a href="#"></a>
        </li>
      </ul>
    </nav>

    <div class="p-5">
      <!-- Basic -->

      <div
        class="border border-gray-500 rounded-md p-5 mb-2 cursor-pointer hover:bg-gray-100"
        :class="{ disabled: loading }"
        @click="getBasicManual"
      >
        <div>
          <i class="fa-solid fa-book text-green-500"></i>
          <span class="text-xl ml-3"> Basic User Manual </span>
        </div>
      </div>

      <!-- Advanced -->
      <div
        class="border border-gray-500 rounded-md p-5 mb-2 cursor-pointer hover:bg-gray-100"
        :class="{ disabled: loading }"
        @click="getAdminManual"
      >
        <div>
          <i class="fa-solid fa-book text-red-500"></i>
          <span class="text-xl ml-3"> Administrator Manual </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../services/api";
import authentication from "../services/authentication";
import { PDFDocument } from "pdf-lib";
import { mixin } from "../mixins/mixin";
import RSnackbar from "../components/RSnackbar.vue";
import RSpinner from "../components/RSpinner.vue";

export default {
  name: "about",
  mixins: [mixin],
  components: { RSnackbar, RSpinner },
  data() {
    return {
      loading: false,

      inactivityTimer: null,

      //-------------------------Snackbar
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,
    };
  },

  mounted() {
    this.startInactivityTimer();
    document.addEventListener("click", this.resetInactivityTimer);
  },
  unmounted() {
    this.clearInactivityTimer();
    document.removeEventListener("click", this.resetInactivityTimer);
  },

  beforeDestroy() {
    clearTimeout(this.inactivityTimer);
  },

  methods: {
    startInactivityTimer() {
      this.clearInactivityTimer();
      this.inactivityTimer = setTimeout(() => {
        this.handleAutoLogout();
      }, this.timeoutDuration);
    },

    clearInactivityTimer() {
      if (this.inactivityTimer) {
        clearTimeout(this.inactivityTimer);
      }
    },

    resetInactivityTimer() {
      this.startInactivityTimer();
    },

    async handleAutoLogout() {
      try {
        if (!this.auth) {
          this.$store.commit("updateUser", null);
          clearTimeout(this.inactivityTimer);
          this.$router.push({ name: "home" });
          return;
        }

        //---------------------------------------Refresh Token
        this.loading = true;
        const accessToken = await authentication.getAccessToken();
        if (!accessToken) {
          this.loading = false;
          this.$router.push({ name: "home" });
          return;
        }

        const res = await api.get("/auth/logout", {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        });

        this.loading = false;

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }

        this.$store.commit("updateAuth", null);
        this.$store.commit("updateUser", null);
        clearTimeout(this.inactivityTimer);
        this.$router.push({ name: "home" });
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarVisible = true;
        this.snackbarText = `Error during logout: ${error}`;

        setTimeout(() => {
          this.$store.commit("updateAuth", null);
          this.$store.commit("updateUser", null);
          clearTimeout(this.inactivityTimer);
          this.$router.push({ name: "home" });
        }, 3000);
      }
    },

    async getBasicManual() {
      this.loading = true;
      try {
        const url =
          "https://s3.amazonaws.com/monarch.atlasair.com.resources/manuals/user_manual.pdf";

        const existingPdfBytes = await fetch(url).then((res) =>
          res.arrayBuffer()
        );

        const pdfDoc = await PDFDocument.load(existingPdfBytes);

        pdfDoc.setTitle("Monarch User Manual");

        const pdfUrl = URL.createObjectURL(
          new Blob([await pdfDoc.save()], { type: "application/pdf" })
        );

        this.loading = false;
        window.open(pdfUrl, "_blank");
      } catch (error) {
        this.loading = false;

        this.snackbarColor = "red";
        this.snackbarText = error.message;
        this.snackbarVisible = true;
        return;
      }
    },

    async getAdminManual() {
      this.loading = true;
      try {
        const url =
          "https://s3.amazonaws.com/monarch.atlasair.com.resources/manuals/admin_manual.pdf";

        const existingPdfBytes = await fetch(url).then((res) =>
          res.arrayBuffer()
        );

        const pdfDoc = await PDFDocument.load(existingPdfBytes);

        pdfDoc.setTitle("Monarch Admin Manual");

        const pdfUrl = URL.createObjectURL(
          new Blob([await pdfDoc.save()], { type: "application/pdf" })
        );

        this.loading = false;

        window.open(pdfUrl, "_blank");
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = error.message;
        this.snackbarVisible = true;
        return;
      }
    },
  },
};
</script>
