<template>
  <div>
    <nav>
      <ul class="nav-wrapper">
        <li class="icon" @click="handleGoBack">
          <a href="#">
            <span class="tooltip">Go Back</span>
            <span><i class="fa-solid fa-arrow-left text-white"> </i></span>
          </a>
        </li>
        <li class="icon">
          <RouterLink to="/">
            <span class="tooltip">Home</span>
            <span><i class="fa-solid fa-house text-white ml-2"> </i></span
          ></RouterLink>
        </li>
        <li>
          <a href="#">
            <div class="ml-3 text-base lg:text-2xl text-white">
              {{ `${selectedAircraft.registration}` }}
            </div>
          </a>
        </li>

        <li>
          <RouterLink to="/"></RouterLink>
        </li>
      </ul>
    </nav>

    <r-snackbar
      v-if="snackbarVisible"
      :backgroundColor="snackbarColor"
      :message="snackbarText"
      :timeout="3000"
      @close="snackbarVisible = false"
    >
    </r-snackbar>

    <!-- LOADING -->

    <r-spinner v-if="loading"> </r-spinner>

    <div
      class="flex flex-col h-5/6 justify-between p-5"
      v-if="selectedAircraft"
    >
      <div class="min-h-48 border-red-500">
        <div class="flex flex-wrap gap-3">
          <div>
            <p class="text-sm text-gray-500">Registration</p>
            <input
              class="block w-32 rounded-md border-0 py-2 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              type="text"
              maxlength="10"
              v-model="selectedAircraft.registration"
              :disabled="!enableRegistration"
            />
          </div>
          <div>
            <p class="text-sm text-gray-500">Type Code</p>
            <select
              class="block w-32 py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 cursor-pointer"
              v-model="selectedAircraft.type"
            >
              <option
                v-for="(type, index) in typeOptions"
                :key="index"
                :value="type"
              >
                {{ type }}
              </option>
            </select>
          </div>

          <div>
            <p class="text-sm text-gray-500">A/C Type</p>
            <input
              class="block w-32 rounded-md border-0 py-2 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              type="text"
              maxlength="10"
              v-model="selectedAircraft.typeDescription"
            />
          </div>

          <div>
            <p class="text-sm text-gray-500">Configuration</p>
            <select
              class="block w-32 py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 cursor-pointer"
              v-model="selectedAircraft.deck"
            >
              <option
                v-for="(deck, index) in deckOptions"
                :key="index"
                :value="deck"
              >
                {{ deck }}
              </option>
            </select>
          </div>

          <div v-if="selectedAircraft.deck === 'Cargo'">
            <p class="text-sm text-gray-500">Main Deck Floor</p>
            <select
              class="block w-64 py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 cursor-pointer"
              v-model="selectedAircraft.upperFloorType"
            >
              <option
                v-for="(floor, index) in upperFloorTypeOptions"
                :key="index"
                :value="floor"
              >
                {{ floor }}
              </option>
            </select>
          </div>

          <div>
            <p class="text-sm text-gray-500">Lower Deck Floor</p>
            <select
              class="block w-64 py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 cursor-pointer"
              v-model="selectedAircraft.lowerFloorType"
            >
              <option
                v-for="(floor, index) in lowerFloorTypeOptions"
                :key="index"
                :value="floor"
              >
                {{ floor }}
              </option>
            </select>
          </div>

          <div>
            <p class="text-sm text-gray-500">Max. Payload</p>

            <input
              class="block w-32 py-2 rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 cursor-pointer"
              type="number"
              step="100"
              min="0"
              max="500000"
              required
              v-model="selectedAircraft.maximumPayload"
            />
          </div>

          <div>
            <p class="text-sm text-gray-500">Weights in</p>
            <select
              class="block w-32 py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 cursor-pointer"
              v-model="selectedAircraft.weightUnit"
            >
              <option
                v-for="(unit, index) in unitOptions"
                :key="index"
                :value="unit"
              >
                {{ unit }}
              </option>
            </select>
          </div>

          <div>
            <p class="text-sm text-gray-500">Status</p>
            <select
              class="block w-32 py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 cursor-pointer"
              v-model="selectedAircraft.status"
            >
              <option
                v-for="(status, index) in statusOptions"
                :key="index"
                :value="status"
              >
                {{ status }}
              </option>
            </select>
          </div>
        </div>

        <!-- CABIN CONFIGURATION -->
        <div
          v-if="selectedAircraft.deck === 'Passengers'"
          class="my-2 border-t-2 py-2"
        >
          <p class="text-lg text-gray-500 mb-2">Cabin Configuration</p>

          <div class="flex flex-wrap gap-3">
            <!-- Cabin One -->
            <div class="flex">
              <div class="border rounded-lg px-3 py-1">
                <div class="flex gap-2">
                  <div class="mb-2">
                    <p class="text-sm text-gray-500">Class</p>
                    <input
                      class="block w-24 rounded-md border-0 py-1.5 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      type="text"
                      maxlength="10"
                      v-model="selectedAircraft.cabinOne.class"
                    />
                  </div>

                  <div>
                    <p class="text-sm text-gray-500">Seats</p>

                    <input
                      class="block w-24 py-1.5 rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                      type="number"
                      step="10"
                      min="1"
                      max="300"
                      required
                      v-model="selectedAircraft.cabinOne.seats"
                    />
                  </div>
                </div>

                <div class="flex h-6 items-center mb-2">
                  <input
                    v-model="selectedAircraft.cabinOne.active"
                    id="cabinOneActive"
                    aria-describedby="cabin-one-active"
                    name="cabinOneActive"
                    type="checkbox"
                    class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
                  />

                  <div class="ml-3 text-sm leading-6">
                    <label
                      for="cabinOneActive"
                      class="font-medium text-gray-900"
                      >Active</label
                    >
                    {{ " " }}
                  </div>
                </div>
              </div>
            </div>

            <!-- Cabin Two -->
            <div class="flex">
              <div class="border rounded-lg px-3 py-1">
                <div class="flex gap-2">
                  <div class="mb-2">
                    <p class="text-sm text-gray-500">Class</p>
                    <input
                      class="block w-24 rounded-md border-0 py-1.5 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      type="text"
                      maxlength="10"
                      v-model="selectedAircraft.cabinTwo.class"
                    />
                  </div>

                  <div>
                    <p class="text-sm text-gray-500">Seats</p>

                    <input
                      class="block w-24 py-1.5 rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                      type="number"
                      step="10"
                      min="1"
                      max="300"
                      required
                      v-model="selectedAircraft.cabinTwo.seats"
                    />
                  </div>
                </div>

                <div class="flex h-6 items-center mb-2">
                  <input
                    v-model="selectedAircraft.cabinTwo.active"
                    id="cabinTwoActive"
                    aria-describedby="cabin-two-active"
                    name="cabinTwoActive"
                    type="checkbox"
                    class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
                  />

                  <div class="ml-3 text-sm leading-6">
                    <label
                      for="cabinTwoActive"
                      class="font-medium text-gray-900"
                      >Active</label
                    >
                    {{ " " }}
                  </div>
                </div>
              </div>
            </div>

            <!-- Cabin Three -->
            <div class="flex">
              <div class="border rounded-lg px-3 py-1">
                <div class="flex gap-2">
                  <div class="mb-2">
                    <p class="text-sm text-gray-500">Class</p>
                    <input
                      class="block w-24 rounded-md border-0 py-1.5 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      type="text"
                      maxlength="10"
                      v-model="selectedAircraft.cabinThree.class"
                    />
                  </div>

                  <div>
                    <p class="text-sm text-gray-500">Seats</p>

                    <input
                      class="block w-24 py-1.5 rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                      type="number"
                      step="10"
                      min="1"
                      max="300"
                      required
                      v-model="selectedAircraft.cabinThree.seats"
                    />
                  </div>
                </div>

                <div class="flex h-6 items-center mb-2">
                  <input
                    v-model="selectedAircraft.cabinThree.active"
                    id="cabinThreeActive"
                    aria-describedby="cabin-three-active"
                    name="cabinThreeActive"
                    type="checkbox"
                    class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
                  />

                  <div class="ml-3 text-sm leading-6">
                    <label
                      for="cabinThreeActive"
                      class="font-medium text-gray-900"
                      >Active</label
                    >
                    {{ " " }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- SWA CATEGORIES -->
        <div
          v-if="selectedAircraft.deck === 'Passengers'"
          class="my-2 border-t-2 py-2"
        >
          <p class="text-lg text-gray-500 mb-2">SWA Categories</p>

          <div class="flex flex-wrap gap-3">
            <!-- Category One -->
            <div class="flex gap-5">
              <div class="border rounded-lg px-3 py-1">
                <div class="flex gap-2">
                  <div class="mb-2">
                    <p class="text-sm text-gray-500">Category</p>
                    <input
                      class="block w-24 rounded-md border-0 py-1.5 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      type="text"
                      maxlength="10"
                      v-model="selectedAircraft.swaCategoryOne.name"
                    />
                  </div>

                  <div>
                    <p class="text-sm text-gray-500">Weight</p>

                    <input
                      class="block w-24 py-1.5 rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                      type="number"
                      step="10"
                      min="1"
                      max="50000"
                      required
                      v-model="selectedAircraft.swaCategoryOne.value"
                    />
                  </div>
                </div>

                <div class="flex h-6 items-center mb-2">
                  <input
                    v-model="selectedAircraft.swaCategoryOne.active"
                    id="categoryOneActive"
                    aria-describedby="category-one-active"
                    name="categoryOneActive"
                    type="checkbox"
                    class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
                  />

                  <div class="ml-3 text-sm leading-6">
                    <label
                      for="categoryOneActive"
                      class="font-medium text-gray-900"
                      >Active</label
                    >
                    {{ " " }}
                  </div>
                </div>
              </div>
            </div>

            <!-- Category Two -->
            <div class="flex gap-5">
              <div class="border rounded-lg px-3 py-1">
                <div class="flex gap-2">
                  <div class="mb-2">
                    <p class="text-sm text-gray-500">Category</p>
                    <input
                      class="block w-24 rounded-md border-0 py-1.5 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      type="text"
                      maxlength="10"
                      v-model="selectedAircraft.swaCategoryTwo.name"
                    />
                  </div>

                  <div>
                    <p class="text-sm text-gray-500">Weight</p>

                    <input
                      class="block w-24 py-1.5 rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                      type="number"
                      step="10"
                      min="1"
                      max="50000"
                      required
                      v-model="selectedAircraft.swaCategoryTwo.value"
                    />
                  </div>
                </div>

                <div class="flex h-6 items-center mb-2">
                  <input
                    v-model="selectedAircraft.swaCategoryTwo.active"
                    id="categoryTwoActive"
                    aria-describedby="category-two-active"
                    name="categoryTwoActive"
                    type="checkbox"
                    class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
                  />

                  <div class="ml-3 text-sm leading-6">
                    <label
                      for="categoryTwoActive"
                      class="font-medium text-gray-900"
                      >Active</label
                    >
                    {{ " " }}
                  </div>
                </div>
              </div>
            </div>

            <!-- Category Three -->
            <div class="flex gap-5">
              <div class="border rounded-lg px-3 py-1">
                <div class="flex gap-2">
                  <div class="mb-2">
                    <p class="text-sm text-gray-500">Category</p>
                    <input
                      class="block w-24 rounded-md border-0 py-1.5 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      type="text"
                      maxlength="10"
                      v-model="selectedAircraft.swaCategoryThree.name"
                    />
                  </div>

                  <div>
                    <p class="text-sm text-gray-500">Weight</p>

                    <input
                      class="block w-24 py-1.5 rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                      type="number"
                      step="10"
                      min="1"
                      max="50000"
                      required
                      v-model="selectedAircraft.swaCategoryThree.value"
                    />
                  </div>
                </div>

                <div class="flex h-6 items-center mb-2">
                  <input
                    v-model="selectedAircraft.swaCategoryThree.active"
                    id="categoryThreeActive"
                    aria-describedby="category-three-active"
                    name="categoryThreeActive"
                    type="checkbox"
                    class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
                  />

                  <div class="ml-3 text-sm leading-6">
                    <label
                      for="categoryThreeActive"
                      class="font-medium text-gray-900"
                      >Active</label
                    >
                    {{ " " }}
                  </div>
                </div>
              </div>
            </div>

            <!-- Category Four -->
            <div class="flex gap-5">
              <div class="border rounded-lg px-3 py-1">
                <div class="flex gap-2">
                  <div class="mb-2">
                    <p class="text-sm text-gray-500">Category</p>
                    <input
                      class="block w-24 rounded-md border-0 py-1.5 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      type="text"
                      maxlength="10"
                      v-model="selectedAircraft.swaCategoryFour.name"
                    />
                  </div>

                  <div>
                    <p class="text-sm text-gray-500">Weight</p>

                    <input
                      class="block w-24 py-1.5 rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                      type="number"
                      step="10"
                      min="1"
                      max="50000"
                      required
                      v-model="selectedAircraft.swaCategoryFour.value"
                    />
                  </div>
                </div>

                <div class="flex h-6 items-center mb-2">
                  <input
                    v-model="selectedAircraft.swaCategoryFour.active"
                    id="categoryFourActive"
                    aria-describedby="category-four-active"
                    name="categoryFourActive"
                    type="checkbox"
                    class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
                  />

                  <div class="ml-3 text-sm leading-6">
                    <label
                      for="categoryFourActive"
                      class="font-medium text-gray-900"
                      >Active</label
                    >
                    {{ " " }}
                  </div>
                </div>
              </div>
            </div>
            <!-- Category Five -->
            <div class="flex gap-5">
              <div class="border rounded-lg px-3 py-1">
                <div class="flex gap-2">
                  <div class="mb-2">
                    <p class="text-sm text-gray-500">Category</p>
                    <input
                      class="block w-24 rounded-md border-0 py-1.5 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      type="text"
                      maxlength="10"
                      v-model="selectedAircraft.swaCategoryFive.name"
                    />
                  </div>

                  <div>
                    <p class="text-sm text-gray-500">Weight</p>

                    <input
                      class="block w-24 py-1.5 rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                      type="number"
                      step="10"
                      min="1"
                      max="50000"
                      required
                      v-model="selectedAircraft.swaCategoryFive.value"
                    />
                  </div>
                </div>

                <div class="flex h-6 items-center mb-2">
                  <input
                    v-model="selectedAircraft.swaCategoryFive.active"
                    id="categoryFiveActive"
                    aria-describedby="category-five-active"
                    name="categoryFiveActive"
                    type="checkbox"
                    class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
                  />

                  <div class="ml-3 text-sm leading-6">
                    <label
                      for="categoryFiveActive"
                      class="font-medium text-gray-900"
                      >Active</label
                    >
                    {{ " " }}
                  </div>
                </div>
              </div>
            </div>

            <!-- Category Six -->
            <div class="flex gap-5">
              <div class="border rounded-lg px-3 py-1">
                <div class="flex gap-2">
                  <div class="mb-2">
                    <p class="text-sm text-gray-500">Category</p>
                    <input
                      class="block w-24 rounded-md border-0 py-1.5 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      type="text"
                      maxlength="10"
                      v-model="selectedAircraft.swaCategorySix.name"
                    />
                  </div>

                  <div>
                    <p class="text-sm text-gray-500">Weight</p>

                    <input
                      class="block w-24 py-1.5 rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                      type="number"
                      step="10"
                      min="1"
                      max="50000"
                      required
                      v-model="selectedAircraft.swaCategorySix.value"
                    />
                  </div>
                </div>

                <div class="flex h-6 items-center mb-2">
                  <input
                    v-model="selectedAircraft.swaCategorySix.active"
                    id="categorySixActive"
                    aria-describedby="category-six-active"
                    name="categorySixActive"
                    type="checkbox"
                    class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
                  />

                  <div class="ml-3 text-sm leading-6">
                    <label
                      for="categorySixActive"
                      class="font-medium text-gray-900"
                      >Active</label
                    >
                    {{ " " }}
                  </div>
                </div>
              </div>
            </div>

            <!-- Category Seven -->
            <div class="flex gap-5">
              <div class="border rounded-lg px-3 py-1">
                <div class="flex gap-2">
                  <div class="mb-2">
                    <p class="text-sm text-gray-500">Category</p>
                    <input
                      class="block w-24 rounded-md border-0 py-1.5 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      type="text"
                      maxlength="10"
                      v-model="selectedAircraft.swaCategorySeven.name"
                    />
                  </div>

                  <div>
                    <p class="text-sm text-gray-500">Weight</p>

                    <input
                      class="block w-24 py-1.5 rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                      type="number"
                      step="10"
                      min="1"
                      max="50000"
                      required
                      v-model="selectedAircraft.swaCategorySeven.value"
                    />
                  </div>
                </div>

                <div class="flex h-6 items-center mb-2">
                  <input
                    v-model="selectedAircraft.swaCategorySeven.active"
                    id="categorySevenActive"
                    aria-describedby="category-seven-active"
                    name="categorySevenActive"
                    type="checkbox"
                    class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
                  />

                  <div class="ml-3 text-sm leading-6">
                    <label
                      for="categorySevenActive"
                      class="font-medium text-gray-900"
                      >Active</label
                    >
                    {{ " " }}
                  </div>
                </div>
              </div>
            </div>

            <!-- Category Eight -->
            <div class="flex gap-5">
              <div class="border rounded-lg px-3 py-1">
                <div class="flex gap-2">
                  <div class="mb-2">
                    <p class="text-sm text-gray-500">Category</p>
                    <input
                      class="block w-24 rounded-md border-0 py-1.5 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      type="text"
                      maxlength="10"
                      v-model="selectedAircraft.swaCategoryEight.name"
                    />
                  </div>

                  <div>
                    <p class="text-sm text-gray-500">Weight</p>

                    <input
                      class="block w-24 py-1.5 rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                      type="number"
                      step="10"
                      min="1"
                      max="50000"
                      required
                      v-model="selectedAircraft.swaCategoryEight.value"
                    />
                  </div>
                </div>

                <div class="flex h-6 items-center mb-2">
                  <input
                    v-model="selectedAircraft.swaCategoryEight.active"
                    id="categoryEightActive"
                    aria-describedby="category-eight-active"
                    name="categoryEightActive"
                    type="checkbox"
                    class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
                  />

                  <div class="ml-3 text-sm leading-6">
                    <label
                      for="categoryEightActive"
                      class="font-medium text-gray-900"
                      >Active</label
                    >
                    {{ " " }}
                  </div>
                </div>
              </div>
            </div>

            <!-- Category Nine -->
            <div class="flex gap-5">
              <div class="border rounded-lg px-3 py-1">
                <div class="flex gap-2">
                  <div class="mb-2">
                    <p class="text-sm text-gray-500">Category</p>
                    <input
                      class="block w-24 rounded-md border-0 py-1.5 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      type="text"
                      maxlength="10"
                      v-model="selectedAircraft.swaCategoryNine.name"
                    />
                  </div>

                  <div>
                    <p class="text-sm text-gray-500">Weight</p>

                    <input
                      class="block w-24 py-1.5 rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                      type="number"
                      step="10"
                      min="1"
                      max="50000"
                      required
                      v-model="selectedAircraft.swaCategoryNine.value"
                    />
                  </div>
                </div>

                <div class="flex h-6 items-center mb-2">
                  <input
                    v-model="selectedAircraft.swaCategoryNine.active"
                    id="categoryNineActive"
                    aria-describedby="category-nine-active"
                    name="categoryNineActive"
                    type="checkbox"
                    class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
                  />

                  <div class="ml-3 text-sm leading-6">
                    <label
                      for="categoryNineActive"
                      class="font-medium text-gray-900"
                      >Active</label
                    >
                    {{ " " }}
                  </div>
                </div>
              </div>
            </div>

            <!-- Category Ten -->
            <div class="flex gap-5">
              <div class="border rounded-lg px-3 py-1">
                <div class="flex gap-2">
                  <div class="mb-2">
                    <p class="text-sm text-gray-500">Category</p>
                    <input
                      class="block w-24 rounded-md border-0 py-1.5 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      type="text"
                      maxlength="10"
                      v-model="selectedAircraft.swaCategoryTen.name"
                    />
                  </div>

                  <div>
                    <p class="text-sm text-gray-500">Weight</p>

                    <input
                      class="block w-24 py-1.5 rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                      type="number"
                      step="10"
                      min="1"
                      max="50000"
                      required
                      v-model="selectedAircraft.swaCategoryTen.value"
                    />
                  </div>
                </div>

                <div class="flex h-6 items-center mb-2">
                  <input
                    v-model="selectedAircraft.swaCategoryTen.active"
                    id="categoryTenActive"
                    aria-describedby="category-ten-active"
                    name="categoryTenActive"
                    type="checkbox"
                    class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
                  />

                  <div class="ml-3 text-sm leading-6">
                    <label
                      for="categoryTenActive"
                      class="font-medium text-gray-900"
                      >Active</label
                    >
                    {{ " " }}
                  </div>
                </div>
              </div>
            </div>

            <!-- Category Eleven -->
            <div class="flex gap-5">
              <div class="border rounded-lg px-3 py-1">
                <div class="flex gap-2">
                  <div class="mb-2">
                    <p class="text-sm text-gray-500">Category</p>
                    <input
                      class="block w-24 rounded-md border-0 py-1.5 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      type="text"
                      maxlength="10"
                      v-model="selectedAircraft.swaCategoryEleven.name"
                    />
                  </div>

                  <div>
                    <p class="text-sm text-gray-500">Weight</p>

                    <input
                      class="block w-24 py-1.5 rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                      type="number"
                      step="10"
                      min="1"
                      max="50000"
                      required
                      v-model="selectedAircraft.swaCategoryEleven.value"
                    />
                  </div>
                </div>

                <div class="flex h-6 items-center mb-2">
                  <input
                    v-model="selectedAircraft.swaCategoryEleven.active"
                    id="categoryElevenActive"
                    aria-describedby="category-eleven-active"
                    name="categoryElevenActive"
                    type="checkbox"
                    class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
                  />

                  <div class="ml-3 text-sm leading-6">
                    <label
                      for="categoryElevenActive"
                      class="font-medium text-gray-900"
                      >Active</label
                    >
                    {{ " " }}
                  </div>
                </div>
              </div>
            </div>

            <!-- Category Twelve -->
            <div class="flex gap-5">
              <div class="border rounded-lg px-3 py-1">
                <div class="flex gap-2">
                  <div class="mb-2">
                    <p class="text-sm text-gray-500">Category</p>
                    <input
                      class="block w-24 rounded-md border-0 py-1.5 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      type="text"
                      maxlength="10"
                      v-model="selectedAircraft.swaCategoryTwelve.name"
                    />
                  </div>

                  <div>
                    <p class="text-sm text-gray-500">Weight</p>

                    <input
                      class="block w-24 py-1.5 rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                      type="number"
                      step="10"
                      min="1"
                      max="50000"
                      required
                      v-model="selectedAircraft.swaCategoryTwelve.value"
                    />
                  </div>
                </div>

                <div class="flex h-6 items-center mb-2">
                  <input
                    v-model="selectedAircraft.swaCategoryTwelve.active"
                    id="categoryTwelveActive"
                    aria-describedby="category-twelve-active"
                    name="categoryTwelveActive"
                    type="checkbox"
                    class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
                  />

                  <div class="ml-3 text-sm leading-6">
                    <label
                      for="categoryTwelveActive"
                      class="font-medium text-gray-900"
                      >Active</label
                    >
                    {{ " " }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex justify-end mt-3">
        <button
          @click="[handleGoBack(), (selectedAircraft = null)]"
          class="btn bg-gray-500 text-white"
        >
          Cancel
        </button>

        <button
          @click="handleUpdateAircraft"
          class="btn bg-green-500 text-white ml-1"
        >
          Save Aircraft
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../services/api";
import authentication from "../services/authentication";
import { mixin } from "../mixins/mixin";
import RSnackbar from "../components/RSnackbar.vue";
import RSpinner from "../components/RSpinner.vue";
import RModal from "../components/RModal.vue";
import { Switch } from "@headlessui/vue";
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid";

export default {
  name: "aircraft",
  mixins: [mixin],
  components: {
    RSnackbar,
    RSpinner,
    RModal,
    Switch,
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    ChevronUpDownIcon,
  },

  data() {
    return {
      loading: false,

      inactivityTimer: null,

      error: null,
      typeOptions: [
        "737",
        "738",
        "744",
        "747",
        "748",
        "763",
        "74Y",
        "76F",
        "77F",
        "LCF",
        "PAX",
      ],
      deckOptions: ["Cargo", "Passengers"],
      upperFloorTypeOptions: ["Ancra", "Boeing"],
      lowerFloorTypeOptions: ["Ancra", "Boeing"],
      unitOptions: ["Lbs", "Kgs"],
      statusOptions: ["Active", "Inactive"],
      enableRegistration: false,
      maximumPayload: 500000,

      //-------------------------Snackbar
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,
    };
  },

  mounted() {
    this.startInactivityTimer();
    document.addEventListener("click", this.resetInactivityTimer);
  },
  unmounted() {
    this.clearInactivityTimer();
    document.removeEventListener("click", this.resetInactivityTimer);
  },

  computed: {
    auth() {
      return this.$store.state.auth;
    },

    selectedAircraft() {
      return this.$store.getters.selectedAircraft;
    },
  },

  methods: {
    startInactivityTimer() {
      this.clearInactivityTimer();
      this.inactivityTimer = setTimeout(() => {
        this.handleAutoLogout();
      }, this.timeoutDuration);
    },

    clearInactivityTimer() {
      if (this.inactivityTimer) {
        clearTimeout(this.inactivityTimer);
      }
    },

    resetInactivityTimer() {
      this.startInactivityTimer();
    },

    async handleAutoLogout() {
      try {
        if (!this.auth) {
          this.$store.commit("updateUser", null);
          clearTimeout(this.inactivityTimer);
          this.$router.push({ name: "home" });
          return;
        }

        //---------------------------------------Refresh Token
        this.loading = true;
        const accessToken = await authentication.getAccessToken();
        if (!accessToken) {
          this.loading = false;
          this.$router.push({ name: "home" });
          return;
        }

        const res = await api.get("/auth/logout", {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        });

        this.loading = false;

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }

        this.$store.commit("updateAuth", null);
        this.$store.commit("updateUser", null);
        clearTimeout(this.inactivityTimer);
        this.$router.push({ name: "home" });
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarVisible = true;
        this.snackbarText = `Error during logout: ${error}`;

        setTimeout(() => {
          this.$store.commit("updateAuth", null);
          this.$store.commit("updateUser", null);
          clearTimeout(this.inactivityTimer);
          this.$router.push({ name: "home" });
        }, 3000);
      }
    },

    isValidNumber(num) {
      const isValid = !isNaN(num) && !isNaN(parseInt(num));
      if (isValid && parseInt(num) > 0) {
        return true;
      } else {
        return false;
      }
    },

    async handleUpdateAircraft() {
      if (!this.isValidNumber(this.selectedAircraft.maximumPayload)) {
        this.snackbarColor = "red";
        this.snackbarText = "Please enter a valid Maximum Payload value.";
        this.snackbarVisible = true;
        return;
      }

      if (!this.selectedAircraft.typeDescription) {
        this.snackbarColor = "red";
        this.snackbarText = `Please enter a valid type description`;
        this.snackbarVisible = true;
        return;
      }

      let cargoOnly = false;

      if (this.selectedAircraft.deck === "Cargo") {
        cargoOnly = true;
      }

      let cabinConfig = [];
      let swaCategories = [];

      if (!cargoOnly) {
        // Cabin One
        if (this.selectedAircraft.cabinOne.active) {
          if (!this.selectedAircraft.cabinOne.class.length) {
            this.snackbarColor = "red";
            this.snackbarText = `Please enter a valid cabin class name`;
            this.snackbarVisible = true;
            return;
          }

          if (!this.isValidNumber(this.selectedAircraft.cabinOne.seats)) {
            this.snackbarColor = "red";
            this.snackbarText = `Please enter a valid number of seats for cabin class "${this.selectedAircraft.cabinOne.class}"`;
            this.snackbarVisible = true;
            return;
          }
          cabinConfig.push({
            class: this.selectedAircraft.cabinOne.class.toUpperCase(),
            seats: this.selectedAircraft.cabinOne.seats,
          });
        }

        // Cabin Two
        if (this.selectedAircraft.cabinTwo.active) {
          if (!this.selectedAircraft.cabinTwo.class.length) {
            this.snackbarColor = "red";
            this.snackbarText = `Please enter a valid cabin class name`;
            this.snackbarVisible = true;
            return;
          }

          if (!this.isValidNumber(this.selectedAircraft.cabinTwo.seats)) {
            this.snackbarColor = "red";
            this.snackbarText = `Please enter a valid number of seats for cabin class "${this.selectedAircraft.cabinTwo.class}"`;
            this.snackbarVisible = true;
            return;
          }
          cabinConfig.push({
            class: this.selectedAircraft.cabinTwo.class.toUpperCase(),
            seats: this.selectedAircraft.cabinTwo.seats,
          });
        }

        // Cabin Three
        if (this.selectedAircraft.cabinThree.active) {
          if (!this.selectedAircraft.cabinThree.class.length) {
            this.snackbarColor = "red";
            this.snackbarText = `Please enter a valid cabin class name`;
            this.snackbarVisible = true;
            return;
          }

          if (!this.isValidNumber(this.selectedAircraft.cabinThree.seats)) {
            this.snackbarColor = "red";
            this.snackbarText = `Please enter a valid number of seats for cabin class "${this.selectedAircraft.cabinThree.class}"`;
            this.snackbarVisible = true;
            return;
          }
          cabinConfig.push({
            class: this.selectedAircraft.cabinThree.class.toUpperCase(),
            seats: this.selectedAircraft.cabinThree.seats,
          });
        }

        //swaCategoryOne
        if (this.selectedAircraft.swaCategoryOne.active) {
          if (!this.selectedAircraft.swaCategoryOne.name.length) {
            this.snackbarColor = "red";
            this.snackbarText = `Please enter a valid SWA Category name`;
            this.snackbarVisible = true;
            return;
          }

          if (!this.isValidNumber(this.selectedAircraft.swaCategoryOne.value)) {
            this.snackbarColor = "red";
            this.snackbarText = `Please enter a valid value for SWA Category "${this.selectedAircraft.swaCategoryOne.name}"`;
            this.snackbarVisible = true;
            return;
          }
          swaCategories.push({
            name: this.selectedAircraft.swaCategoryOne.name.toUpperCase(),
            value: this.selectedAircraft.swaCategoryOne.value,
          });
        }

        //swaCategoryTwo
        if (this.selectedAircraft.swaCategoryTwo.active) {
          if (!this.selectedAircraft.swaCategoryTwo.name.length) {
            this.snackbarColor = "red";
            this.snackbarText = `Please enter a valid SWA Category name`;
            this.snackbarVisible = true;
            return;
          }

          if (!this.isValidNumber(this.selectedAircraft.swaCategoryTwo.value)) {
            this.snackbarColor = "red";
            this.snackbarText = `Please enter a valid value for SWA Category "${this.selectedAircraft.swaCategoryTwo.name}"`;
            this.snackbarVisible = true;
            return;
          }
          swaCategories.push({
            name: this.selectedAircraft.swaCategoryTwo.name.toUpperCase(),
            value: this.selectedAircraft.swaCategoryTwo.value,
          });
        }

        //swaCategoryThree
        if (this.selectedAircraft.swaCategoryThree.active) {
          if (!this.selectedAircraft.swaCategoryThree.name.length) {
            this.snackbarColor = "red";
            this.snackbarText = `Please enter a valid SWA Category name`;
            this.snackbarVisible = true;
            return;
          }

          if (
            !this.isValidNumber(this.selectedAircraft.swaCategoryThree.value)
          ) {
            this.snackbarColor = "red";
            this.snackbarText = `Please enter a valid value for SWA Category "${this.selectedAircraft.swaCategoryThree.name}"`;
            this.snackbarVisible = true;
            return;
          }
          swaCategories.push({
            name: this.selectedAircraft.swaCategoryThree.name.toUpperCase(),
            value: this.selectedAircraft.swaCategoryThree.value,
          });
        }

        //swaCategoryFour
        if (this.selectedAircraft.swaCategoryFour.active) {
          if (!this.selectedAircraft.swaCategoryFour.name.length) {
            this.snackbarColor = "red";
            this.snackbarText = `Please enter a valid SWA Category name`;
            this.snackbarVisible = true;
            return;
          }

          if (
            !this.isValidNumber(this.selectedAircraft.swaCategoryFour.value)
          ) {
            this.snackbarColor = "red";
            this.snackbarText = `Please enter a valid value for SWA Category "${this.selectedAircraft.swaCategoryFour.name}"`;
            this.snackbarVisible = true;
            return;
          }
          swaCategories.push({
            name: this.selectedAircraft.swaCategoryFour.name.toUpperCase(),
            value: this.selectedAircraft.swaCategoryFour.value,
          });
        }

        //swaCategoryFive
        if (this.selectedAircraft.swaCategoryFive.active) {
          if (!this.selectedAircraft.swaCategoryFive.name.length) {
            this.snackbarColor = "red";
            this.snackbarText = `Please enter a valid SWA Category name`;
            this.snackbarVisible = true;
            return;
          }

          if (
            !this.isValidNumber(this.selectedAircraft.swaCategoryFive.value)
          ) {
            this.snackbarColor = "red";
            this.snackbarText = `Please enter a valid value for SWA Category "${this.selectedAircraft.swaCategoryFive.name}"`;
            this.snackbarVisible = true;
            return;
          }
          swaCategories.push({
            name: this.selectedAircraft.swaCategoryFive.name.toUpperCase(),
            value: this.selectedAircraft.swaCategoryFive.value,
          });
        }

        //swaCategorySix
        if (this.selectedAircraft.swaCategorySix.active) {
          if (!this.selectedAircraft.swaCategorySix.name.length) {
            this.snackbarColor = "red";
            this.snackbarText = `Please enter a valid SWA Category name`;
            this.snackbarVisible = true;
            return;
          }

          if (!this.isValidNumber(this.selectedAircraft.swaCategorySix.value)) {
            this.snackbarColor = "red";
            this.snackbarText = `Please enter a valid value for SWA Category "${this.selectedAircraft.swaCategorySix.name}"`;
            this.snackbarVisible = true;
            return;
          }
          swaCategories.push({
            name: this.selectedAircraft.swaCategorySix.name.toUpperCase(),
            value: this.selectedAircraft.swaCategorySix.value,
          });
        }

        //swaCategorySeven
        if (this.selectedAircraft.swaCategorySeven.active) {
          if (!this.selectedAircraft.swaCategorySeven.name.length) {
            this.snackbarColor = "red";
            this.snackbarText = `Please enter a valid SWA Category name`;
            this.snackbarVisible = true;
            return;
          }

          if (
            !this.isValidNumber(this.selectedAircraft.swaCategorySeven.value)
          ) {
            this.snackbarColor = "red";
            this.snackbarText = `Please enter a valid value for SWA Category "${this.selectedAircraft.swaCategorySeven.name}"`;
            this.snackbarVisible = true;
            return;
          }
          swaCategories.push({
            name: this.selectedAircraft.swaCategorySeven.name.toUpperCase(),
            value: this.selectedAircraft.swaCategorySeven.value,
          });
        }

        //swaCategoryEight
        if (this.selectedAircraft.swaCategoryEight.active) {
          if (!this.selectedAircraft.swaCategoryEight.name.length) {
            this.snackbarColor = "red";
            this.snackbarText = `Please enter a valid SWA Category name`;
            this.snackbarVisible = true;
            return;
          }

          if (
            !this.isValidNumber(this.selectedAircraft.swaCategoryEight.value)
          ) {
            this.snackbarColor = "red";
            this.snackbarText = `Please enter a valid value for SWA Category "${this.selectedAircraft.swaCategoryEight.name}"`;
            this.snackbarVisible = true;
            return;
          }
          swaCategories.push({
            name: this.selectedAircraft.swaCategoryEight.name.toUpperCase(),
            value: this.selectedAircraft.swaCategoryEight.value,
          });
        }

        //swaCategoryNine
        if (this.selectedAircraft.swaCategoryNine.active) {
          if (!this.selectedAircraft.swaCategoryNine.name.length) {
            this.snackbarColor = "red";
            this.snackbarText = `Please enter a valid SWA Category name`;
            this.snackbarVisible = true;
            return;
          }

          if (
            !this.isValidNumber(this.selectedAircraft.swaCategoryNine.value)
          ) {
            this.snackbarColor = "red";
            this.snackbarText = `Please enter a valid value for SWA Category "${this.selectedAircraft.swaCategoryNine.name}"`;
            this.snackbarVisible = true;
            return;
          }
          swaCategories.push({
            name: this.selectedAircraft.swaCategoryNine.name.toUpperCase(),
            value: this.selectedAircraft.swaCategoryNine.value,
          });
        }

        //swaCategoryTen
        if (this.selectedAircraft.swaCategoryTen.active) {
          if (!this.selectedAircraft.swaCategoryTen.name.length) {
            this.snackbarColor = "red";
            this.snackbarText = `Please enter a valid SWA Category name`;
            this.snackbarVisible = true;
            return;
          }

          if (!this.isValidNumber(this.selectedAircraft.swaCategoryTen.value)) {
            this.snackbarColor = "red";
            this.snackbarText = `Please enter a valid value for SWA Category "${this.selectedAircraft.swaCategoryTen.name}"`;
            this.snackbarVisible = true;
            return;
          }
          swaCategories.push({
            name: this.selectedAircraft.swaCategoryTen.name.toUpperCase(),
            value: this.selectedAircraft.swaCategoryTen.value,
          });
        }

        //swaCategoryEleven
        if (this.selectedAircraft.swaCategoryEleven.active) {
          if (!this.selectedAircraft.swaCategoryEleven.name.length) {
            this.snackbarColor = "red";
            this.snackbarText = `Please enter a valid SWA Category name`;
            this.snackbarVisible = true;
            return;
          }

          if (
            !this.isValidNumber(this.selectedAircraft.swaCategoryEleven.value)
          ) {
            this.snackbarColor = "red";
            this.snackbarText = `Please enter a valid value for SWA Category "${this.selectedAircraft.swaCategoryEleven.name}"`;
            this.snackbarVisible = true;
            return;
          }
          swaCategories.push({
            name: this.selectedAircraft.swaCategoryEleven.name.toUpperCase(),
            value: this.selectedAircraft.swaCategoryEleven.value,
          });
        }

        //swaCategoryTwelve
        if (this.selectedAircraft.swaCategoryTwelve.active) {
          if (!this.selectedAircraft.swaCategoryTwelve.name.length) {
            this.snackbarColor = "red";
            this.snackbarText = `Please enter a valid SWA Category name`;
            this.snackbarVisible = true;
            return;
          }

          if (
            !this.isValidNumber(this.selectedAircraft.swaCategoryTwelve.value)
          ) {
            this.snackbarColor = "red";
            this.snackbarText = `Please enter a valid value for SWA Category "${this.selectedAircraft.swaCategoryTwelve.name}"`;
            this.snackbarVisible = true;
            return;
          }
          swaCategories.push({
            name: this.selectedAircraft.swaCategoryTwelve.name.toUpperCase(),
            value: this.selectedAircraft.swaCategoryTwelve.value,
          });
        }
      }

      let aircraft = {
        registration: this.selectedAircraft.registration,
        type: this.selectedAircraft.type,
        typeDescription: this.selectedAircraft.typeDescription.toUpperCase(),
        upperFloorType: this.selectedAircraft.upperFloorType,
        lowerFloorType: this.selectedAircraft.lowerFloorType,
        status: this.selectedAircraft.status,
        cargoOnly,
        weightUnit: this.selectedAircraft.weightUnit,
        maximumPayload: this.selectedAircraft.maximumPayload,
        swaCategories,
        cabinConfig,
      };

      //---------------------------------------Refresh Token
      this.loading = true;
      const accessToken = await authentication.getAccessToken();
      if (!accessToken) {
        this.loading = false;
        this.$router.push({ name: "home" });
        return;
      }

      try {
        const res = await api.put(
          `/aircrafts/${this.selectedAircraft._id}`,
          aircraft,
          {
            headers: { Authorization: "Bearer " + accessToken },
          }
        );

        this.loading = false;

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }

        this.handleGoBack();
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    handleGoBack() {
      this.$router.go(-1);
    },

    goHome() {
      this.$router.push({ name: "home" });
    },
  },
};
</script>
