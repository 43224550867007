<template>
  <div>
    <r-snackbar
      v-if="snackbarVisible"
      :backgroundColor="snackbarColor"
      :message="snackbarText"
      :timeout="3000"
      @close="snackbarVisible = false"
    >
    </r-snackbar>

    <!-- LOADING -->

    <r-spinner v-if="loading"> </r-spinner>

    <nav>
      <ul class="nav-wrapper">
        <li class="icon">
          <RouterLink to="/">
            <span class="tooltip">Home</span>
            <span><i class="fa-solid fa-house text-white ml-3"> </i></span
          ></RouterLink>
        </li>

        <li>
          <a href="#">
            <div class="mx-3 text-base lg:text-2xl text-white">
              Notifications
            </div>
          </a>
        </li>

        <li @click="handleOpenDateFilter">
          <a href="#"
            ><button type="button" class="btn text-white">
              {{
                `${formatDateShort(this.dateFilter.start)} - ${formatDateShort(
                  this.dateFilter.end
                )}`
              }}
            </button></a
          >
        </li>

        <li>
          <a href="#">
            <input
              @click="[(search = ''), handleFilterNotifications()]"
              class="mx-3 text-align-center w-24 h-8 rounded-md border-gray-500"
              v-model="search"
              @input="handleFilterNotifications()"
              type="text"
              maxlength="6"
              placeholder="Name"
            />
          </a>
        </li>

        <li @click="handleOpenFilters" class="icon">
          <a href="#">
            <span class="tooltip">Notifications Filters</span>
            <span><i class="fa-solid fa-bars-filter text-yellow-500"></i></span>
          </a>
        </li>

        <li class="icon" @click="getData">
          <a href="#">
            <span class="tooltip">Refresh</span>
            <span><i class="fa-solid fa-rotate"></i></span>
          </a>
        </li>

        <li
          v-if="selectedAssignments.length"
          class="icon"
          @click="sendNotifications"
        >
          <a href="#">
            <span class="tooltip">Send Notications</span>
            <span><i class="fa-solid fa-paper-plane"></i></span>
          </a>
        </li>

        <li>
          <a href="#">
            <div
              v-if="selectedAssignments.length"
              class="text-sm text-white mr-5 cursor-default"
            >
              {{ `Send ${selectedAssignments.length} notification(s)` }}
            </div>
          </a>
        </li>

        <li>
          <a href="#"> </a>
        </li>
      </ul>
    </nav>

    <!-- DATE FILTER DIALOG -->
    <r-modal v-if="showDateFilterDialog" @close="showDateFilterDialog = false">
      <div v-if="showDateFilterDialog" class="p-5">
        <div class="flex justify-between mb-2">
          <div class="text-2xl font-bold">Date Filter</div>
          <button @click="showDateFilterDialog = false" class="btn-icon">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <div class="flex">
          <div>
            <label
              for="date-filter"
              class="block text-sm font-medium leading-6 text-gray-900"
              >Start Date</label
            >

            <input
              class="block w-32 rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              v-model="startDate"
              type="date"
              id="date-filter"
            />
          </div>

          <div class="ml-3">
            <label
              for="date-filter"
              class="block text-sm font-medium leading-6 text-gray-900"
              >Number of Days</label
            >

            <select
              class="block w-24 py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              v-model="numberOfDays"
            >
              <option
                v-for="option in numberOfDaysOptions"
                :key="option"
                :value="option"
              >
                {{ option }}
              </option>
            </select>
          </div>
        </div>

        <div class="flex justify-end mt-1">
          <button
            @click="handleSaveDateFilter"
            class="btn bg-blue-500 text-white"
          >
            Apply Filter
          </button>
        </div>
      </div>
    </r-modal>

    <!--  FILTERS DIALOG -->
    <r-modal v-if="showFiltersDialog" @close="showFiltersDialog = false">
      <div v-if="showFiltersDialog" class="p-5">
        <!-- Header -->
        <div class="flex justify-between mb-10">
          <div class="text-2xl font-bold ml-5">Notification Filters</div>
          <button @click="showFiltersDialog = false" class="btn-icon">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <div
          class="flex flex-col justify-between text-sm px-3 max-h-[300px] lg:max-h-[500px] overflow-x-auto"
        >
          <!-- Section -->
          <div class="px-5">
            <!-- Filter by Send Status -->
            <div class="border-b border-gray-400 mb-5">
              <div class="flex h-6 items-center mb-2">
                <input
                  v-model="filterByStatus"
                  id="filterByCompany"
                  aria-describedby="filter-description"
                  name="filterByCompany"
                  type="checkbox"
                  class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
                />

                <div class="ml-3 text-base leading-6">
                  <label for="filterByCompany" class="font-medium text-gray-900"
                    >Filter by Notification Status</label
                  >
                  {{ " " }}
                </div>
              </div>
              <div class="flex my-1 px-5 pb-2" v-if="filterByStatus">
                <Listbox as="div" v-model="selectedStatus" class="mb-10">
                  <ListboxLabel
                    class="block text-sm font-medium leading-6 text-gray-900"
                    >Filter by Notification Status</ListboxLabel
                  >
                  <div class="relative mt-2 min-w-[250px]">
                    <ListboxButton
                      class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                    >
                      <span class="block truncate">{{ selectedStatus }}</span>
                      <span
                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                      >
                        <ChevronUpDownIcon
                          class="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </ListboxButton>

                    <transition
                      leave-active-class="transition ease-in duration-100"
                      leave-from-class="opacity-100"
                      leave-to-class="opacity-0"
                    >
                      <ListboxOptions
                        class="absolute z-10 mt-1 max-h-64 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                      >
                        <ListboxOption
                          as="template"
                          v-for="(option, id) in statusOptions"
                          :key="id"
                          :value="option"
                          v-slot="{ active, selectedStatus }"
                        >
                          <li
                            :class="[
                              active
                                ? 'bg-blue-600 text-white'
                                : 'text-gray-900',
                              'relative cursor-default select-none py-2 pl-3 pr-9',
                            ]"
                          >
                            <span
                              :class="[
                                selectedStatus
                                  ? 'font-semibold'
                                  : 'font-normal',
                                'block truncate',
                              ]"
                              >{{ option }}</span
                            >

                            <span
                              v-if="selectedStatus"
                              :class="[
                                active ? 'text-white' : 'text-blue-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                              ]"
                            >
                              <CheckIcon class="h-5 w-5" aria-hidden="true" />
                            </span>
                          </li>
                        </ListboxOption>
                      </ListboxOptions>
                    </transition>
                  </div>
                </Listbox>
              </div>
            </div>

            <!-- Filter by Company Name -->
            <div class="border-b border-gray-400 mb-5">
              <div class="flex h-6 items-center mb-2">
                <input
                  v-model="filterByCompany"
                  id="filterByCompany"
                  aria-describedby="filter-description"
                  name="filterByCompany"
                  type="checkbox"
                  class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
                />

                <div class="ml-3 text-base leading-6">
                  <label for="filterByCompany" class="font-medium text-gray-900"
                    >Filter by Company</label
                  >
                  {{ " " }}
                </div>
              </div>

              <div class="flex my-1 px-5 pb-2" v-if="filterByCompany">
                <Listbox as="div" v-model="selectedCompany" class="mb-10">
                  <ListboxLabel
                    class="block text-sm font-medium leading-6 text-gray-900"
                    >Show Employees from this Company</ListboxLabel
                  >
                  <div class="relative mt-2 min-w-[250px]">
                    <ListboxButton
                      class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                    >
                      <span class="block truncate">{{ selectedCompany }}</span>
                      <span
                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                      >
                        <ChevronUpDownIcon
                          class="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </ListboxButton>

                    <transition
                      leave-active-class="transition ease-in duration-100"
                      leave-from-class="opacity-100"
                      leave-to-class="opacity-0"
                    >
                      <ListboxOptions
                        class="absolute z-10 mt-1 max-h-64 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                      >
                        <ListboxOption
                          as="template"
                          v-for="(option, id) in companyOptions"
                          :key="id"
                          :value="option"
                          v-slot="{ active, selectedCompany }"
                        >
                          <li
                            :class="[
                              active
                                ? 'bg-blue-600 text-white'
                                : 'text-gray-900',
                              'relative cursor-default select-none py-2 pl-3 pr-9',
                            ]"
                          >
                            <span
                              :class="[
                                selectedCompany
                                  ? 'font-semibold'
                                  : 'font-normal',
                                'block truncate',
                              ]"
                              >{{ option }}</span
                            >

                            <span
                              v-if="selectedCompany"
                              :class="[
                                active ? 'text-white' : 'text-blue-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                              ]"
                            >
                              <CheckIcon class="h-5 w-5" aria-hidden="true" />
                            </span>
                          </li>
                        </ListboxOption>
                      </ListboxOptions>
                    </transition>
                  </div>
                </Listbox>
              </div>
            </div>

            <!-- Filter by Job Title -->
            <div class="border-b border-gray-400 mb-5">
              <div class="mb-2">
                <div class="flex h-6 items-center mb-2">
                  <input
                    v-model="filterByJobTitle"
                    id="filterByJobTitle"
                    aria-describedby="filter-description"
                    name="filterJobTitle"
                    type="checkbox"
                    class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
                  />

                  <div class="ml-3 text-base leading-6">
                    <label
                      for="filterByJobTitle"
                      class="font-medium text-gray-900"
                      >Filter by Job Title</label
                    >
                    {{ " " }}
                  </div>
                </div>

                <div class="flex mt-2" v-if="filterByJobTitle">
                  <div
                    v-if="selectedJobTitles.length !== jobTitles.length"
                    @click="selectedJobTitles = [...jobTitles]"
                    class="cursor-pointer text-base underline text-blue-500"
                  >
                    Selected All
                  </div>
                  <div
                    v-if="selectedJobTitles.length"
                    @click="selectedJobTitles = []"
                    class="ml-2 cursor-pointer text-base underline text-red-500"
                  >
                    Clear Selected
                  </div>
                </div>
              </div>

              <div v-if="filterByJobTitle">
                <div class="relative shadow-md">
                  <table
                    class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
                  >
                    <tbody>
                      <tr
                        v-for="jobTitle in jobTitles"
                        :key="jobTitle.name"
                        class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                      >
                        <td class="w-4 p-4">
                          <div class="flex items-center">
                            <input
                              v-model="selectedJobTitles"
                              :id="jobTitle.name"
                              :value="jobTitle"
                              type="checkbox"
                              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                          </div>
                        </td>
                        <td
                          scope="row"
                          class="px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          {{ jobTitle }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <!-- Footer -->
          <div class="flex justify-end mt-1">
            <button
              @click="handleSaveFilters"
              class="btn bg-blue-500 text-white"
            >
              Apply Filters
            </button>
          </div>
        </div>
      </div>
    </r-modal>

    <!--NOTIFICATION  INFORMATION DIALOG -->
    <r-modal
      v-if="showNotificationInformationDialog"
      @close="showNotificationInformationDialog = false"
    >
      <div v-if="assignment" class="border-l-8 border-2 border-blue-500 p-5">
        <div
          class="flex justify-between text-xl border-b border-gray-500 py-2 mb-5"
        >
          <div class="flex">
            <div class="font-bold">Assignment</div>
          </div>

          <button
            @click="handleHideNotificationInformationDialog"
            small
            icon
            class="ml-3"
          >
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <div v-if="assignment.type === 'Flight'">
          <div>
            {{ formatAssignment(assignment) }}
          </div>

          <div class="flex my-1" v-if="assignment.employee">
            <div class="font-bold">
              {{
                `${assignment.employee.surname}, ${assignment.employee.givenName}`
              }}
            </div>
            <div class="font-bold text-blue-600 ml-3">
              {{
                `(${assignment.employee.companyName} - ${assignment.employee.companyId.number})`
              }}
            </div>
          </div>

          <div>
            <span>Phone: </span>
            <span class="text-sm-bold ml-1">
              {{ assignment.employee.mobilePhone }}
            </span>
          </div>

          <div>
            <span>Flight Role:</span
            ><span class="text-base font-bold ml-2">{{ assignment.role }}</span>
          </div>

          <div>
            <span>Assigned by:</span
            ><span class="ml-1 font-bold">{{ assignment.assignedBy }}</span>
          </div>
          <div>
            <span>Last Updated:</span
            ><span class="ml-1 font-bold">{{
              formatDateTime(assignment.updatedAt)
            }}</span>
          </div>

          <div class="horizontal-line-gray my-1"></div>

          <div>Remarks</div>

          <div class="remarks">
            <p
              v-if="assignment.remarks"
              class="user-select-text text-sm text-blue-600 mt-2"
            >
              {{ assignment.remarks }}
            </p>
          </div>

          <div class="horizontal-line-gray my-1"></div>
          <div>Notification</div>
          <div v-if="assignment.notification">
            <div>
              <span>Sent by:</span
              ><span class="ml-1 font-bold">{{
                assignment.notification.sentBy
              }}</span>
            </div>
            <div>
              <span>Sent on:</span
              ><span class="ml-1 font-bold">{{
                `${formatDateTime(assignment.notification.sentAt)} Z`
              }}</span>
            </div>
            <div v-if="assignment.notification.acknowledged">
              <span>Acknowledged on:</span
              ><span class="ml-1 font-bold text-green-500">
                {{
                  `${formatDateTime(assignment.notification.acknowledgedAt)} Z`
                }}
              </span>
            </div>
            <div v-else>
              <span class="font-bold text-red-500">Not Acknowledged</span>
            </div>
            <div
              v-if="assignment.notificationStatus === 'acknowledged-changed'"
            >
              <span>Warning:</span
              ><span class="ml-1 font-bold text-red-500">
                Assignment changed since last notification
              </span>
            </div>
          </div>
          <div v-else>
            <span class="font-bold text-red-500">No notification sent</span>
          </div>
        </div>
        <div v-else>
          <div class="flex my-1">
            <div class="font-bold">
              {{
                `${assignment.employee.surname}, ${assignment.employee.givenName}`
              }}
            </div>
            <div class="font-bold text-blue-600 ml-3">
              {{
                `(${assignment.employee.companyName} - ${assignment.employee.companyId.number})`
              }}
            </div>
          </div>

          <div class="flex justify-between mb-2">
            <div class="text-lg font-bold lg:text-3xl">
              {{ `${formatAssignment(assignment)}` }}
            </div>
          </div>

          <div class="horizontal-line-gray my-1"></div>

          <div>
            <span>Period:</span
            ><span class="text-base font-bold ml-2">
              {{
                `${formatDateTime(assignment.startTime)} -  ${formatDateTime(
                  assignment.endTime
                )}`
              }}</span
            >
          </div>

          <div>
            <span>Employee Phone:</span
            ><span class="text-base font-bold ml-2">{{
              assignment.employee.mobilePhone
            }}</span>
          </div>

          <div>
            <span>Assigned by:</span
            ><span class="text-base font-bold ml-2">{{
              assignment.assignedBy
            }}</span>
          </div>
          <div>
            <span>Last updated:</span
            ><span class="text-base font-bold ml-2">{{
              formatDateTime(assignment.updatedAt)
            }}</span>
          </div>

          <div class="horizontal-line-gray my-1"></div>

          <div>Remarks</div>
          <div class="remarks">
            <p
              v-if="assignment.remarks"
              class="user-select-text text-sm text-blue-600 mt-2"
            >
              {{ assignment.remarks }}
            </p>
          </div>
          <div class="horizontal-line-gray my-1"></div>
          <div>Notification</div>
          <div v-if="assignment.notification">
            <div>
              <span>Sent by:</span
              ><span class="ml-1 font-bold">{{
                assignment.notification.sentBy
              }}</span>
            </div>
            <div>
              <span>Sent on:</span
              ><span class="ml-1 font-bold">{{
                `${formatDateTime(assignment.notification.sentAt)} Z`
              }}</span>
            </div>
            <div v-if="assignment.notification.acknowledged">
              <span>Acknowledged on:</span
              ><span class="ml-1 font-bold text-green-500">
                {{
                  `${formatDateTime(assignment.notification.acknowledgedAt)} Z`
                }}
              </span>
            </div>
            <div v-else>
              <span class="font-bold text-red-500">Not Acknowledged</span>
            </div>
            <div
              v-if="assignment.notificationStatus === 'acknowledged-changed'"
            >
              <span>Warning:</span
              ><span class="ml-1 font-bold text-red-500">
                Assignment changed since last notification
              </span>
            </div>
          </div>
          <div v-else>
            <span class="font-bold text-red-500">No notification sent</span>
          </div>
        </div>
      </div>
    </r-modal>

    <!-- Assignments Table -->
    <div class="flex flex-col justify-between">
      <!-- Data Table -->
      <div v-if="assignments.length">
        <div class="relative overflow-x-auto shadow-md">
          <table
            class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
          >
            <thead
              class="text-xs text-gray-200 uppercase bg-gray-500 dark:bg-gray-700 dark:text-gray-400"
            >
              <tr>
                <th scope="col" class="p-4">
                  <div class="flex items-center">
                    <input
                      v-model="selectAll"
                      @click="handleToggleSelectAll"
                      id="checkbox-all-search"
                      type="checkbox"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 cursor-pointer"
                    />
                    <label for="checkbox-all-search" class="sr-only"
                      >checkbox</label
                    >
                  </div>
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 cursor-pointer"
                  @click="sortArrayByKey('employee.surname')"
                >
                  Last Name
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 cursor-pointer"
                  @click="sortArrayByKey('employee.givenName')"
                >
                  First Name
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 cursor-pointer"
                  @click="sortArrayByKey('employee.company')"
                >
                  Company
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 cursor-pointer"
                  @click="sortArrayByKey('employee.jobTitle')"
                >
                  Job Title
                </th>
                <th scope="col" class="px-6 py-3">Assignment</th>
                <th scope="col" class="px-6 py-3">Notification</th>
                <th
                  scope="col"
                  class="px-6 py-3"
                  @click="sortArrayByKey('updatedAt')"
                >
                  Updated At
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="assignment in assignments"
                :key="assignment._id"
                class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 cursor-pointer"
              >
                <td class="w-4 p-4">
                  <div class="flex items-center">
                    <input
                      v-model="assignment.selected"
                      :id="assignment._id"
                      type="checkbox"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </div>
                </td>

                <td
                  scope="row"
                  class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {{ assignment.employee.surname }}
                </td>

                <td class="px-6 py-2">
                  {{ assignment.employee.givenName }}
                </td>

                <td class="px-6 py-2">
                  {{ assignment.employee.companyName }}
                </td>

                <td class="px-6 py-2">
                  {{ assignment.employee.jobTitle }}
                </td>

                <td class="px-6 py-2">
                  {{ formatAssignment(assignment) }}
                </td>

                <td class="px-6 py-2">
                  <button
                    icon
                    @click="handleShowNotificationInformationDialog(assignment)"
                  >
                    <i
                      class="fa-solid fa-bell"
                      :style="getBellStyle(assignment)"
                    ></i>
                  </button>
                </td>

                <td class="px-6 py-2">
                  {{ formatDateTime(assignment.updatedAt) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div v-else class="bottom-frame text-base">
        <div class="p-3 text-xl text-red-500">
          No asssignments found with the selected filters.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../services/api";
import authentication from "../services/authentication";
import { mixin } from "../mixins/mixin";
import RSnackbar from "../components/RSnackbar.vue";
import RSpinner from "../components/RSpinner.vue";
import RModal from "../components/RModal.vue";
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid";

export default {
  name: "notifications",
  mixins: [mixin],
  components: {
    RSnackbar,
    RSpinner,
    RModal,
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    ChevronUpDownIcon,
  },
  data() {
    return {
      loading: false,

      inactivityTimer: null,
      selectAll: false,

      search: "",
      dateFilterDialog: false,
      selectedStartDate: "",
      selectedEndDate: "",
      employees: [],
      assignments: [],
      allAssignments: [],
      showStartCalendar: false,
      showEndCalendar: false,

      //-------------------------Snackbar
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,

      assignment: null,
      showNotificationInformationDialog: false,

      //------------------------------------------Filters and Settings
      showFiltersDialog: false,
      selectedAircraftDeck: "Cargo and Pax.",
      aircraftDeckOptions: [
        "Cargo and Pax.",
        "Cargo Aircraft",
        "Passenger Aircraft",
      ],

      selectedAircraftDeck: "Cargo and Pax.",
      aircraftDeckOptions: [
        "Cargo and Pax.",
        "Cargo Aircraft",
        "Passenger Aircraft",
      ],

      filterByStatus: false,
      selectedStatus: "Notifications Sent",
      statusOptions: ["Notifications Sent", "Notifications Not Sent"],

      filterByCompany: false,
      selectedCompany: null,

      filterByJobTitle: false,
      selectedJobTitles: [],

      showDateFilterDialog: false,
      startDate: null,
      numberOfDays: 7,
      numberOfDaysOptions: [3, 5, 7, 14, 21, 31],
    };
  },

  created() {
    (async () => {
      this.getData();
    })();
  },

  mounted() {
    this.startInactivityTimer();
    document.addEventListener("click", this.resetInactivityTimer);
  },
  unmounted() {
    this.clearInactivityTimer();
    document.removeEventListener("click", this.resetInactivityTimer);
  },

  computed: {
    auth() {
      return this.$store.state.auth;
    },

    dateFilter() {
      return this.$store.state.dateFilter;
    },

    notificationStatusFilter() {
      return this.$store.getters.notificationStatusFilter;
    },

    companyFilter() {
      return this.$store.getters.companyFilter;
    },

    jobTitlesFilter() {
      return this.$store.getters.jobTitlesFilter;
    },

    user() {
      return this.$store.state.user;
    },

    companyOptions() {
      if (this.auth) {
        return [...this.user.securityGroup.companies];
      } else {
        return [];
      }
    },

    jobTitles() {
      if (this.auth) {
        return [...this.user.securityGroup.jobTitles];
      } else {
        return [];
      }
    },

    selectedAssignments() {
      return this.assignments.filter((assignment) => assignment.selected);
    },
  },

  methods: {
    handleToggleSelectAll() {
      this.selectAll = !this.selectAll;

      this.assignments.forEach((assignment) => {
        assignment.selected = this.selectAll;
      });
    },
    startInactivityTimer() {
      this.clearInactivityTimer();
      this.inactivityTimer = setTimeout(() => {
        this.handleAutoLogout();
      }, this.timeoutDuration);
    },

    clearInactivityTimer() {
      if (this.inactivityTimer) {
        clearTimeout(this.inactivityTimer);
      }
    },

    resetInactivityTimer() {
      this.startInactivityTimer();
    },

    async handleAutoLogout() {
      try {
        if (!this.auth) {
          this.$store.commit("updateUser", null);
          clearTimeout(this.inactivityTimer);
          this.$router.push({ name: "home" });
          return;
        }

        //---------------------------------------Refresh Token
        this.loading = true;
        const accessToken = await authentication.getAccessToken();
        if (!accessToken) {
          this.loading = false;
          this.$router.push({ name: "home" });
          return;
        }

        const res = await api.get("/auth/logout", {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        });

        this.loading = false;

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }

        this.$store.commit("updateAuth", null);
        this.$store.commit("updateUser", null);
        clearTimeout(this.inactivityTimer);
        this.$router.push({ name: "home" });
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarVisible = true;
        this.snackbarText = `Error during logout: ${error}`;

        setTimeout(() => {
          this.$store.commit("updateAuth", null);
          this.$store.commit("updateUser", null);
          clearTimeout(this.inactivityTimer);
          this.$router.push({ name: "home" });
        }, 3000);
      }
    },

    isSortedAsc(arr, key) {
      for (let i = 0; i < arr.length - 1; i++) {
        if (arr[i][key] > arr[i + 1][key]) {
          return false;
        }
      }
      return true;
    },

    sortArrayByKey(key) {
      const sortedAsc = this.isSortedAsc(this.assignments, key);

      if (sortedAsc) {
        this.assignments.sort((a, b) => (a[key] < b[key] ? 1 : -1));
      } else {
        this.assignments.sort((a, b) => (a[key] > b[key] ? 1 : -1));
      }
    },

    handleOpenFilters() {
      if (!this.selectedCompany) {
        this.selectedCompany = this.user.companyName.slice();
      }

      if (this.notificationStatusFilter.active) {
      }

      if (this.companyFilter.active) {
        this.filterByCompany = true;
        this.selectedCompany = this.companyFilter.name.slice();
      }

      if (this.jobTitlesFilter.active) {
        this.filterByJobTitle = true;
        this.selectedJobTitles = [...this.jobTitlesFilter.jobTitles];
      } else {
        this.filterByJobTitle = false;
        this.selectedJobTitles = [];
      }

      this.showFiltersDialog = true;
    },

    handleSaveFilters() {
      this.showFiltersDialog = false;

      //-----------------------------Status Filter
      let statusFilter = {
        active: false,
        status: "",
      };

      if (this.filterByStatus && this.selectedStatus) {
        statusFilter = {
          active: true,
          status: this.selectedStatus.slice(),
        };
      }

      this.$store.commit("updateNotificationStatusFilter", statusFilter);

      //-----------------------------Company Filter
      let companyFilter = {
        active: false,
        name: "",
      };

      if (this.filterByCompany && this.selectedCompany) {
        companyFilter = {
          active: true,
          name: this.selectedCompany.slice(),
        };
      }

      this.$store.commit("updateCompanyFilter", companyFilter);

      //------------------------------Job Titles Filter
      let jobTitlesFilter = {
        active: false,
        jobTitles: [],
      };

      if (this.filterByJobTitle && this.selectedJobTitles.length) {
        jobTitlesFilter = {
          active: true,
          jobTitles: [...this.selectedJobTitles],
        };
      }

      this.$store.commit("updateJobTitlesFilter", jobTitlesFilter);

      this.getData();
    },

    async getData() {
      this.allAssignments = [];
      this.assignments = [];

      let companyFilter = {
        active: false,
        name: "",
      };

      if (this.companyFilter.active) {
        companyFilter = {
          active: true,
          name: this.companyFilter.name.slice(),
        };
      }

      //--------------------------Job Title Filter
      let jobTitlesFilter = {
        active: false,
        jobTitles: [],
      };

      if (this.jobTitlesFilter.active) {
        jobTitlesFilter = {
          active: true,
          jobTitles: [...this.jobTitlesFilter.jobTitles],
        };
      }

      try {
        //---------------------------------------Refresh Token
        this.loading = true;
        const accessToken = await authentication.getAccessToken();
        if (!accessToken) {
          this.loading = false;
          this.$router.push({ name: "home" });
          return;
        }

        const res = await api.post(
          "/notifications",
          {
            dateFilterStart: this.dateFilter.start,
            dateFilterEnd: this.dateFilter.end,
            companyFilter,
            jobTitlesFilter,
            aircraftDeck: this.selectedAircraftDeck,
          },
          {
            headers: {
              Authorization: "Bearer " + accessToken,
            },
          }
        );

        this.loading = false;

        if (res.status !== 200) {
          this.loading = false;
          this.snackbarColor = "red";
          this.snackbarText = res;
          this.snackbarVisible = true;

          return;
        }

        this.allAssignments = res.data.assignments;

        this.handleFilterNotifications();
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    handleFilterNotifications() {
      let filterOne;
      let filterTwo;

      if (this.search.length) {
        filterOne = this.allAssignments.filter((assignment) => {
          return (
            assignment.employee.surname
              .toUpperCase()
              .startsWith(this.search.toUpperCase()) ||
            assignment.employee.givenName
              .toUpperCase()
              .startsWith(this.search.toUpperCase())
          );
        });
      } else {
        filterOne = [...this.allAssignments];
      }

      if (this.notificationStatusFilter.active) {
        if (this.notificationStatusFilter.status === "Notifications Sent") {
          filterTwo = filterOne.filter((assignment) => {
            return assignment.notification;
          });
        } else {
          filterTwo = filterOne.filter((assignment) => {
            return !assignment.notification;
          });
        }
      } else {
        filterTwo = [...filterOne];
      }

      filterTwo.forEach((assignment) => {
        assignment.selected = false;
      });

      this.assignments = [...filterTwo];
    },

    //-------------------------------------Filters and Settings
    handleOpenDateFilter() {
      this.startDate = this.dateFilter.startDate.slice();
      this.numberOfDays = this.dateFilter.days.length;

      this.showDateFilterDialog = true;
    },

    handleSaveDateFilter() {
      const filterStart = new Date(this.startDate).getTime();

      this.showDateFilterDialog = false;

      //Pass filterStart in miliseconds, numberOfDays as integer
      this.$store.commit("updateDateFilter", {
        filterStart,
        numberOfDays: this.numberOfDays,
      });

      this.getData();
    },

    handleShowNotificationInformationDialog(assignment) {
      const status = this.getNotificationStatus(assignment);

      this.assignment = { ...assignment, notificationStatus: status };

      this.showNotificationInformationDialog = true;
    },

    handleHideNotificationInformationDialog() {
      this.showNotificationInformationDialog = false;
      this.assignment = null;
    },

    getNotificationStatus(assignment) {
      let status = "not-sent";
      let version;

      let startTime = new Date(assignment.startTime).toISOString();
      let endTime = new Date(assignment.endTime).toISOString();

      switch (assignment.type) {
        case "Flight":
          version = `${assignment.aircraftRegistration}|${assignment.flightNumber}|${assignment.originIata}|${startTime}|${assignment.destinationIata}|${endTime}`;
          break;
        case "Travel":
          version = `${assignment.type}|${assignment.originIata}|${startTime}|${assignment.destinationIata}|${endTime}`;
          break;
        default:
          version = `${assignment.type}|${assignment.originIata}|${startTime}|${endTime}`;
          break;
      }

      if (assignment.notification) {
        if (assignment.notification.acknowledged) {
          if (assignment.notification.version === version) {
            status = "acknowledged";
          } else {
            status = "acknowledged-changed";
          }
        } else {
          status = "sent-not-acknowledged";
        }
      }

      return status;
    },

    formatNotificationSentAt(item) {
      if (item.notification) {
        return `${this.formatDateTime(item.notification.sentAt)}`;
      } else {
        return "Not Sent";
      }
    },
    getBellStyle(assignment) {
      const status = this.getNotificationStatus(assignment);

      switch (status) {
        case "sent-not-acknowledged":
          return { color: "blue" };
        case "acknowledged":
          return { color: "green" };
        case "acknowledged-changed":
          return { color: "purple" };
        default:
          return { color: "red" };
      }
    },

    async sendNotifications() {
      //---------------------------------------Refresh Token
      this.loading = true;
      const accessToken = await authentication.getAccessToken();
      if (!accessToken) {
        this.loading = false;
        this.$router.push({ name: "home" });
        return;
      }

      try {
        const emailSubject = "Assignment(s)";

        const res = await api.put(
          `/notifications/multi`,
          {
            assignments: this.selectedAssignments,
            emailSubject,
          },
          {
            headers: { Authorization: "Bearer " + accessToken },
          }
        );

        this.loading = false;

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;

          setTimeout(() => {
            this.$store.commit("updateAuth", null);
            this.$router.push({ name: "home" });
          }, 3000);

          return;
        }

        this.getData();
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;

        setTimeout(() => {
          this.$store.commit("updateAuth", null);
          this.$router.push({ name: "home" });
        }, 3000);

        return;
      }
    },

    //------------------------Formatters and Stylers

    formatDate(miliseconds) {
      return new Date(miliseconds).toISOString().substring(0, 10);
    },

    formatDateShort(miliseconds) {
      const date = new Date(miliseconds);

      let options = {
        timeZone: "UTC",
        month: "short",
        day: "numeric",
      };

      return new Intl.DateTimeFormat("en-US", options).format(date);
    },

    formatTime(miliseconds) {
      return new Date(miliseconds).toISOString().substring(11, 16);
    },

    formatDateTime(dateTimeString) {
      const date = new Date(dateTimeString);

      let options = {
        timeZone: "UTC",
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hourCycle: "h23",
      };

      return new Intl.DateTimeFormat("en-US", options).format(date);
    },

    formatAssignment(assignment) {
      const timeOut = this.formatDateTime(assignment.startTime);
      const timeIn = this.formatDateTime(assignment.endTime);

      switch (assignment.type) {
        case "Flight":
          return `${assignment.role} . ${assignment.flightNumber} . ${assignment.aircraftRegistration} . ${assignment.originIata} - ${assignment.destinationIata} . ${timeOut} Z - ${timeIn} Z`;

        case "Travel":
          return `${assignment.type} . ${assignment.originIata} - ${assignment.destinationIata} . ${timeOut} Z - ${timeIn} Z`;
        case "Station Assignment":
          return `${assignment.role} . ${assignment.originIata} . ${timeOut} Z - ${timeIn} Z`;

        default:
          return `${assignment.type} . ${assignment.originIata} . ${timeOut} Z - ${timeIn} Z`;
      }
    },
  },
};
</script>

<style scoped>
.toolbox {
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 50px;
  z-index: 100;
  background-color: #fff;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);
  padding: 5px;
}
.bottom-frame {
  margin: 0 2%;
}

.fa-bell {
  font-size: 1.3rem;
  color: green;
}

.fa-paper-plane {
  font-size: 1.3rem;
  color: #93c5fd;
}
</style>
