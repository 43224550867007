import { createWebHistory, createRouter } from "vue-router";
import { reactive } from "vue";
import store from "../store";

import About from "../views/About.vue";
import Manuals from "../views/Manuals.vue";
import Home from "../views/Home.vue";
import FlightBoard from "../views/FlightBoard.vue";
import Administration from "../views/Administration.vue";
import EmployeeCreate from "../views/EmployeeCreate.vue";
import EmployeeUpdate from "../views/EmployeeUpdate.vue";
import Bidlines from "../views/Bidlines.vue";
import AssignmentBoard from "../views/AssignmentBoard.vue";
import Notifications from "../views/Notifications.vue";
import MyMonarch from "../views/MyMonarch.vue";
import ActivityReports from "../views/ActivityReports.vue";
import ActivityReport from "../views/ActivityReport.vue";
import MyActivityReport from "../views/MyActivityReport.vue";
import Airports from "../views/Airports.vue";
import Aircraft from "../views/Aircraft.vue";
import SelectedAircraft from "../views/SelectedAircraft.vue";
import Airport from "../views/Airport.vue";
import SecurityGroups from "../views/SecurityGroups.vue";
import SecurityGroup from "../views/SecurityGroup.vue";
import CrewRequirements from "../views/CrewRequirements.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },

  {
    path: "/manuals",
    name: "manuals",
    component: Manuals,
  },
  {
    path: "/about",
    name: "about",
    component: About,
  },
  {
    path: "/airports",
    name: "airports",
    component: Airports,
  },
  {
    path: "/aircraft",
    name: "aircraft",
    component: Aircraft,
  },
  {
    path: "/selected-aircraft",
    name: "selectedAircraft",
    component: SelectedAircraft,
  },
  {
    path: "/airport",
    name: "airport",
    component: Airport,
  },
  {
    path: "/crew-requirements",
    name: "crewRequirements",
    component: CrewRequirements,
  },
  {
    path: "/security-groups",
    name: "securityGroups",
    component: SecurityGroups,
  },
  {
    path: "/security-group",
    name: "securityGroup",
    component: SecurityGroup,
  },
  {
    path: "/activity-reports",
    name: "activityReports",
    component: ActivityReports,
  },
  {
    path: "/activity-report",
    name: "activityReport",
    component: ActivityReport,
  },
  {
    path: "/my-activity-report",
    name: "myActivityReport",
    component: MyActivityReport,
  },
  {
    path: "/flight-board",
    name: "flightBoard",
    component: FlightBoard,
  },
  {
    path: "/assignment-board",
    name: "assignmentBoard",
    component: AssignmentBoard,
  },
  {
    path: "/notifications",
    name: "notifications",
    component: Notifications,
  },
  {
    path: "/my-monarch",
    name: "myMonarch",
    component: MyMonarch,
  },
  {
    path: "/bidlines",
    name: "bidlines",
    component: Bidlines,
  },
  {
    path: "/administration",
    name: "administration",
    component: Administration,
  },
  {
    path: "/employee-create",
    name: "employeeCreate",
    component: EmployeeCreate,
  },
  {
    path: "/employee-update/:id",
    name: "employeeUpdate",
    component: EmployeeUpdate,
    props: (route) => ({ employee_id: route.params.id }),
  },
];

// Create a reactive variable to hold the previous route name
const previousRoute = reactive({ name: null });

const router = createRouter({
  history: createWebHistory(),
  routes,
});

function isAuthenticated() {
  return store.state.auth;
}

router.beforeEach((to, from, next) => {
  previousRoute.name = from.name; // Set the previous route name
  if ((to.name !== "home" || to.name !== "logout") && !isAuthenticated)
    next({ name: "home" });
  else next();
});

export { router, previousRoute };
